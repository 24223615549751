import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import set from "lodash/set";
import { Query } from "app/containers/types";
import { Question } from "app/containers/Stations/types";
import { InitialGamesQuestionsState } from "../types";


const initialState = InitialGamesQuestionsState;

export const GameQuestionsSlice = createSlice({
  name: "gameQuestionsState",
  initialState,
  reducers: {
    getQuestions(state) {
      set(state, "isLoading", true);
      set(state, "questions", []);
    },
    getQuestionsSuccess(state, action: PayloadAction<Question[]>) {
      set(state, "questions", action.payload);
      set(state, "isLoading", false);
    },
    getQuestionsError(state) {
      set(state, "isLoading", false);
    },
    setSelectedQuestion(state, action: PayloadAction<string>) {
      set(state, "selectedQuestion", action.payload);
    },
    resetState(state) {
      state = initialState;
    },
  },
});

export const { reducer: gamesQuestionsReducer, actions, name: gameQuestionsSlice } = GameQuestionsSlice;
