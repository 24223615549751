import Grid2 from "@mui/material/Unstable_Grid2";
import MediaUploader from "app/components/MediaUploader";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ASSET_TYPE, MODES } from "utils/constants";
import { actions } from "app/containers/Questions/redux/slice";
import { selectForm } from "app/containers/Questions/redux/selector";
import { MediaTypes } from "app/containers/types";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { selectDirection } from "styles/theme/slice";
export default function LowerDeck({ mode }: { mode: MODES }) {
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const [opened, setOpened] = useState(false);
  return (
    <Grid2
      container
      spacing={2}
      sx={{
        marginTop: "10px",
      }}
    >
      <Grid2 container xs={12} position={"relative"}>
        <IconButton
          onClick={() => setOpened(!opened)}
          sx={{
            width: "30px",
            height: "30px",
            textTransform: "none",
            alignItems: "center",
            position: "absolute",
            left: " -10px",
            top: "14px",
          }}
        >
          {opened ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        <Grid2 xs={12}>
          <MediaUploader
           width="60%"
           label={t("COMMON.LABELS.ADD_IMAGE_VIDEO")}
           tooltip={t("QUESTIONS.FORM.LABELS.QUESTION_IMAGE_TOOLTIP")}
            expanded={opened}
            availableTypes={[ASSET_TYPE.IMAGE, ASSET_TYPE.VIDEO]}
            showBtn={false}
            onCheck={(value:MediaTypes) => {
              dispatch(
                actions.setAssetsValues({
                  key: "asset",
                  value: {
                    type:  value,
                    _id: form.asset?._id,
                    mimeType: form.asset?.mimeType,
                    fileName: form.asset?.fileName,
                    filePath: form.asset?.filePath,
                  },
                })
              );
            }}
            checked={form.asset?.type|| ASSET_TYPE.NONE}
            disabled={mode === MODES.VIEW}
            primaryColor="#E24399"
            secondaryColor="#FBE4F0"
            type={form.asset?.type as any}
            src={ form.asset?.url}
            onSuccess={(img) => {
              dispatch(
                actions.setAssetsValues({
                  key: "asset",
                  value: {
                    _id: img?._id,
                    type: img?.type,
                    mimeType: img?.type,
                    fileName: img?.fileName,
                    filePath: img?.filePath,
                    url: img?.url,
                  },
                })
              );
            }}
            title={t("COMMON.LABELS.IMAGE")}
            caption={t("STATIONS.FORM.LABELS.STATION_IMAGE_CAPTION")}
            height="200px"
            fallbackWidth="320px"
            
          />
        </Grid2>

      </Grid2>
    </Grid2>
  );
}
