import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  textHeaderT: {
    color: "#0016BB",
    fontWeight: "700px",
    fontSize: "18px",
    lineHeight: "29.98px",
    fontStyle: "normal"
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    gap: "10px"
  },
  textHeader: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "100%",
    color: "#207DB9",
  }
}));
