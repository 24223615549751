import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import CustomTable, { StyledCell } from "app/components/Table";
import { TableHeaderDefinition, TableHeaders } from "./headers";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoading, selectList, selectPagination } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { selectOrganization } from "app/redux/selectors";
import { formatHumanizedDuration, formatTableDateTime, formatUUID } from "styles/theme/utils";
import CustomTableRow from "app/components/TableRow";
import { OrderDirections } from "app/containers/types";
import { useTranslation } from "react-i18next";
const List = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const list = useSelector(selectList);
  const dispatch = useDispatch();
  const pagination = useSelector(selectPagination);
  const searchParams = useSearchParams();
  const isLoading = useSelector(selectIsLoading);
  const q = searchParams?.[0]?.get("q");
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction");
  const organization = useSelector(selectOrganization);
  useEffect(() => {
    dispatch(
      actions.getResults({
        page: 1,
        search: q ? q : undefined,
        organization: organization?.value,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      })
    );
  }, [q, dispatch, organization?.value, orderBy, orderDirection]);

  const hasMoreItems = pagination.page < pagination.totalPage;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getResults({
        page: pagination.page + 1,
        search: q ? q : undefined,
        organization: organization?.value,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      }));
    }
  };
  return (
    <>
      <Header />
      <CustomTable
        headers={TableHeaders}
        loading={isLoading}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        dataLength={list.length}
      >
        {list.map((row) => (
          <CustomTableRow key={row._id}>
            <StyledCell
              minWidth={TableHeaderDefinition.RESULT_ID.minWidth}
              maxWidth={TableHeaderDefinition.RESULT_ID.maxWidth}>
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/results/${row.ticket}`)}
              >
                {formatUUID(row._id)}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.GROUP_NAME.minWidth}
              maxWidth={TableHeaderDefinition.GROUP_NAME.maxWidth}
            >
              {row.groupName || "-"}
            </StyledCell>

            <StyledCell
              minWidth={TableHeaderDefinition.GAME_NAME.minWidth}
              maxWidth={TableHeaderDefinition.GAME_NAME.maxWidth}
            >
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/app/games/${row.game?._id}/view`)}
              >
                {row.game?.name}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.TICKET_ID.minWidth}
              maxWidth={TableHeaderDefinition.TICKET_ID.maxWidth}
            >
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/app/tickets/${row.ticket}/view`)}
              >
                {formatUUID(row.ticket)}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.QUESTIONS_AMOUNT.minWidth}
              maxWidth={TableHeaderDefinition.QUESTIONS_AMOUNT.maxWidth}
            >
              {t('RESULTS.MIN_OF_MAX', {min: row.completedQuestions, max: row.totalQuestions})}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.STATIONS_AMOUNT.minWidth}
              maxWidth={TableHeaderDefinition.STATIONS_AMOUNT.maxWidth}
            >
              {t('RESULTS.MIN_OF_MAX', {min: row.completedStations, max: row.totalStations})}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.PLAYED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.PLAYED_DATE.maxWidth}
            >{formatTableDateTime(row.consumedAt)}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.GAME_DURATION.minWidth}
              maxWidth={TableHeaderDefinition.GAME_DURATION.maxWidth}
            >{formatHumanizedDuration(row.startTime, row.endTime)}</StyledCell>
              <StyledCell
              minWidth={TableHeaderDefinition.CREATED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.CREATED_DATE.maxWidth}
            >
              {formatTableDateTime(row.createdAt)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.EDITED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.EDITED_DATE.maxWidth}
            >
              {formatTableDateTime(row.updatedAt)}
            </StyledCell>
                    
          </CustomTableRow>
        ))}
      </CustomTable>
    </>
  );
};

export default List;
