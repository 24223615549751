import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';
import { useTranslation } from 'react-i18next';

export default function CountInput({
    containerStyle,
    label,
    labelStyle,
    isDisable,
    inputProps,
    onChange,
    error,
    inputStyle,
    value=0,
    name,
    placeholder,
    sx,
    min=0,
    max=10,
}:{
    containerStyle?: React.CSSProperties,
    label?: string,
    labelStyle?: React.CSSProperties,
    isDisable?: boolean,
    inputProps?: any,
    onChange?: (v: number) => void,
    error?: string,
    inputStyle?: React.CSSProperties,
    value?: number,
    name?: string,
    placeholder?: string,
    sx?: any
    min?: number,
    max?: number,
}) {
    const {i18n} = useTranslation();
    const direction = useSelector(selectDirection)
    const onIncrease = () => {
        if(value < max) {
            onChange?.(value + 1)
        }
    }

    const onDecrease = () => {
        if(value > min) {
            onChange?.(value - 1)
        }
    }
    return (
        <Box 
            style={containerStyle}
        >
            {label && (
                <Typography
                    sx={{
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        display: "-webkit-box",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        ...labelStyle
                    }}
                >
                {label}
                </Typography>
            )}
            <Box
                sx={{
                    width: '100%',
                    position: 'relative',
                }}
            >
                <TextField
                    type="text"
                    disabled={isDisable}
                    inputProps={inputProps}
                    fullWidth={true}
                    onChange={(e) => onChange?.(Number(e.target.value))}
                    error={!!error}
                    helperText={error}
                    contentEditable={false}
                    sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: '#86BDE3',
                        },
                        '&:hover fieldset': {
                        borderColor: '#86BDE3',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: '#86BDE3',
                        },
                    },
                    input: {
                        '&::placeholder': {
                        color: '#727F89',
                        opacity: '100%',
                        fontWeight: '400',
                        fontSize: '14px',
                        },
                        fontWeight: '400',
                        fontSize: '14px',
                        height: '18px',
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        border: '1px solid #86BDE3',    
                        '&:hover': {
                        border: '2px solid #86BDE3',
                        },
                        '&:focus': {
                        border: '2px solid #86BDE3',
                        },
                        maxHeight: '48px',
                        ...inputStyle
                    },
                    ...sx,
                    }}
                    placeholder={placeholder}
                    value={value}
                    name={name}
                />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2px",
                        position: 'absolute',
                        right: direction === "ltr" ? "12px" : undefined,
                        left: direction === "rtl" ? "12px" : undefined,
                        top: "12px",
                    }}
                >
                    <KeyboardArrowUpIcon 
                        sx={{
                            fontSize: "20px",
                            mb: "-5px",
                            cursor: "pointer",
                        }}
                        onClick={onIncrease}
                    />
                    <KeyboardArrowDownIcon 
                        sx={{
                            fontSize: "20px",
                            mt: "-5px",
                            cursor: "pointer",
                        }}
                        onClick={onDecrease}
                    />
                </Box>
            </Box>
            {error && <Typography sx={{ marginTop: 0, color: '#ff0000' }}>{error}</Typography>}
        </Box>
    )
}
