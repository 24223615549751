import React, { useEffect } from "react";
import CustomTable from "app/components/Table";
import { TableHeaderColumnType } from "app/components/Table/types";
import Uploader from "../Uploader";
import { Checkbox } from "@mui/material";
import EditableColumn from "../FreeText/components/EditableColumn";
import { styled } from "@mui/material/styles";
import { ASSET_TYPE, MODES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectAnswer } from "app/containers/Questions/redux/selector";
import { actions } from "app/containers/Questions/redux/slice";
import CustomTableRow from "app/components/TableRow";
import { selectDirection } from "styles/theme/slice";

import {SingleMultiData as Data} from '../../constants';
interface StyledCellProps {
  minWidth?: string | number;
  maxWidth?: string | number;
  fontFamily?: string;
}

const StyledTableCell = styled("div")(
  ({ minWidth, maxWidth, fontFamily }: StyledCellProps) => ({
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "14px 16px",
    "&:last-of-type": {
      borderRight: "none",
    },
    backgroundColor: "transparent",
    textAlign: "unset",
    whiteSpace: "nowrap",
    height: 50,
    minWidth: minWidth || "auto",
    maxWidth: maxWidth || "none",
    fontFamily,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3C3C43",
  })
);

const Headers: TableHeaderColumnType[] = [
  {
    id: "#",
    label: "HASH",
    align: "left",
    minWidth: 80,
    sortable: true,
  },
  {
    id: "rightAnswer",
    label: "RIGHT_ANSWER",
    align: "left",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "difficulty",
    label: "ANSWER_TEXT",
    align: "left",
    minWidth: 500,
    sortable: true,
  },
  {
    id: "action",
    label: "ACTIONS",
    align: "left",
    minWidth: 500,
    sortable: true,
  },
];



const MultiSelect = ({
  disabled,
  mode,
}: {
  disabled?: boolean;
  mode: MODES;
}) => {
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);
  const answers = useSelector(selectAnswer);
  useEffect(() => {
    if (mode == MODES.NEW) {
      dispatch(actions.setMultiSelectAnswer(Data));
    } else {
      if (!answers.some((x) => x.multiSelect)) {
        dispatch(actions.setMultiSelectAnswer(Data));
      }
    }
    return () => {};
  }, [mode]);

  return (
    <CustomTable headers={Headers} containerStyle={{ marginBottom: "50px" }}>
      {answers?.map((item, index) => (
        <CustomTableRow key={index}>
          <StyledTableCell
            fontFamily={direction === "ltr" ? "Inter" : "Rubik"}
            minWidth={80}
            maxWidth={80}
          >
            {index + 1}
          </StyledTableCell>
          <StyledTableCell
            fontFamily={direction === "ltr" ? "Inter" : "Rubik"}
            minWidth={180}
            maxWidth={180}
          >
            <Checkbox
              disabled={disabled}
              checked={item.multiSelect?.isCorrect}
              defaultChecked={item.multiSelect?.isCorrect}
              onChange={() => {
                dispatch(
                  actions.setUpdateAnswer({
                    index: index,
                    type: "multiSelect",
                    key: "isCorrect",
                    value: !item.multiSelect?.isCorrect,
                  })
                );
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  color: "#207DB9",
                },
                "&.Mui-disabled .MuiSvgIcon-root": {
                  color: "#8890A6",
                },
              }}
            />
          </StyledTableCell>
          <StyledTableCell
            fontFamily={direction === "ltr" ? "Inter" : "Rubik"}
            minWidth={500}
            maxWidth={500}
          >
            <EditableColumn
              disabled={disabled}
              value={item.multiSelect?.title || ""}
              isOdd={index % 2 === 0}
              innerContainerStyle={{
                maxWidth: "100%",
              }}
              inputStyle={{
                background: "transparent",
              }}
              onSave={(val) => {
                dispatch(
                  actions.setUpdateAnswer({
                    index: index,
                    type: "multiSelect",
                    key: "title",
                    value: val,
                  })
                );
              }}
            />
          </StyledTableCell>
          <StyledTableCell
            fontFamily={direction === "ltr" ? "Inter" : "Rubik"}
            minWidth={500}
            maxWidth={500}
          >
            <Uploader
              isOdd={!(index % 2 === 0)}
              onUpload={(img) => {
                dispatch(
                  actions.setUpdateAnswer({
                    index: index,
                    key: "asset",
                    type: "multiSelect",
                    value: {
                      _id: img?._id,
                      type: ASSET_TYPE.IMAGE,
                      mimeType: img?.mimeType,
                      fileName: img?.fileName,
                      filePath: img?.filePath,
                      url: img?.url,
                    },
                  })
                );
              }}
              isDelete={
                item.multiSelect?.asset?.fileName
                  ? item.multiSelect.asset?.fileName.length > 0
                  : false
              }
              src={item.multiSelect?.asset?.url}
              onDelete={() => {
                dispatch(
                  actions.setUpdateAnswer({
                    index: index,
                    key: "asset",
                    type: "multiSelect",
                    value: {
                      _id: "",
                      type: ASSET_TYPE.IMAGE,
                      mimeType: "",
                      fileName: "",
                      filePath: "",
                      url: "",
                    },
                  })
                );
              }}
            />
          </StyledTableCell>
        </CustomTableRow>
      ))}
    </CustomTable>
  );
};

export default MultiSelect;
