import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { Organization, OrganizationsState } from "../types";
import set from "lodash/set";
import { FormError, Pagination, Query } from "app/containers/types";

const initialState = OrganizationsState;

export const formSlice = createSlice({
  name: "organizationsState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFormValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.isUnsaved = true;
    },
    getOrganization: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getOrganizationSuccess: (state, action: PayloadAction<Organization>) => {
      state.form.address.value = action.payload.address;
      state.form.contactName.value = action.payload.contactName;
      state.form.email.value = action.payload.email;
      state.form.mobileNumber.value = action.payload.mobileNumber;
      state.form.name.value = action.payload.name;
      state.form.note.value = action.payload.note;
      state.form.taxNumber.value = action.payload.taxNumber;
      state.form.id.value = action.payload._id;
      state.loading = false;
    },
    getOrganizations: (state, action: PayloadAction<Query>) => {
      state.loading = true;
    },
    getOrganizationsSuccess: (
      state,
      action: PayloadAction<{ organizations: Organization[]; pagination: Pagination }>
    ) => {
      state.organizations = action.payload.pagination.page === 1 ? action.payload.organizations : [
        ...state.organizations,
        ...action.payload.organizations,
      ];
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    getOrganizationError: (state) => {
      state.loading = false;
    },
    createOrganization: (state, action: PayloadAction<{organization: Organization; callback?: ()=>void;}>) => {
      state.loading = true;
    },
    createOrganizationSuccess: (state) => {
      state.loading = false;
      state.isUnsaved=false;
    },
    createOrganizationError: (state) => {
      state.loading = false;
    },
    updateOrganization: (state, action: PayloadAction<{organization: Organization; callback?: ()=>void;}>) => {
      state.loading = true;
    },
    updateOrganizationSuccess: (state, action: PayloadAction<Organization>) => {
      state.form.address.value = action.payload.address;
      state.form.contactName.value = action.payload.contactName;
      state.form.email.value = action.payload.email;
      state.form.mobileNumber.value = action.payload.mobileNumber;
      state.form.name.value = action.payload.name;
      state.form.note.value = action.payload.note;
      state.form.taxNumber.value = action.payload.taxNumber;
      state.form.id.value = action.payload._id;
      state.loading = false;
      state.isUnsaved=false;
    },
    updateOrganizationError: (state) => {
      state.loading = false;
    },
    deleteOrganization: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.organizations= state.organizations.filter((x)=>x._id !== action.payload)
    },
    updateStatusOrganizationSuccess: (state, action: PayloadAction<{ id: string; status: boolean; }>) => {
      state.loading = false;
      const org = state.organizations.find((x)=>x._id==action.payload.id)
      if(org) {
        org.isActive = action.payload.status
      }
    },
    updateStatusOrganization: (state, action: PayloadAction<{id:string,status:boolean}>) => {
      state.loading = true;
    },
    deleteOrganizationSuccess: (state) => {
      state.loading = false;
      
    },
    deleteOrganizationError: (state) => {
      state.loading = false;
    },
    setFormErrors(
      state,
      action: PayloadAction<FormError[]>
    ) {
      action.payload.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    resetForm: (state, action: PayloadAction<{ callback?: () => void }>) => {
      state.loading = false;
      state.form = initialState.form;
      state.isUnsaved=false;
    },
  },
});

export const { reducer: organizationReducer, actions, name: organizationSlice } = formSlice;
