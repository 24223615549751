import { Box } from '@mui/material'
import React from 'react'
import Header from './components/Header'
import QuestionCard from './components/QuestionCard'
import { useSelector } from 'react-redux'
import { selectCustomizeTicket } from 'app/containers/Tickets/redux/selector'


export default function CustomizedQuestions({
    onDelete,
    onEdit,
    onAdd
}:{
    readonly onDelete: (index:number,body:string)=>void 
    readonly onEdit: (index:number)=>void
    readonly onAdd: ()=>void
}) {
    const ticket = useSelector(selectCustomizeTicket);
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: "16px",
                gap: "12px",
            }}
        >
            <Header 
                onAdd={onAdd}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                {
                    ticket.questions?.map((item,index) => (
                        <QuestionCard 
                            key={index} 
                            question={item}
                            onDelete={()=>onDelete(index,item.body)} 
                            onEdit={()=>onEdit(index)}
                        />
                    ))
                }
            </Box>
        </Box>
    )
}
