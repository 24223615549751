import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { TicketsState } from '../types';

const selectDomain = (state: RootState) => state.ticketsState || TicketsState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.isLoading,
);
export const selectTickets = createSelector([selectDomain], state => state.tickets);

export const selectTicket = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectPagination = createSelector(
  [selectDomain],
  state => state.pagination,
);
export const selectCustomizeTicket = createSelector([selectDomain], state => state.customizeForm);
export const selectCustomizeTicketQuestion = createSelector([selectDomain], state => state.customizeFormQuestion);
export const selectIsUnsaved = createSelector([selectDomain], state => state.isUnsaved);
export const selectIsSharing = createSelector([selectDomain], state => state.isSharing);