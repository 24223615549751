import { Box, IconButton } from "@mui/material";
import OutlineButton from "app/components/Buttons/OutlineButton";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import InputField from "app/components/InputField";
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIsLoading, selectProfile } from "../../redux/selector";
import { selectUser } from "app/redux/selectors";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/slice";
import { profileValidator } from "../../validator";
import { RoleTypes } from "app/types";
import { actions as authActions } from "app/redux/slice";
import Uploader from "./Uploader";
import { ASSET_TYPE } from "utils/constants";

export default function Form({
  handleClose,
}: {
  readonly handleClose: () => void;
}) {
  const { t } = useTranslation();
  const profile = useSelector(selectProfile);
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.getUser());
  }, [dispatch]);

  useEffect(() => {
    if (!user) return;
    dispatch(actions.setDefaultProfileValues(user));
  }, [dispatch, user]);

  const onChange = (e) => {
    const { name, value } = e?.target;
    dispatch(actions.setFormValues({ key: name, value }));
  };

  const onUpdate = () => {
    const errors = profileValidator(profile);
    if (errors?.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.updateProfile({
        profile: {
          fullName: profile.fullName.value,
          mobileNumber: profile.mobileNumber.value,
          departmentName: profile.departmentName.value,
          photo: profile.photo,
        },
        callback: () => {
          dispatch(authActions.getUser());
          handleClose();
        },
      })
    );
  };



  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Uploader
            onUpload={(img) => {
              dispatch(
                actions.setFormPhoto({
                  key: "photo",
                  value: {
                    _id: img?._id,
                    type: ASSET_TYPE.IMAGE,
                    mimeType: img?.mimeType,
                    fileName: img?.fileName,
                    filePath: img?.filePath,
                    url: img?.url,
                  },
                })
              );
            }}
            src={profile.photo?.url}
          ></Uploader>
        </Box>
        <InputField
          label={t("PROFILE.FORM.LABELS.NAME")}
          fullWidth
          mainStyle={{
            width: "100%",
          }}
          name="fullName"
          value={profile.fullName.value}
          onChange={onChange}
          error={
            profile?.fullName?.error ? t(profile?.fullName?.error) : undefined
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "24px",
        }}
      >
        <InputField
          fullWidth
          isFieldReq
          mainStyle={{
            width: "100%",
          }}
          maxLength={17}
          label={t("PROFILE.FORM.LABELS.PHONE_NUMBER")}
          name="mobileNumber"
          value={profile.mobileNumber.value}
          onChange={(e) => {
              let { name, value } = e?.target;
              // Allow digits, +, and - characters
              value = value.replace(/[^0-9+-]/g, "");
              
              dispatch(actions.setFormValues({ key: name, value }));
          }}
          error={
            profile?.mobileNumber?.error
              ? t(profile?.mobileNumber?.error)
              : undefined
          }
        />
        {user?.type === RoleTypes.EDITOR && (
          <InputField
            label={t("PROFILE.FORM.LABELS.DEPARTMENT")}
            fullWidth
            mainStyle={{
              width: "100%",
            }}
            name="departmentName"
            value={profile.departmentName.value}
            onChange={onChange}
            error={
              profile?.departmentName?.error
                ? t(profile?.departmentName?.error)
                : undefined
            }
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "24px",
          marginBottom: "24px",
        }}
      >
        <OutlineButton
          label={t("COMMON.BUTTONS.CANCEL")}
          onClick={handleClose}
          disabled={isLoading}
        />
        <PrimaryButton
          label={t("COMMON.BUTTONS.SAVE")}
          onClick={onUpdate}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
}
