import { FormError } from "app/containers/types";
import { Answer, QuestionsForm } from "../../types";

export const validateQuestion = (values: QuestionsForm, answer: Answer[]) => {
  const errors: Array<FormError> = [];
  if (!values.body.value) {
    errors.push({ name: "body", error: "QUESTIONS.ERRORS.BODY_IS_REQUIRED" });
  }

  if (values.showLink.value == "true") {
    if (!values.linkLabel.value) {
      errors.push({
        name: "linkLabel",
        error: "QUESTIONS.ERRORS.LINK_LABEL_IS_REQUIRED",
      });
    }
    if (!values.link.value) {
      errors.push({ name: "link", error: "QUESTIONS.ERRORS.LINK_IS_REQUIRED" });
    } else {
      if (!values.link.value.startsWith("http")) {
        errors.push({
          name: "link",
          error: "QUESTIONS.ERRORS.LINK_SHOULD_START_WITH_HTTP",
        });
      }
    }
  }
  if (values.type.value === "single_select") {
    // @ts-ignore
    const correctAnswers = answer.filter((a) => a?.singleSelect?.isCorrect);
    if (correctAnswers.length !== 1) {
      errors.push({
        name: "answer",
        error: "QUESTIONS.ERRORS.SELECT_ONE_CORRECT_ANSWER",
      });
    }
    const correctAnswersTitle = answer.filter(
      (a) =>
        (a?.singleSelect && a?.singleSelect.title.length > 0) ||
        (a.singleSelect?.asset?.fileName &&
          a.singleSelect?.asset?.fileName.length > 0)
    );

    if (correctAnswersTitle.length < 2) {
      errors.push({
        name: "answer",
        error: "QUESTIONS.ERRORS.SELECT_TWO_TITLE",
      });
    }
  }

  if (values.type.value === "multi_select") {
    // @ts-ignore
    const correctAnswers = answer.filter((a) => a?.multiSelect.isCorrect);
    if (correctAnswers.length === 0) {
      errors.push({
        name: "answer",
        error: "QUESTIONS.ERRORS.SELECT_ATLEAST_TWO_CORRECT_ANSWERS",
      });
    }

    const correctAnswersTitle = answer.filter(
      (a) =>
        (a?.multiSelect && a?.multiSelect.title.length > 0) ||
        (a.multiSelect?.asset?.fileName &&
          a.multiSelect?.asset?.fileName.length > 0)
    );
    if (correctAnswersTitle.length < 2) {
      errors.push({
        name: "answer",
        error: "QUESTIONS.ERRORS.SELECT_TWO_TITLE",
      });
    }
  }
  if (values.type.value === "mixed_letter") {
    // @ts-ignore
    const words = answer.map((a) => a?.mixedLetter?.word);
    if (words.includes("")) {
      errors.push({
        name: "answer",
        error: "QUESTIONS.ERRORS.FILL_ALL_WORDS",
      });
    }
    if (words.filter((x) => x && x.length > 7).length > 0) {
      errors.push({
        name: "answer",
        error: "QUESTIONS.ERRORS.FILL_ALL_MAX_CHAR",
      });
    }
  }

  if (values.type.value === "correlation") {
    // for each items check if left and right are not empty
    for (let index = 0; index < answer.length; index++) {
      const element = answer[index].corelation;
      if (index == 0 || index == 1) {
        // @ts-ignore
        if (element?.left === "" || element?.right === "") {
          errors.push({
            name: "answer",
            error: "QUESTIONS.ERRORS.FILL_ALL_PAIRS",
          });
        }
      } else {
        // @ts-ignore
        if (element?.left !== "" && element?.right === "") {
          errors.push({
            name: "answer",
            error: "QUESTIONS.ERRORS.FILL_PAIRS",
          });
        }
      }
    }
  }

  if (values.type.value === "free_text") {
    for (let index = 0; index < answer.length; index++) {
      const element = answer[index].freeText;
      let count = 0;
      // @ts-ignore
      for (const letter of element?.letters ?? []) {
        // Check if option1 or option2 are empty
        if (letter?.option1) {
          count = count + 1;
        }

      }


      if (index == 0 && count < 2) {
        errors.push({
          name: "answer",
          error: "QUESTIONS.ERRORS.FILL_ALL_LETTERS",
        });
      }
    }
  }

  return errors;
};
