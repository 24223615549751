import React, { useEffect, useState } from "react";
import { Typography, IconButton } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Thumbnail from "./components/Thumbnail";
import MediaModal from "./components/MediaModal"; // Import the MediaModal
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  selectResultInfo,
} from "app/containers/Results/redux/selector";
import { actions } from "../../../../redux/slice";
import "slick-carousel/slick/slick.css"; // Import slick-carousel base styles

export default function GroupPhotos() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<{
    url: string;
    type: string;
    name?: string;
}>({ url: "", type: "image", name: "" });

  useEffect(() => {
    if (!id) return;
    dispatch(actions.getTicketResultInfo(id));
  }, [id]);

  const resultInfo = useSelector(selectResultInfo);
  if (!resultInfo.length) {
    return null;
  }

  const handleThumbnailClick = (url: string, type: "image" | "video" | "text", name?: string) => {
    setModalContent({ url, type, name });
    setModalOpen(true);
  };

  function ArrowBack(props) {
    const { onClick, visible } = props;
    if (!visible) return null;
    return (
      <IconButton
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "30%",
          left: "-50px",
          zIndex: 1,
          transform: "translateY(-50%)",
          background: "#fff",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
    );
  }

  function ArrowForward(props) {
    const { onClick, visible } = props;
    if (!visible) return null;
    return (
      <IconButton
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "30%",
          right: "-50px",
          zIndex: 1,
          transform: "translateY(-50%)",
          background: "#fff",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    );
  }

  return (
    <Grid2
      container
      spacing={2}
      sx={{
        padding: "16px",
        borderRadius: "12px",
        background: "#FFFFFF",
        marginTop: "16px",
        marginBottom: "40px",
        marginX: "12px",
      }}
    >
      <Grid2 md={12}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "32px",
            letterSpacing: "0em",
            color: "#207DB9",
          }}
        >
          {t("RESULTS.PHOTOS_BY_CREATIVE")}
        </Typography>
      </Grid2>
      <Grid2
        md={12}
        sx={{
          marginX: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {resultInfo.map((result, index) => {
          const slidesToShow = Math.min(5, result.stationResults.reduce((acc, m) => acc + m?.data?.length, 0));
          const showArrows = slidesToShow >= 5;

          const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow,
            slidesToScroll: 1,
            nextArrow: <ArrowForward visible={showArrows} />,
            prevArrow: <ArrowBack visible={showArrows} />,
            className: "slider",
          };

          return (
            <div
              key={index}
              style={{
                width: "100%",
                marginBottom: "24px",
                padding: "0 15px",
              }}
            >
              <Typography sx={{ marginBottom: "8px" }}>{result.groupName}</Typography>
              <Slider {...settings}>
                {result.stationResults?.map((m, mIndex) =>
                  m?.data?.map((answer, aIndex) => {
                    const asset  = answer?.answer?.asset
                    const url =  asset?.url  ? asset?.url : ''
                    const isVideo = url?.match(/\.(mp4|webm|ogg|mov)$/i);
                    const isText = !isVideo && !url?.match(/\.(jpg|jpeg|png|gif)$/i);

                    return (
                      <div key={`${mIndex}-${aIndex}`} style={{ padding: "0 8px" }}>
                        <Thumbnail
                          url={url}
                          thumbUrl={answer?.answer?.asset?.thumbUrl}
                          name={answer?.answer?.comment}
                          onClick={() => handleThumbnailClick(
                            url,
                            isVideo ? "video" : isText ? "text" : "image",
                            isText ? answer?.answer?.comment : undefined
                          )}
                        />
                      </div>
                    );
                  })
                )}
              </Slider>
            </div>
          );
        })}
      </Grid2>

      <MediaModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        url={modalContent.url}
        type={modalContent.type as "image" | "video" | "text"}
        name={modalContent.name}
      />
    </Grid2>
  );
}
