import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import AddPhotoAlternateTwoToneIcon from "@mui/icons-material/AddPhotoAlternateTwoTone";
import trash from "assets/ic_delete.svg";
import MediaUploader from "app/components/MediaUploader";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { CreateAssetResponse } from "app/components/DragNDropDialog/types";
import OutlineButton from "app/components/Buttons/OutlineButton";
import { useTranslation } from "react-i18next";
import { MediaTypes } from "app/containers/types";
import { useSelector } from "react-redux";
import { selectCustomizeTicket } from "app/containers/Tickets/redux/selector";

export default function Uploader({
  onUpload,
  isDelete = false,
  onDelete,
  src,
  width,
  height,
  type,
  caption,
}: {
  readonly onUpload: (url?: CreateAssetResponse) => void;
  readonly onDelete: () => void;
  readonly src?: string;
  readonly isDelete?: boolean;
  readonly width?: string;
  readonly height?: string;
  readonly caption: string;
  readonly type?: MediaTypes;
}) {
  const { t } = useTranslation();
  const ticket = useSelector(selectCustomizeTicket);


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "6px",
      }}
    >
      <MediaUploader
        showBtn={false}
        onSuccess={onUpload}
        src={src}
        width={width}
        height={height}
        type={type}
        caption={caption}
        fallbackWidth={width}
        fallbackHeight={height}
      >
        <OutlineButton
          label={ticket?.asset?.fileName ?ticket?.asset?.fileName.length>30?ticket?.asset?.fileName.substring(0,30)+"...":ticket?.asset?.fileName: t("TICKETS.CUSTOMIZED_TICKETS.UPLOAD_VIDEO_IMAGE")}
          sx={{
            textTransform: "uppercase",
            width: "100%",
            fontFamily: "Rubik",
            fontSize: "14px",
            color: "#E24399",
            backgroundColor: "#FFFFFF",
            border: "1px solid #E24399",
            "&:hover": {
              border: "1px solid #E24399",
              color: "#E24399",
              backgroundColor: "#FFFFFF",
              opacity: 0.8,
            },
          }}
          startIcon={<FileUploadOutlinedIcon />}
          endIcon={isDelete ? <DeleteIcon /> : undefined}
        />
      </MediaUploader>
    </Box>
  );
}
