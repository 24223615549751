/**
 * Homepage selectors
 */


import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { RootState } from 'types/RootState';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.cityState || initialState;

export const selectCities = createSelector(
  [selectDomain],
  cityState => cityState.cities,
);

export const selectPagination = createSelector(
    [selectDomain],
    cityState => cityState.pagination,
);

export const selectIsLoading = createSelector(
    [selectDomain],
    cityState => cityState.isLoading,
);
