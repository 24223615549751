import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Details from './components/Details';
import GameList from './components/List';
import { MODES } from 'utils/constants';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { gamesSlice, gamesReducer } from './redux/slice';
import { gamesSaga } from './redux/saga';

const Games = () => {
    useInjectReducer({ key: gamesSlice, reducer: gamesReducer });
    useInjectSaga({ key: gamesSlice, saga: gamesSaga });
    
    return (
        <Routes>
            <Route path="/" element={<GameList />} />
            <Route path=":id/view" element={<Details mode={MODES.VIEW} />} />
            <Route path="new" element={<Details mode={MODES.NEW}  />} />
            <Route path=":id/edit" element={<Details mode={MODES.EDIT} />} /> 
            <Route path=":id/duplicate" element={<Details mode={MODES.DUPLICATE} />} /> 
        </Routes>
    );
};

export default Games;
