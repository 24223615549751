import makeStyles from "@mui/styles/makeStyles";


export const useStyles = makeStyles(() => ({
    heading: {
      fontSize: 16,
      fontWeight: 500,
      textAlign: "center",
      color: "#000",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      width: "100%",
      height: 40,
      fontSize: 22,
      marginTop: 11,
    },
    icon: {
      width: 77,
      height: 70,
      color: "#000",
      margin: "23px 0px 30px 0px",
    },
    input: {
      marginTop: 24,
    },
    subHeading: {
      fontSize: 16,
      lineHeight: "23.87px",
      textAlign: "center",
    },
    loginLink: {
      color: "#EDAC15",
    },
  }));