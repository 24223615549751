import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import ActionButtons from "app/components/ActionButtons";
import { actions as actionsOrganization } from "app/redux/slice";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCustomer,
  selectIsLoading,
  selectIsUnsaved,
} from "../../redux/selector";
import { actions } from "../../redux/slice";
import { validateCustomer } from "./validator";
import { selectOrganization } from "app/redux/selectors";
import ConfirmDialog from "app/components/ConfirmDialog";
import useOrgValidator from "app/containers/Auth/containers/component/OrgValidator";
import NavigationConfirmDialog from "app/components/NavigationConfirmDialog";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer = useSelector(selectCustomer);
  useOrgValidator(customer.organizationId.value);
  const isLoading = useSelector(selectIsLoading);
  const organizationId = useSelector(selectOrganization);
  const isUnsaved = useSelector(selectIsUnsaved);

  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    dispatch(actions.getCustomer(id));
  }, [id, mode, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, [dispatch]);

  const onSubmit = () => {
    const errors = validateCustomer(customer);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.createCustomer({
        customer: {
          organizationId: organizationId?.value ?? "",
          name: customer.name.value,
          companyName: customer.companyName.value,
          taxNumber: customer.taxNumber.value,
          email: customer.email.value,
          address: customer.address.value,
          mobileNumber: customer.mobileNumber.value,
          city: customer.city.value,
        },
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/customers");
              },
            })
          );
        },
      })
    );
  };

  const onUpdate = () => {
    const errors = validateCustomer(customer);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.updateCustomer({
        customer: {
          _id: customer._id.value,
          organizationId: organizationId?.value ?? "",
          name: customer.name.value,
          companyName: customer.companyName.value,
          taxNumber: customer.taxNumber.value,
          email: customer.email.value,
          address: customer.address.value,
          city: customer.city.value,
          mobileNumber: customer.mobileNumber.value,
        },
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/customers");
              },
            })
          );
        },
      })
    );
  };

  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
    navigate(`/app/customers/${id}/edit`);
  };

  const handleCancelBtn = () => {
    if (isUnsaved) {
      setShowConfirm(true);
      return;
    }

    navigate("/app/customers");
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
  };
  const openAddTicketPageInNewTab = () => {
    window.open("/app/tickets/new", "_blank");
  };
  useEffect(() => {
    dispatch(actionsOrganization.getSetEnableOrganization(true));

    return () => {
      dispatch(actionsOrganization.getSetEnableOrganization(false));
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);
  return (
    <>
      <Box className={styles.breadCrumbSectionParent}>
        <BreadCrumb
          title={generatePageTitle(Modules.CUSTOMERS, mode)}
          onBackClick={handleCancelBtn}
        />
        <Box display={"flex"} flexDirection={"row"}>
          <PrimaryButton
            onClick={openAddTicketPageInNewTab}
            label={t("CUSTOMERS.BUTTONS.ADD_TICKET")}
            sx={{
              textTransform: "uppercase",
              backgroundColor: "#E24399",
              width: "170px",
              fontWeight: 500,
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <ActionButtons
            mode={mode}
            handleCancelBtn={handleCancelBtn}
            handleConfirmBtn={handleConfirmBtn}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      <Form mode={mode} />
      <div style={{ height: "30px" }} />
      <NavigationConfirmDialog
        title={t("COMMON.SCREEN_NAME.CUSTOMER")}
        isUnsaved={isUnsaved}
        onConfirm={handelConfirm}
      ></NavigationConfirmDialog>
      <ConfirmDialog
        open={showConfirm}
        handleClose={(val) => {
          setShowConfirm(false);
          dispatch(
            actions.resetForm({
              callback: () => {
                if (!val) {
                  navigate("/app/customers");
                }
              },
            })
          );
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.CUSTOMER"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
    </>
  );
};

export default Details;
