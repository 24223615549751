import { Box, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

interface CounterProps {
    readonly maxCount?: number;
    readonly disabled?: boolean;
    readonly value?: number;
    readonly onChange?: (value: number) => void;
}
export default function Counter({
    maxCount=10,
    disabled,
    value=1,
    onChange,
}:CounterProps) {
    const direction = useSelector(selectDirection);
    const onIncrease = () => {
        if(disabled) return;
        if(value < maxCount) {
            onChange?.(value + 1);
        }
    }

    const onDecrease = () => {
        if(disabled) return;
        if(value > 1) {
            onChange?.(value - 1);
        }
    }
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                maxWidth: "76px",
                height: "25px",
                borderRadius: "4px",
                backgroundColor: "#ffffff",
                padding: "0 5px 0 10px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
            }}
        >
            <Typography
                sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "left",
                }}
            >
                {value}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2px",
                }}
            >
                <KeyboardArrowUpIcon 
                    sx={{
                        fontSize: "18px",
                        mb: "-5px",
                        cursor: "pointer",
                    }}
                    onClick={onIncrease}
                />
                <KeyboardArrowDownIcon 
                    sx={{
                        fontSize: "18px",
                        mt: "-5px",
                        cursor: "pointer",
                    }}
                    onClick={onDecrease}
                />
            </Box>
        </Box>
    )
}
