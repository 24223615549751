import { FormError } from "app/containers/types";
import { TicketForm } from "../../types";
import dayjs from "dayjs";

export const validateTicket = (values: TicketForm) => {
    const errors: Array<FormError> = [];
    if (!values.customerId.value) {
        errors.push({ name: "customerId", error: "TICKETS.ERRORS.CUSTOMER_ID_IS_REQUIRED" });
    }
    if (!values.gameId.value) {
        errors.push({ name: "gameId", error: "TICKETS.ERRORS.GAME_ID_IS_REQUIRED" });
    }
    if (Number(values.noOfDevices.value) < 0) {
        errors.push({ name: "noOfDevices", error: "TICKETS.ERRORS.NO_OF_DEVICES_IS_REQUIRED" });
    }
    if (values.isDatesApplicable.value) {
        if (!values.startDate.value) {
            errors.push({ name: "startDate", error: "TICKETS.ERRORS.START_DATE_IS_REQUIRED" });
        }
        if (!values.endDate.value) {
            errors.push({ name: "endDate", error: "TICKETS.ERRORS.END_DATE_IS_REQUIRED" });
        }
        if (values.startDate.value && values.endDate.value) {
            if (dayjs(values.startDate.value).isAfter(values.endDate.value)) {
                errors.push({ name: "endDate", error: "TICKETS.ERRORS.START_DATE_IS_AFTER_END_DATE" });
            }
        }
    }

    if (!(values.maxQuestions.value >= 0 && values.maxQuestions.value <= 99)) {
        errors.push({ name: "maxQuestions", error: "TICKETS.ERRORS.MAX_CUSTOM_QUESTION_OUT_OF_BOUNDS" });
    }

    return errors;
};