import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { Place, PlaceState } from "app/containers/Stations/components/Details/components/Address/type";

const initialState = PlaceState;

export const formSlice = createSlice({
  name: "placesState",
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<string>) => {
      state.form.q.value = action.payload;
      state.form.q.error = "";
    },
    getPredictions: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    setPredictions: (state, action: PayloadAction<Place[]>) => {
      state.predictions = action.payload;
      state.isLoading = false;
    },
    getCordinates: (state, action: PayloadAction<string>) => {},
    getPredictionsError: (state, action: PayloadAction<string>) => {
      state.form.q.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { reducer: placesReducer, actions, name: placesSlice } = formSlice;
