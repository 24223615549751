import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { InitialGamesState } from '../types';


const selectDomain = (state: RootState) => state.gamesState || InitialGamesState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.isLoading,
);
export const selectGames = createSelector([selectDomain], state => state.games);
export const selectGame = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectDefaultTicket = createSelector(
  [selectDomain],
  state => state.form.defaultTicket,
);

export const selectPagination = createSelector(
  [selectDomain],
  state => state.pagination
)

export const selectStations = createSelector(
  [selectDomain],
  state => state.stations
)

export const selectStationsPagination = createSelector(
  [selectDomain],
  state => state.stationsPagination
)

export const selectSelectedStations = createSelector(
  [selectDomain],
  state => state.selectedStations
)

export const selectIsLoadingStations = createSelector(
  [selectDomain],
  state => state.isLoadingStations
)
export const selectIsUnsaved = createSelector([selectDomain], state => state.isUnsaved);