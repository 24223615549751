import { call, put, takeLatest } from 'redux-saga/effects';
import { DEFAULT_COORDINATES, GOOGLE_API_KEY, translate } from 'utils/constants';
import { Res, request } from 'utils/request';
import { actions } from './slice';
import { Place } from 'app/containers/Stations/components/Details/components/Address/type';
import { removeBlankProperties } from 'utils/helpers';
import { actions as stationActions } from 'app/containers/Stations/redux/slice';




export function* placesSaga() {

}