import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { EditorsState } from '../types';

const selectDomain = (state: RootState) => state.editorsState || EditorsState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectEditors = createSelector([selectDomain], state => state.editors);
export const selectEditor = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectPagination = createSelector([selectDomain], state => state.pagination);
export const selectIsUnsaved = createSelector([selectDomain], state => state.isUnsaved);
