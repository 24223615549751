import React from "react";
import PopupDialog from "../PopupDialog";
import { Box, Typography } from "@mui/material";
import Summary from "./components/Summary";
import Form from "./components/Form";
import { useTranslation } from "react-i18next";
import { profileReducer, profileSlice } from "./redux/slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { profileSaga } from "./redux/saga";
import { useSelector } from "react-redux";
import { selectDirection } from "styles/theme/slice";

export default function Profile({ open, handleClose }) {
  useInjectReducer({ key: profileSlice, reducer: profileReducer });
  useInjectSaga({ key: profileSlice, saga: profileSaga });
  const { t } = useTranslation()
  const direction = useSelector(selectDirection);
  return (
    <PopupDialog open={open} handleClose={handleClose}>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          paddingX: "50px",
          paddingY: "30px",
          minWidth: "500px",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "29px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#207DB9",
          }}
        >
          {t("PROFILE.TITLE")}
        </Typography>
        <Summary />
        <Form 
          handleClose={handleClose}
        />
      </Box>
    </PopupDialog>
  );
}
