/**
 * Homepage selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.resetPasswordState || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectPassword = createSelector(
  [selectDomain],
  state => state.password,
);

export const selectConfirmPassword = createSelector(
  [selectDomain],
  state => state.confirmPassword,
);

export const selectError = createSelector(
  [selectDomain],
  state => state.error,
);