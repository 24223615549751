import React from 'react'
import { Box, Typography } from '@mui/material';
import { Option } from 'app/types';
import SelectInput from './components/SelectInput';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { editorSelectReducer, editorSelectSlice } from './redux/slice';
import { editorsSelectSaga } from './redux/saga';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

interface EditorSelectProps {
    readonly label?: string;
    readonly value?: Option;
    readonly onChange?: (newValue?: Option | null) => void;
    readonly isFieldReq?: boolean;
    readonly mainStyle?: React.CSSProperties;
    readonly labelStyle?: React.CSSProperties;
    readonly isLoading?: boolean;
    readonly error?: string;
    readonly disabled?: boolean;
    readonly borderWidth?: number;
    readonly backgroundColor?: string;
}

export default function EditorsSelect({
    value,
    onChange,
    isFieldReq=true,
    mainStyle,
    labelStyle,
    isLoading,
    error,
    disabled,
    label,
    borderWidth,
    backgroundColor
}: EditorSelectProps) {
    useInjectReducer({ key: editorSelectSlice, reducer: editorSelectReducer });
    useInjectSaga({ key: editorSelectSlice, saga: editorsSelectSaga });
  const direction = useSelector(selectDirection);
    return (
        <Box
            sx={{ 
                marginBottom: "10px",
                width: "100%",
            }} 
            style={mainStyle}
        >
            {
                label &&
                <Typography
                        sx={{
                            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            display: "-webkit-box",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            ...labelStyle
                        }}
                        >
                        {label}
                        {isFieldReq && <span style={{ color: '#ff0000' }}> *</span>}
                    </Typography>
            }
            <SelectInput 
                value={value}
                onChange={onChange}
                isLoading={isLoading}
                disabled={disabled}
                error={error}
                backgroundColor={backgroundColor}
                borderWidth={borderWidth}
            />
        </Box>
    )
}
