import React from "react";
import { TableHeaderColumnType } from "app/components/Table/types";
import fileIcon from "assets/ic_file.svg";
import qr from "assets/ic_qr.svg";
import controller from "assets/ic_controller.svg";
import edit from "assets/ic_edit.svg";
import folder from "assets/ic_folder.svg";
import trash from "assets/ic_trash.svg";
import { ListItem } from "app/containers/types";

export const TableHeaderDefinition = {
  TICKET_ID: {
    id: "_id",
    label: "TICKET_ID",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: false,
  },
  CREATED_DATE: {
    id: "createdAt",
    label: "CREATED_DATE",
    format: (value) => value,
    maxWidth: 160,
    minWidth: 160,
    sortable: true,
  },
  EDITED_DATE: {
    id: "updatedAt",
    label: "EDITED_DATE",
    format: (value) => value,
    maxWidth: 160,
    minWidth: 160,
    sortable: true,
  },
  VALID_FROM: {
    id: "startDate",
    label: "VALID_FROM",
    format: (value) => value,
    maxWidth: 110,
    minWidth: 110,
    sortable: true,
  },
  VALID_TO: {
    id: "endDate",
    label: "VALID_TO",
    format: (value) => value,
    maxWidth: 110,
    minWidth: 110,
    sortable: true,
  },
  CREDITS: {
    id: "credits",
    label: "CREDITS",
    format: (value) => value,
    maxWidth: 90,
    minWidth: 90,
    sortable: false,
  },
  NUMBER_OF_DEVICES: {
    id: "noOfDevices",
    label: "NUMBER_OF_DEVICES",
    format: (value) => value,
    maxWidth: 90,
    minWidth: 90,
    sortable: false,
  },
  CUSTOMIZED: {
    id: "customizations.isCustomizationAvailable",
    label: "CUSTOMIZED",
    format: (value) => value,
    maxWidth: 120,
    minWidth: 120,
    sortable: true,
  },
  CUSTOMER_NAME: {
    id: "customer.name",
    label: "CUSTOMER_NAME",
    format: (value) => value,
    maxWidth: 140,
    minWidth: 140,
    sortable: true,
  },
  GAME_NAME: {
    id: "game.name",
    label: "GAME_NAME",
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable: true,
  },
  TICKET_CREATOR: {
    id: "game.creator",
    label: "TICKET_CREATOR",
    format: (value) => value,
    maxWidth: 140,
    minWidth: 140,
    sortable: true,
  },
  PASSWORD: {
    id: "game.password",
    label: "PASSWORD",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  NUMBER_OF_STATIONS: {
    id: "game.noOfStations",
    label: "NUMBER_OF_STATIONS",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
};

export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);

export const MenuOptions: ListItem[] = [
  {
    id: "1",
    name: "DOWNLOAD_QR",
    onClick: () => console.log("Edit"),
    icon: <img src={qr} alt="qr" />,
  },
  {
    id: "2",
    name: "DOWNLOAD_ANSWERS",
    onClick: () => console.log("Edit"),
    icon: <img src={fileIcon} alt="edit" />,
  },
  {
    id: "3",
    name: "SEND_TABLE_RESULTS",
    onClick: () => console.log("Delete"),
    icon: <img src={folder} alt="trash" />,
  },
  {
    id: "4",
    name: "GAME_PAGE",
    onClick: () => console.log("Download File"),
    icon: <img src={controller} alt="file" />,
  },
  {
    id: "5",
    name: "EDIT",
    onClick: () => console.log("Download Folder"),
    icon: <img src={edit} alt="folder" />,
  },
  {
    id: "6",
    name: "DELETE",
    onClick: () => console.log("Download Folder"),
    icon: <img src={trash} alt="controller" />,
  },
];
