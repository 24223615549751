import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { CustomersState } from '../types';

const selectDomain = (state: RootState) => state.customersState || CustomersState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.isLoading,
);

export const selectCustomers = createSelector(
  [selectDomain],
  state => state.customers,
);

export const selectCustomer = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectPagination = createSelector(
  [selectDomain],
  state => state.pagination,
);
export const selectIsUnsaved = createSelector([selectDomain], state => state.isUnsaved);