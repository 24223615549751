import { FormElement, FormError } from "app/containers/types";
import { translate } from "utils/constants";

export function validate(email: FormElement): FormError|undefined {
  const emailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!email.value) {
    return {
      name: "email",
      error: translate("AUTH.ERRORS.LOGIN.EMAIL_IS_REQUIRED"),
    };
  } 
  else if (!emailFormat.test(email.value)) {
    return {
      name: "email",
      error: translate("AUTH.ERRORS.LOGIN.INVALID_EMAIL"),
    };
  }
}
