import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useBeforeUnload, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import ConfirmDialog from "app/components/ConfirmDialog";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ActionButtons from "app/components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slice";
import { actions as actionsOrganization } from "app/redux/slice";
import {
  selectIsLoading,
  selectIsUnsaved,
  selectTicket,
} from "../../redux/selector";
import { validateTicket } from "./validator";
import { useTranslation } from "react-i18next";
import Option from "app/components/ConfirmDialog/Component/Option";
import { Box } from "@mui/material";
import Summary from "./components/Summary";
import useOrgValidator from "app/containers/Auth/containers/component/OrgValidator";
import NavigationConfirmDialog from "app/components/NavigationConfirmDialog";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const [confirmGoBack, setConfirmGoBack] = useState(false);
  const dispatch = useDispatch();
  const isUnsaved = useSelector(selectIsUnsaved);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [saveOption, setSaveOption] = useState(0);
  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);
  const ticket = useSelector(selectTicket);
  useOrgValidator(ticket.organizationId.value);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    dispatch(actions.getTicket(id));
  }, [mode, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, [dispatch]);

  const onSubmit = () => {
    const errors = validateTicket(ticket);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.createTicket({
        ticket: {
          customerId: ticket.customerId.value?.value,
          gameId: ticket.gameId.value?.value,
          noOfDevices: Number(ticket.noOfDevices.value || 0),
          startDate: ticket.startDate.value,
          endDate: ticket.endDate.value,
          isDatesApplicable: ticket.isDatesApplicable.value,
          customizations: {
            isCustomizationAvailable: ticket.isCustomizationAvailable.value,
            maxQuestions: parseInt(ticket.maxQuestions.value),
          },
          testPassword: ticket.testPassword.value,
          password: ticket.password.value,
        },
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/tickets");
              },
            })
          );
        },
      })
    );
  };

  const onUpdate = () => {
    const errors = validateTicket(ticket);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }

    dispatch(
      actions.updateTicket({
        ticket: {
          customerId: ticket.customerId.value?.value,
          gameId: ticket.gameId.value?.value,
          noOfDevices: Number(ticket.noOfDevices.value || 0),
          startDate: ticket.startDate.value,
          endDate: ticket.endDate.value,
          isDatesApplicable: ticket.isDatesApplicable.value,
          customizations: {
            isCustomizationAvailable: ticket.isCustomizationAvailable.value,
            maxQuestions: parseInt(ticket.maxQuestions.value),
          },
          testPassword: ticket.testPassword.value,
          password: ticket.password.value,
          _id: ticket._id.value,
        },
        callback: () => {
          dispatch(
            actions.resetForm({
              callback: () => {
                navigate("/app/tickets");
              },
            })
          );
        },
      })
    );
  };

  const handleCancelBtn = () => {
    if (isUnsaved) {
      setShowConfirm(true);
      return;
    }

    navigate("/app/tickets");
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
  };

  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
    navigate(`/app/tickets/${id}/edit`);
  };
  const handelSaveConfirm = () => {
    if (saveOption == 0) {
      onUpdate();
    } else {
      setTimeout(() => {
        onSubmit();
      }, 500);
    }
    setShowSaveConfirm(false);
    setSaveOption(0);
  };

  useEffect(() => {
    dispatch(actionsOrganization.getSetEnableOrganization(true));

    return () => {
      dispatch(actionsOrganization.getSetEnableOrganization(false));
      dispatch(actions.resetForm({ callback: () => {} }));
    };
  }, []);
  return (
    <>
      <Grid2 container>
        <Grid2 xs={12}>
          <div className={styles.breadCrumbSectionParent}>
            <BreadCrumb
              onBackClick={handleCancelBtn}
              title={generatePageTitle(Modules.TICKETS, mode)}
            />
            <Box>
              <ActionButtons
                handleCancelBtn={handleCancelBtn}
                handleConfirmBtn={handleConfirmBtn}
                mode={mode}
                isLoading={isLoading}
              />
            </Box>
          </div>
          <Summary />
          <Form mode={mode} />
        </Grid2>
      </Grid2>
      <ConfirmDialog
        open={confirmGoBack}
        onConfirm={handleCancelBtn}
        handleClose={() => setConfirmGoBack(false)}
      />
      <NavigationConfirmDialog
        title={t("COMMON.SCREEN_NAME.GAME")}
        isUnsaved={isUnsaved}
        onConfirm={handelConfirm}
      ></NavigationConfirmDialog>
      <ConfirmDialog
        open={showConfirm}
        handleClose={(val) => {
          setShowConfirm(false); 
          dispatch(
            actions.resetForm({
              callback: () => {
                if (!val) {
                  navigate("/app/tickets");
                }
              },
            })
          );
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.TICKET"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
      <ConfirmDialog
        open={showSaveConfirm}
        handleClose={() => {
          setShowSaveConfirm(false);
          setSaveOption(0);
        }}
        onConfirm={handelSaveConfirm}
        title={t("COMMON.SAVED_CHANGES.TITLE", { name: t("EDITORS.EDITOR") })}
        message={t("COMMON.SAVED_CHANGES.MESSAGE", {
          name: t("EDITORS.EDITOR"),
        })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.SAVE")}
      >
        <Option
          disabled={false}
          value={saveOption}
          onChange={(e) => {
            setSaveOption(e);
          }}
        />
      </ConfirmDialog>
    </>
  );
};

export default Details;
