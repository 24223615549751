import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Styles } from "./styles";
import { useNavigate } from "react-router-dom";
import Logo from "assets/logo.png";
import bankImage from 'assets/bank.png';
import businessImage from 'assets/business.png';
import checkedImage from 'assets/checked.png';
import cubeImage from 'assets/cube.png';
import helpImage from 'assets/help.png';
import ticketsImage from 'assets/tickets.png';
import userImage from 'assets/user.png';
import usergroupImage from 'assets/usergroup.png';
import { useSelector } from "react-redux";
import { selectRole } from "app/redux/selectors";
import { useTranslation } from "react-i18next";
import { RoleTypes } from "app/types";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerItems = [
  {
    name: "TICKETS",
    icon: ticketsImage,
    route: "/app/tickets",
  },
  {
    name: "GAMES",
    icon: cubeImage,
    route: "/app/games",
  },
  {
    name: "STATIONS",
    icon: bankImage,
    route: "/app/stations",
  },
  {
    name: "QUESTIONS",
    icon: helpImage,
    route: "/app/questions",
  },
  {
    name: "CUSTOMERS",
    icon: userImage,
    route: "/app/customers",
  },
  {
    name: "ORGANIZATIONS",
    icon: businessImage,
    route: "/app/organizations",
  },
  {
    name: "EDITORS",
    icon: usergroupImage,
    route: "/app/editors",
  },
  {
    name: "RESULTS",
    icon: checkedImage,
    route: "/app/results",
  }
];

export const CustomDrawer = (props: Props) => {
  const styles = Styles();
  const role = useSelector(selectRole);
  const urlParam = window.location.pathname;
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Box sx={{ 
      display: "flex",
      position: "absolute",
      height: "100%",
      top: "0px",
    }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ 
          flex: 1,
          marginRight: "5px",
        }}
        aria-label="mailbox folders"
      >
        <div>
          <div className={styles.logoImage}>
            <img src={Logo} alt="logo" width="145px" height="50px" />
          </div>
          <List sx={{ marginLeft: "5px" }}>
            {drawerItems.map((item, index) => {
              const name = item.name;
              const route = item.route;
              if(role !== RoleTypes.ADMIN && route === "/app/organizations") return null;
              return (
                <ListItem
                  key={index}
                  disablePadding
                  className={urlParam?.startsWith(route) ? styles.drawerListItems : styles.drawerListUnselectedItems}
                  onClick={() => navigate(route)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <img
                        src={item.icon}
                        style={{ backgroundColor: urlParam?.startsWith(route) ? "#E24399" : "", borderRadius: "4px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={
                        urlParam?.startsWith(route) ? styles.drawerListItemText : styles.drawerListItemUnselectedText
                      }
                      disableTypography
                    >
                      {
                        t(`COMMON.MODULES.${name}`)
                      }
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>
      </Box>
    </Box>
  );
};
