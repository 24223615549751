import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from 'app/redux/slice';
import { selectIsLoadingOrganizations } from "app/redux/selectors";

const useOrgValidator = (organizationId: string) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingOrganizations);

  useEffect(() => {
    if (!isLoading && organizationId) {
      dispatch(actions.setToCurrentOrganization(organizationId));
    }
  }, [organizationId, dispatch, isLoading]);
};

export default useOrgValidator;