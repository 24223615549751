import React, { useEffect } from "react";
import InputField from "app/components/InputField";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { MODES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Questions/redux/slice";
import { selectAnswer } from "app/containers/Questions/redux/selector";
import { MixedLetterAnswer } from "app/containers/Questions/types";
import { useTranslation } from "react-i18next";
const Data: MixedLetterAnswer[] = [
  {
    _id: "",

    word: "",
  },
  {
    _id: "",

    word: "",
  },
];
const MixedLetters = ({
  disabled,
  mode,
}: {
  disabled?: boolean;
  mode: MODES;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const answers = useSelector(selectAnswer);
  useEffect(() => {
    if (mode == MODES.NEW) {
      dispatch(actions.setMixedLetterAnswer(Data));
    } else {
      if (!answers.some((x) => x.mixedLetter)) {
        dispatch(actions.setMixedLetterAnswer(Data));
      }
    }
    return () => {};
  }, [mode]);

  return (
    <Grid2 spacing={3} container sx={{ marginBottom: "200px" }}>
      {answers.map((item, index) => (
        <Grid2 xs={6} key={index}>
          <InputField
            label={`${t("QUESTIONS.FORM.LABELS.WORD")} ${index + 1}`}
            placeholder={`${t("QUESTIONS.FORM.LABELS.ENTER_WORD")} ${
              index + 1
            }`}
            name="wordOne"
            fullWidth={true}
            borderColor="#D7D7E0"
            isDisable={disabled}
            lightPlaceholder
            labelStyle={{
              color: "#5E6781",
              fontSize: "14px",
            }}
            value={item.mixedLetter?.word}
            onChange={(e) => {
              dispatch(
                actions.setUpdateAnswer({
                  index: index,
                  key: "word",
                  type: "mixedLetter",
                  value: e.target.value,
                })
              );
            }}
          />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default MixedLetters;
