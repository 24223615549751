import { Box, Typography } from "@mui/material";
import OutlineButton from "app/components/Buttons/OutlineButton";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import InputField from "app/components/InputField";
import PopupDialog from "app/components/PopupDialog";
import { selectIsSharing } from "app/containers/Tickets/redux/selector";
import { actions } from "app/containers/Tickets/redux/slice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectDirection } from "styles/theme/slice";

export default function ShareForm({
  open,
  handleClose,
}: {
  readonly open: boolean;
  readonly handleClose: () => void;
}) {
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const [emails, setEmails] = React.useState("");
  const [error, setError] = React.useState("");
  const isLoading = useSelector(selectIsSharing);
  const dispatch = useDispatch();
  const { id } = useParams();

  const onClose = ()=>{
    setEmails("")
    setError("")
    handleClose()
  }
  const onConfirm = () => {
    if (!emails) {
      setError("COMMON.ERRORS.REQUIRED");
      return;
    }
    const emailList = emails.split(",");
    for (const element of emailList) {
      if (!RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).exec(element.trim())) {
        setError("COMMON.ERRORS.INVALID_EMAIL");
        return;
      }
    }
    dispatch(
      actions.shareResult({
        emails,
        id: id as string,
        callback: onClose,
      })
    );
  };

  return (
    <PopupDialog open={open} handleClose={isLoading ? undefined : onClose}>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignItems: "center",
          paddingX: "50px",
          paddingY: "30px",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "29px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#207DB9",
          }}
        >
          {t("TICKETS.FORM.SHARE_RESULTS")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#3C3C43",
          }}
        >
          {t("TICKETS.FORM.SHARE_RESULTS_DESC")}
        </Typography>
        <InputField
          label={t("TICKETS.FORM.EMAIL")}
          onChange={(e) => {
            setEmails(e.target.value);
            setError("");
          }}
          fullWidth
          mainStyle={{ width: "100%" }}
          value={emails}
          error={error ? t(error) : ""}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            mt: "10px",
          }}
        >
          <OutlineButton
            label={t("COMMON.BUTTONS.CANCEL")}
            disabled={isLoading}
            sx={{
              textTransform: "none",
            }}
            onClick={onClose}
          />
          <PrimaryButton
            isLoading={isLoading}
            disabled={!emails || isLoading || !!error}
            label={t("COMMON.BUTTONS.SHARE")}
            sx={{
              textTransform: "none",
            }}
            onClick={onConfirm}
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}
