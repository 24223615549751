import React, { useState } from "react";
import ThankYou from "../component/Thankyou";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { resetPasswordReducer, resetPasswordSlice } from "./redux/slice";
import { resetPasswordSaga } from "./redux/saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

const ResetPassword = () => {
  const [isUpdated, setIsUpdated] = useState(false);
  useInjectReducer({ key: resetPasswordSlice, reducer: resetPasswordReducer });
  useInjectSaga({ key: resetPasswordSlice, saga: resetPasswordSaga });

  return !isUpdated ? (
    <ResetPasswordForm onSuccess={setIsUpdated} />
  ) : (
    <ThankYou />
  );
};

export default ResetPassword;
