import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { StationTypes } from 'app/containers/Stations/types';
import React from 'react'
import { getStationType } from 'utils/constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: StationTypes;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== getStationType(index)}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === getStationType(index) && <Grid2 sx={{ p: "20px 5px" }}>{children}</Grid2>}
    </div>
  );
}
