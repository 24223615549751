import { FormError } from "app/containers/types";
import { LoginForm } from "../../../../types";
import { translate } from "utils/constants";

export function validate(form: LoginForm): Array<FormError> {
  const errors: Array<FormError> = [];
  const emailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!form.email.value) {
    errors.push({
      name: "email",
      error: translate("AUTH.ERRORS.LOGIN.EMAIL_IS_REQUIRED"),
    });
  } 
  else if (!emailFormat.test(form.email.value)) {
    errors.push({
      name: "email",
      error: translate("AUTH.ERRORS.LOGIN.INVALID_EMAIL"),
    });
  }
  if (!form.password.value) {
    errors.push({
      name: "password",
      error: translate("AUTH.ERRORS.LOGIN.PASSWORD_IS_REQUIRED"),
    });
  }

  return errors;
}
