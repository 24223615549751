import React from "react";
import { Box, Typography } from "@mui/material";
import { Option } from "app/types";
import SelectInput from "./components/SelectInput";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { citySlice, cityReducer } from "./redux/slice";
import { citiesSaga } from "./redux/saga";
import { useSelector } from "react-redux";
import { selectCities } from "./redux/selector";
import { useTranslation } from "react-i18next";
import { selectDirection } from "styles/theme/slice";

interface CitySelectProps {
  readonly label?: string;
  readonly value?: Option;
  readonly onChange?: (newValue?: Option | null) => void;
  readonly isFieldReq?: boolean;
  readonly mainStyle?: React.CSSProperties;
  readonly labelStyle?: React.CSSProperties;
  readonly isLoading?: boolean;
  readonly error?: string;
  readonly disabled?: boolean;
  readonly borderWidth?: number;
  readonly backgroundColor?: string;
}

export default function CitySelect({
  value,
  onChange,
  isFieldReq = true,
  mainStyle,
  labelStyle,
  isLoading,
  error,
  disabled,
  label,
  borderWidth,
  backgroundColor,
}: CitySelectProps) {
  useInjectReducer({ key: citySlice, reducer: cityReducer });
  useInjectSaga({ key: citySlice, saga: citiesSaga });
  const direction = useSelector(selectDirection);
  const cities = useSelector(selectCities);
  const renderValue = () => {
    if (value?.value === "") {
      return value;
    }
    if (value?.label === "") {
      const cityItem = cities.filter((x) => x._id == value.value)[0];
      if (cityItem) {
        return { label: cityItem.name, value: value?.value };
      }
      return value;
    }
    return value;
  };

  return (
    <Box
      sx={{
        marginBottom: "10px",
        width: "100%",
      }}
      style={mainStyle}
    >
      {label && (
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0em",
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            ...labelStyle,
          }}
        >
          {label}
          {isFieldReq && <span style={{ color: "#ff0000" }}> *</span>}
        </Typography>
      )}
      <SelectInput
        value={renderValue()}
        onChange={onChange}
        isLoading={isLoading}
        disabled={disabled}
        error={error}
        backgroundColor={backgroundColor}
        borderWidth={borderWidth}
      />
    </Box>
  );
}
