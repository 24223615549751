import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { ResultsState } from '../types';

const selectDomain = (state: RootState) => state.resultsState || ResultsState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectList = createSelector([selectDomain], state => state.list);
export const selectCurrentResultId = createSelector([selectDomain], state => state.currentResult);
export const selectGroupPhotos = createSelector([selectDomain], state => state.list.filter((x)=>x.allowedToPublish && x?.groupPhoto?.url));
export const selectTicket = createSelector([selectDomain], state => state.ticket);

export const selectPagination = createSelector([selectDomain], state => state.pagination);
export const selectResultInfo = createSelector([selectDomain], state => {
  return state.resultInfo.results.map(result => ({
    ...result,
    stationResults: result.stationResults.filter(stationResult => Object.keys(stationResult).length > 0)
  })).filter(result => result.stationResults.length > 0);
});
export const selectError = createSelector([selectDomain], state => state.error);
export const selectCurrentResult = createSelector([selectDomain], state => {
  const currentResult = state.list.find(f => f._id == state.currentResult)
  return currentResult
})
export const selectStationsInfo = createSelector([selectDomain], state => {
  const ticket = state.ticket
  const currentResult = state.list.find(f => f._id == state.currentResult)
  if(!currentResult) {return []}

  const currentStationIndex =  currentResult.assignedStations?.indexOf(currentResult.currentStation?._id)as number
  
  const res = (ticket?.game?.gameStations || []).map((s: any, index) => {
    const station = s.station
    const i =  currentResult.assignedStations?.indexOf(station._id) as number
    let status = 'IN_PROGRESS'
    if(currentStationIndex > -1 && i > -1) {
      if(currentStationIndex < i) {status =  'PENDING'} 
      if(currentStationIndex > i) {status =  'DONE'} 
    }

    return {
      id: index + 1,
      name:   station.name,
      status,
    }
  })

  return res

});
