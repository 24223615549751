import React, { useState } from 'react';
import ThankYou from './components/ThankYou';
import { CreatePasswordForm } from './components/CreatePasswordForm';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createPasswordReducer, createPasswordSlice } from './redux/slice';
import { createPasswordSaga } from './redux/saga';

type Props = {}

const CreatePassword = (props: Props) => {
  useInjectReducer({ key: createPasswordSlice, reducer: createPasswordReducer });
  useInjectSaga({ key: createPasswordSlice, saga: createPasswordSaga });

  const [isUpdated, setIsUpdated] = useState(false);
  return !isUpdated ? <CreatePasswordForm onSuccess={()=>setIsUpdated(true)} /> : <ThankYou />
}

export default CreatePassword