import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React from 'react'
import DatePicker from './DatePicker';
import { useTranslation } from 'react-i18next';
import { selectTicket } from 'app/containers/Tickets/redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/containers/Tickets/redux/slice';
import { selectDirection } from 'styles/theme/slice';

export default function DateRangePicker({
    containerStyle,
    disabled=false
}:{
    readonly containerStyle?: React.CSSProperties;
    readonly disabled?: boolean;
}) {
    const { t } = useTranslation();
    const ticket = useSelector(selectTicket);
    const dispatch = useDispatch();
  const direction = useSelector(selectDirection);

    return (
        <>
            <Box
                sx={{
                    borderRadius: "4px",
                    px: "16px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "52.81px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #86BDE3",
                    ...containerStyle
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "6px",
                    }}
                >
                    <FormControlLabel
                        sx={{
                            margin: 0,
                            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                            fontWeight: 500,
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            textAlign: "left",
                        }}
                        label={
                            <Typography
                                sx={{
                                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "14px",
                                    letterSpacing: "0em",
                                    opacity: (disabled || !ticket?.isDatesApplicable?.value) ? 0.5 : 1,
                                    display: {
                                        md: "none",
                                        lg: "block",
                                    }
                                }}
                            >
                                {t("COMMON.LABELS.FROM_DATE")}
                            </Typography>
                        }
                        control={
                            <Checkbox
                                size="small"
                                sx={{
                                    color: "#207DB9",
                                    "&.Mui-checked": {
                                        color: "#207DB9",
                                    },
                                }}
                                disabled={disabled}
                                value={ticket?.isDatesApplicable?.value}
                                checked={ticket?.isDatesApplicable?.value}
                                onChange={(e)=>dispatch(actions.setFormValues({ key: "isDatesApplicable", value: e.target.checked }))}
                            />
                        }
                    />
                    <DatePicker 
                        value={ticket?.startDate?.value}
                        onChange={(date)=>dispatch(actions.setFormValues({ key: "startDate", value: date }))}
                        disabled={!ticket?.isDatesApplicable?.value}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "6px",
                    }}
                >
                    
                    <Typography
                        sx={{
                            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "14px",
                            letterSpacing: "0em",
                            opacity: (disabled || !ticket?.isDatesApplicable?.value) ? 0.5 : 1,
                            display: {
                                md: "none",
                                lg: "block",
                            }
                        }}
                    >
                        {t("COMMON.LABELS.TO_DATE")}
                    </Typography>
                    <DatePicker 
                        value={ticket?.endDate?.value}
                        onChange={(date)=>dispatch(actions.setFormValues({ key: "endDate", value: date }))}
                        disabled={!ticket?.isDatesApplicable?.value}
                    />
                </Box>
            </Box>
            {
                ticket?.startDate?.error && 
                <Typography 
                    sx={{ 
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "18px",
                        letterSpacing: "0em",
                        margin: 0,
                        color: '#d32f2f',
                        marginTop: "5px",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    {t(ticket?.startDate?.error)}
                </Typography>
            }
            {
                ticket?.endDate?.error && 
                <Typography 
                    sx={{ 
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "18px",
                        letterSpacing: "0em",
                        margin: 0,
                        color: '#d32f2f',
                        marginTop: "5px",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    {t(ticket?.endDate?.error)}
                </Typography>
            }
        </>
    )
}
