/**
 * Gets the repositories of the user from Github
 */

import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import toastService from "utils/toast";
import { FormElement } from "app/containers/types";
import { selectEmail } from "./selectors";

export function* forgotPasswordApi({ payload }) {
  yield delay(500);
  try {

    const email: FormElement = yield select(selectEmail);
    const requestURL = `${API_URL}/v1/auth/passwords/change-password`;
    const options = {
      method: "PATCH",
      body: JSON.stringify({ email: email.value }),
    };
    const {err}: Res<{}> = yield call(request, requestURL, options);
    if(err){
      throw err;
    }
    yield put(actions.submitSuccess());
    yield payload?.callback?.();
  } catch (err: any) {
    yield put(actions.submitError());
    let errorMessage = err?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {
    }
    toastService.error(errorMessage?.message || errorMessage || "Something went wrong");
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* forgotPasswordSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.submit, forgotPasswordApi);
}
