import { Button, IconButton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import InputField from "app/components/InputField";
import MediaUploader from "app/components/MediaUploader";
import { selectGame } from "app/containers/Games/redux/selector";
import { actions } from "app/containers/Games/redux/slice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function UpperSection({
  disabled,
}: {
  readonly disabled: boolean;
}) {
  const { t } = useTranslation();
  const game = useSelector(selectGame);
  const dispatch = useDispatch();
  const [showUploader, setShowUploader] = React.useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(actions.setFormValues({ key: name, value }));
  };

  return (
    <Grid2
      spacing={3}
      container
      sx={{ backgroundColor: "#F3F6F8", borderRadius: "8px", padding: "24px" }}
    >
      <Grid2 md={4}>
        <InputField
          label={t("COMMON.LABELS.GAME_NAME")}
          placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
          fullWidth={true}
          isDisable={disabled}
          lightPlaceholder
          isFieldReq
          name="name"
          value={game?.name?.value}
          onChange={onChange}
          maxLength={64}
          error={game?.name?.error ? t(game?.name?.error) : ""}
        />
      </Grid2>
      <Grid2 md={12} />
      <Grid2 container md={12} position={'relative'}>
        <IconButton
          size="small"
          onClick={() => setShowUploader(!showUploader)}
          sx={{
            width: '30px',
            height: '30px',
            textTransform: "none",
            alignItems: "center",
            position: 'absolute',
            left:' -10px',
            top: '21px',
          }}
        >
          {showUploader ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        <Grid2 md={6}>
          <MediaUploader
            expanded={showUploader}
            label={t("COMMON.LABELS.BACKGROUND_IMAGE")}
            tooltip={t("GAMES.FORM.LABELS.BACKGROUND_IMAGE_TOOLTIP")}
            src={game?.backgroundUrl?.value}
            onSuccess={(res) => {
              dispatch(
                actions.setFormValues({ key: "backgroundId", value: res?._id })
              );
              dispatch(
                actions.setFormValues({ key: "backgroundUrl", value: res?.url })
              );
            }}
            error={
              game?.backgroundUrl?.error ? t(game?.backgroundUrl?.error) : ""
            }
            disabled={disabled}
            btnLabel={t("COMMON.BUTTONS.UPLOAD_BACKGROUND_IMAGE")}
            title={t("COMMON.LABELS.BACKGROUND_IMAGE")}
            width="154.7px"
            fallbackWidth="390px"
            height="238px"
            fallbackHeight="600px"
            caption={t("GAMES.FORM.LABELS.BACKGROUND_IMAGE_CAPTION")}
          />
        </Grid2>
        <Grid2 md={6}>
          <MediaUploader
            expanded={showUploader}
            label={t("COMMON.LABELS.GAME_BANNER")}
            tooltip={t("GAMES.FORM.LABELS.BANNER_IMAGE_TOOLTIP")}
            disabled={disabled}
            src={game?.gameBannerUrl?.value}
            onSuccess={(res) => {
              dispatch(
                actions.setFormValues({ key: "gameBannerId", value: res?._id })
              );
              dispatch(
                actions.setFormValues({ key: "gameBannerUrl", value: res?.url })
              );
            }}
            error={
              game?.gameBannerUrl?.error ? t(game?.gameBannerUrl?.error) : ""
            }
            title={t("COMMON.LABELS.GAME_BANNER")}
            height="95px"
            caption={t("GAMES.FORM.LABELS.BANNER_IMAGE_CAPTION")}
            fallbackWidth="390px"
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
