import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  dropDownWithoutBorder: {
    background: "#F3F6F8!important",
    '& fieldset': {
      border: 'none', // Remove the border
    },
    height: "52px"
  }
}));
