import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { ContainerState, EditorSelectState } from "../types";
import { Pagination, Query } from "app/containers/types";
import { Editor } from "app/containers/Editors/types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = EditorSelectState;

const formSlice = createSlice({
  name: "editorsSelectState",
  initialState,
  reducers: {
    getEditors(state, action: PayloadAction<Query>) {
      state.isLoading = true;
    },
    setEditors(state, action: PayloadAction<{editors: Editor[], pagination: Pagination}>) {

      state.pagination = action.payload.pagination;
      if(action.payload.pagination.page == 1) {
        state.editors = action.payload.editors;
      }else{
        state.editors = [...state.editors,...action.payload.editors];
      }
      state.isLoading = false;
    },
    getEditorsError(state) {
        state.isLoading = false;
    }
  },
});

export const { actions, reducer: editorSelectReducer, name: editorSelectSlice } = formSlice;
