import { Box } from "@mui/material";
import React from "react";
import UpperDeck from "./components/UpperDeck";
import LowerDeck from "./components/LowerDeck";
import { MODES } from "utils/constants";

type Props = { mode: MODES };

const Form = ({ mode }: Props) => {
  return (
    <Box
      sx={{
        background: "#F3F6F8",
        padding: "24px",
        borderRadius: "8px",
      }}
    >
      <UpperDeck mode={mode} />
      <LowerDeck mode={mode} />
    </Box>
  );
};

export default Form;
