/**
 * Homepage selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.mediaUploadState || initialState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.isLoading,
);

export const selectUrl = createSelector(
  [selectDomain],
  state => state.url,
);