import { FormError } from "app/containers/types";
import { ProfileForm } from "./types";

export const profileValidator = (values: ProfileForm) => {
    const errors: Array<FormError> = [];
    const emailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^(05)\d{8}$/
    if (!values.fullName.value) {
        errors.push({ name: "fullName", error: "PROFILE.ERRORS.FULL_NAME_REQUIRED" });
    }
    if (!values.email.value) {
        errors.push({ name: "email", error: "PROFILE.ERRORS.EMAIL_REQUIRED" });
    }
    else if (!emailFormat.test(values.email.value)) {
        errors.push({ name: "email", error: "PROFILE.ERRORS.INVALID_EMAIL" });
    }
    if (!values.mobileNumber.value) {
        errors.push({ name: "mobileNumber", error: "PROFILE.ERRORS.MOBILE_NUMBER_REQUIRED" });
    }
    else if (!phoneRegex.test(values.mobileNumber.value)) {
        errors.push({ name: "mobileNumber", error: "PROFILE.ERRORS.INVALID_MOBILE_NUMBER" });
    }
    return errors;
}