import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { selectDirection } from 'styles/theme/slice';

export default function NotFound() {
    const navigate = useNavigate();
  const direction = useSelector(selectDirection);
  return (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#FEFEFE',
        }}
    >
       <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '28px'
        }}
       >
            <Typography sx={{
                  fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                  fontSize: '48px',
                fontWeight: 600,
                lineHeight: '57.6px',
                textAlign: 'center',  
                color: "#5E6781"             
            }}>Whoops! Something went wrong.</Typography>
            <Typography sx={{
                  fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                  fontSize: '308px',
                fontWeight: 600,
                lineHeight: '369.6px',
                textAlign: 'center',  
                color: '#5E6781'              
            }}>404</Typography>
            <Typography sx={{
                  fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                  fontSize: '24px',
                fontWeight: 400,
                lineHeight: '36px',
                textAlign: 'left',
                color: '#8890A6'               
            }}>Sorry we cannot find page you are looking for.</Typography>
            <Button
                onClick={() => navigate("/")}
                sx={{
                    width: '400px',
                    height: '52px',
                    padding: '13px 16px 13px 16px',
                    gap: '6px',
                    borderRadius: '8px',
                    opacity: '0px',
                    backgroundColor: '#26A8E0',
                    color: '#FFFFFF',
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '16px',
                    textAlign: 'center',
                    textTransform: 'none',
                }}
            >
                Back to previous page
            </Button>
       </Box>
    </Box>
  )
}
