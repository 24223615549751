import { FormError } from 'app/containers/types';
import { GameForm } from '../../types';

export const validateGame = (form: GameForm): FormError[] => {
  let errors: FormError[] = [];

  if(!form.name.value) {
    errors.push({
      name: "name",
      error: "GAMES.ERRORS.NAME_IS_REQUIRED",
    });
  }

  // if(!form.backgroundId.value || !form.backgroundUrl.value) {
  //   errors.push({
  //     name: "backgroundUrl",
  //     error: "GAMES.ERRORS.BACKGROUND_IS_REQUIRED",
  //   });
  // }

  // if(!form.gameBannerId.value || !form.gameBannerUrl.value) {
  //   errors.push({
  //     name: "gameBannerUrl",
  //     error: "GAMES.ERRORS.GAME_BANNER_IS_REQUIRED",
  //   });
  // }

  // if(!form.groupInstructionText.value) {
  //   errors.push({
  //     name: "groupInstructionText",
  //     error: "GAMES.ERRORS.INSTRUCTION_TEXT_IS_REQUIRED",
  //   });
  // }

  // if(!form.groupInstructionPDF.value) {
  //   errors.push({
  //     name: "groupInstructionPDF",
  //     error: "GAMES.ERRORS.INSTRUCTION_PDF_IS_REQUIRED",
  //   });
  // }

  // if(!form.startGameText.value) {
  //   errors.push({
  //     name: "startGameText",
  //     error: "GAMES.ERRORS.START_GAME_TEXT_IS_REQUIRED",
  //   });
  // }

  // if(!form.endGameText.value) {
  //   errors.push({
  //     name: "endGameText",
  //     error: "GAMES.ERRORS.END_GAME_TEXT_IS_REQUIRED",
  //   });
  // }

  // if(!form.endGameAssetId.value || !form.endGameAssetUrl.value) {
  //   errors.push({
  //     name: "endGameAssetUrl",
  //     error: "GAMES.ERRORS.END_GAME_ASSET_IS_REQUIRED",
  //   });
  // }

  // if(!form.startGameAssetId.value || !form.startGameAssetUrl.value) {
  //   errors.push({
  //     name: "startGameAssetUrl",
  //     error: "GAMES.ERRORS.START_GAME_ASSET_IS_REQUIRED",
  //   });
  // }

  return errors;
};
