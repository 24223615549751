import React from "react";
import { TableHeaderColumnType } from "app/components/Table/types";
import { ListItem } from "./types";
import edit from "assets/ic_edit.svg";
import trash from "assets/ic_trash.svg";
import duplicate from "assets/duplicate.svg";

export const TableHeaderDefinition = {
  QUESTION_ID: {
    id: "_id", // "QUESTION_ID
    label: "QUESTION_ID",
    format: (value) => value,
    maxWidth: 110,
    minWidth: 110,
    sortable: false,
  },
  QUESTION_TYPE: {
    id: "type", // "QUESTION_TYPE
    label: "QUESTION_TYPE",
    format: (value) => value,
    maxWidth: 200,
    minWidth: 130,
    sortable: true,
  },
  QUESTION_BODY: {
    id: "body", // "QUESTION_BODY
    label: "QUESTION_BODY",
    format: (value) => value,
    maxWidth: 310,
    minWidth: 310,
    sortable: true,
  },
  EDITOR_NAME: {
    id: "user.fullName", // "EDITOR_NAME
    label: "EDITOR_NAME",
    format: (value) => value,
    maxWidth: 180,
    minWidth: 180,
    sortable: true,
  },
  CREATED_DATE: {
    id: "createdAt", // "CREATED_DATE
    label: "CREATED_DATE",
    format: (value) => value,
    maxWidth: 160,
    minWidth: 160,
    sortable: true,
  },
  EDITED_DATE: {
    id: "updatedAt", // "EDITED_DATE
    label: "EDITED_DATE",
    format: (value) => value,
    maxWidth: 160,
    minWidth: 160,
    sortable: true,
  },
};

export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);

  export const MenuOptions: ListItem[] = [
    {
      id: "1",
      name: "DUPLICATE",
      onClick: () => console.log("Download File"),
      icon: <img src={duplicate} alt="file" />,
    },
    {
      id: "2",
      name: "EDIT",
      onClick: () => console.log("Download Folder"),
      icon: <img src={edit} alt="folder" />,
    },
    {
      id: "3",
      name: "DELETE",
      onClick: () => console.log("Download Folder"),
      icon: <img src={trash} alt="controller" />,
    },
  ];
