import { Box, Typography } from '@mui/material'
import { selectCustomer } from 'app/containers/Customers/redux/selector'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectDirection } from 'styles/theme/slice'

export default function Summary() {
  const { t } = useTranslation()
  const direction = useSelector(selectDirection);
  const customer = useSelector(selectCustomer)
  return (
    <Box
      sx={{
        border: "1px solid #F2F2F2 !important",
        borderRadius: "8px",
        marginBottom: "16px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("CUSTOMERS.FORM.LABELS.NUMBER_OF_PURCHASE_GAMES")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {customer?.numberOfPurchasedGames.value || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
          backgroundColor: "#F3F6F8",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("CUSTOMERS.FORM.LABELS.NUMBER_OF_PLAYED_GAMES")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {customer?.noOfPlayedGames.value || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("CUSTOMERS.FORM.LABELS.REGISTRATION_DATE")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {customer.createdAt.value && dayjs(customer.createdAt.value).format("DD/MM/YYYY")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
          backgroundColor: "#F3F6F8",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("CUSTOMERS.FORM.LABELS.SOURCE")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {customer?.type.value}
        </Typography>
      </Box>
    </Box>
  )
}
