import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import WelcomeForm from "./components/WelcomeForm";
import CourseForm from "./components/CourseForm";

export default function BottomSection({
  disabled
}:{
  disabled?: boolean
}) {
  return (
    <Grid2 spacing={3} container mt="32px">
      <Grid2 md={12}>
        <WelcomeForm 
          disabled={disabled}
        />
      </Grid2>
      <Grid2 md={12}>
        <CourseForm 
          disabled={disabled}
        />
      </Grid2>
    </Grid2>
  );
}
