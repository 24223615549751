/**
 * Homepage selectors
 */


import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { RootState } from 'types/RootState';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.editorsSelectState || initialState;

export const selectEditors = createSelector(
  [selectDomain],
  editorsState => editorsState.editors,
);

export const selectPagination = createSelector(
    [selectDomain],
    editorsState => editorsState.pagination,
);

export const selectIsLoading = createSelector(
    [selectDomain],
    editorsState => editorsState.isLoading,
);
