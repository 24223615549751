import { Box, IconButton } from '@mui/material'
import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { selectSelectedQuestions } from 'app/containers/Stations/redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/containers/Stations/redux/slice';

export default function DirectionKeys({
    backgroundColor,
    disabled,
    index
}:{
    backgroundColor?: string
    disabled?: boolean
    index: number
}) {
    const selectedQuestions = useSelector(selectSelectedQuestions);
    const isUpDisabled = index === 0;
    const isDownDisabled = index === selectedQuestions.length - 1;
    const dispatch = useDispatch();

    const handleMoveUp = () => {
        if (index > 0) {
            const newSelectedQuestions = [...selectedQuestions];
            const temp = newSelectedQuestions[index];
            newSelectedQuestions[index] = newSelectedQuestions[index - 1];
            newSelectedQuestions[index - 1] = temp;
            dispatch(actions.setSelectedQuestions(newSelectedQuestions));
        }
    };

    const handleMoveDown = () => {
        if (index < selectedQuestions.length - 1) {
            const newSelectedQuestions = [...selectedQuestions];
            const temp = newSelectedQuestions[index];
            newSelectedQuestions[index] = newSelectedQuestions[index + 1];
            newSelectedQuestions[index + 1] = temp;
            dispatch(actions.setSelectedQuestions(newSelectedQuestions));
        }
    };

    return (
        <Box
            sx={{
                width: "60px",
                display: "flex",
                alignItems: "center",
                gap: "6px"
            }}
        >
            <IconButton 
                disabled={disabled || isUpDisabled}
                sx={{
                    width: "20px",
                    height: "20px",
                    padding: "0px",
                    backgroundColor: backgroundColor ?? "#ffffff",
                    "&:disabled": {
                        backgroundColor: backgroundColor ?? "#ffffff",
                    }
                }}
                onClick={handleMoveUp}
            >
                <ArrowUpwardIcon 
                    sx={{
                        fontSize: "14px",
                        color: disabled ? "#D7D7E0" : "#5E6781"
                    }}
                />
            </IconButton>
            <IconButton 
                sx={{
                    width: "20px",
                    height: "20px",
                    padding: "0px",
                    backgroundColor: backgroundColor ?? "#ffffff",
                    "&:disabled": {
                        backgroundColor: backgroundColor ?? "#ffffff",
                    }
                }}
                disabled={disabled || isDownDisabled}
                onClick={handleMoveDown}
            >
                <ArrowDownwardIcon 
                    sx={{
                        fontSize: "14px",
                        color: disabled ? "#D7D7E0" : "#5E6781"
                    }}
                />
            </IconButton>
        </Box>
    )
}
