import React, { useState } from "react";
import { RequestSubmitted } from "./components/RequestSubmitted";
import { ForgotPasswordForm } from "./components/Form";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { forgotPasswordReducer, forgotPasswordSlice } from "./redux/slice";
import { forgotPasswordSaga } from "./redux/saga";

const ForgotPassword = () => {
  useInjectReducer({ key: forgotPasswordSlice, reducer: forgotPasswordReducer });
  useInjectSaga({ key: forgotPasswordSlice, saga: forgotPasswordSaga });
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <>
      {isSubmitted ? (
        <RequestSubmitted />
      ) : (
        <ForgotPasswordForm setIsSubmitted={setIsSubmitted} />
      )}
    </>
  );
};
export default ForgotPassword;
