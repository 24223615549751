import { Box, IconButton, InputAdornment } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import InputField from 'app/components/InputField'
import React from 'react'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsInput from '../GroupsInput';
import DateRangePicker from 'app/components/DateRangePicker';
import { MODES } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectTicket } from 'app/containers/Tickets/redux/selector';
import { actions } from 'app/containers/Tickets/redux/slice';

import CustomerSelect from 'app/components/CustomerSelect';
import GameSelect from 'app/components/GamesSelect';
import { formatTableDate } from 'styles/theme/utils';
import { ContentCopy } from '@mui/icons-material';

export default function BasicForm({ mode }: { mode: MODES }) {
    const { t } = useTranslation();
    const ticket = useSelector(selectTicket)
    const dispatch = useDispatch();

    const createdAt = formatTableDate(ticket?.createdAt.value || (new Date().toISOString() ) )
    
    const handleCopy = (id) => {
        const textField: any = document.getElementById(id); // Assuming the TextField has this ID
        textField.select(); // Select the text field content
        try {
          navigator.clipboard.writeText(textField.value); // Copy to clipboard
          console.log('Text copied successfully!'); // Optional success message (can be replaced with visual feedback)
        } catch (err) {
          console.error('Failed to copy text:', err); // Handle errors
        } finally {
          textField.selectionStart = 0; // Deselect the text field
          textField.selectionEnd = 0;
        }
    };

    return (
        <Box 
            sx={{ 
                padding: "20px", 
                backgroundColor: "#F3F6F8",
                borderRadius: "8px",
            }}
        >
            <Grid2 container spacing={2}>
                <Grid2 md={5} lg={6}>
                    <Box
                        sx={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            gap: "10px",
                        }}
                    >
                        <CustomerSelect 
                            value={ticket.customerId.value}
                            onChange={(v)=>dispatch(actions.setFormValues({ key: "customerId", value: v }))}
                            error={ticket?.customerId.error ? t(ticket?.customerId.error) : undefined}
                            disabled={mode !== MODES.NEW}
                            defaultValue={ticket.customerId.value}
                        />
                        <IconButton
                            sx={{
                                backgroundColor: "#E24399",
                                width: "45px",
                                height: "45px",
                            }}
                            onClick={()=>window.open("/app/customers/new", "_blank")}
                        >
                            <GroupAddIcon 
                                sx={{
                                    color: "#FFFFFF",
                                    fontSize: "20px",
                                }}
                            />
                        </IconButton>
                    </Box>
                </Grid2>
                <Grid2 md={7} lg={6}>
                    <Box
                        sx={{
                            flexDirection: "row",
                            display: "flex",
                            gap: "10px",
                            height: "100%",
                        }}
                    >
                        <InputField
                            label={t("COMMON.LABELS.CREATED_DATE")}
                            fullWidth={true}
                            name="createdDate"
                            onChange={()=>{}}
                            value={createdAt}
                            isDisable={true}
                            lightPlaceholder
                        />
                        <GroupsInput
                            disabled={mode === MODES.VIEW}
                        />
                    </Box>
                </Grid2>
                </Grid2>
                <Grid2 spacing={2} container sx={{mt: "10px", mb: "10px"}}>
                <Grid2 md={2} lg={3}>
                    <GameSelect
                        value={ticket.gameId.value}
                        onChange={(v)=>dispatch(actions.setFormValues({ key: "gameId", value: v }))}
                        error={ticket?.gameId.error ? t(ticket?.gameId.error) : undefined}
                        disabled={mode !== MODES.NEW}
                    />
                </Grid2>
                <Grid2 md={10} lg={9}>
                    <Box
                        sx={{
                            flexDirection: "row",
                            display: "flex",
                            gap: "10px",                          
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                marginTop: '25px'
                            }}
                        >
                            <DateRangePicker 
                                containerStyle={{
                                    marginBottom: "-5px",
                                }}
                                disabled={mode === MODES.VIEW}
                            />
                        </Box>
                        {
                            mode !== MODES.NEW &&
                            <Box
                                sx={{
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    gap: "10px",
                                }}
                            >
                                <InputField
                                    label={t("TICKETS.FORM.TICKET_PASSWORD")}
                                    name="password"
                                    fullWidth={true}
                                    isDisable={true}
                                    value={ticket.password.value}
                                    lightPlaceholder
                                    mainStyle={{ width: "100%"}}  
                                      InputProps={{
                                        id: 'copy-password',
                                        endAdornment: (
                                          <InputAdornment sx={{
                                            background: 'transparent',
                                            padding: 0, // Remove default padding (optional)
                                            margin: 0,  // Remove default margin (optional)
                                            border: 'none', // Remove default border (optional)
                                          }} position="end">
                                            <IconButton size='small' onClick={() => handleCopy('copy-password')}>
                                              <ContentCopy />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                />
                                <InputField
                                
                                    label={t("TICKETS.FORM.TEST_TICKET_PASSWORD")}
                                    name="testPassword"
                                    fullWidth={true}
                                    isDisable={true}
                                    value={ticket.testPassword.value}
                                    lightPlaceholder
                                    mainStyle={{ width: "100%"}}
                                    InputProps={{
                                        id: 'copy-test-password',
                                        endAdornment: (
                                          <InputAdornment sx={{
                                            background: 'transparent',
                                            padding: 0, // Remove default padding (optional)
                                            margin: 0,  // Remove default margin (optional)
                                            border: 'none', // Remove default border (optional)
                                          }} position="end">
                                            <IconButton size='small' onClick={() => handleCopy('copy-test-password') }>
                                              <ContentCopy />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                />
                            </Box>
                        }
                    </Box>
                </Grid2>
            </Grid2>
        </Box>
    )
}
