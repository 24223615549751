import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  mainContainer: {
    height: "100vh",
    background: "#26A8E0",
    overflow: "hidden",
    position: "relative",
  },
  root: {
    background: "#ffffff",
    height: "calc(100vh - 60px)",
    padding: "25px 8px 102px 8px ",
    borderTopLeftRadius: "32px",
    overflow: "auto",
    overflowX: "hidden",
    position: "relative",
    width: '100%', 
  },
}));
