import { Box } from '@mui/material'
import React from 'react'
import logo from 'assets/logo.png'
import ContactItem, { Type } from './components/ContactItem'

const ContactInfo:{
    id: string,
    type: Type,
    value: string,
}[] = [
    {
        id: "1",
        type: "phone",
        value: "+91 9876543210",
    },
    {
        id: "2",
        type: "address",
        value: "Morem ipsum dolor",
    },
    {
        id: "3",
        type: "email",
        value: "twigo@gmail.com"
    },
    {
        id: "4",
        type: "website",
        value: "www.mifrasim.org"
    }
]

export default function Footer() {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "16px",
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: "#26A8E0",
                gap: "12px"
            }}
        >
            <Box>
                <img alt="logo" src={logo} width="63px" height="22px" />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                }}
            >
                {
                    ContactInfo?.map((item, i) => {
                        if(i > 1) return;
                        return (
                            <ContactItem 
                                type={item?.type}
                                text={item?.value}
                            />
                        )
                    })
                }
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                }}
            >
                <ContactItem 
                    type={ContactInfo[2]?.type}
                    text={ContactInfo[2]?.value}
                />

                <ContactItem 
                    type={ContactInfo[3]?.type}
                    text={ContactInfo[3]?.value}
                />
            </Box>
        </Box>
    )
}
