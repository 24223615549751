import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { CustomDrawer } from '../Drawer'
import { useStyles } from "./styles";
import Navbar from '../Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { actions } from 'app/redux/slice';
import { selectDirection } from 'styles/theme/slice';
import { selectIsLoading, selectToken } from 'app/redux/selectors';
import ScrollToTop from "app/components/ScrollToTop";
export default function Layout() {
    const styles = useStyles();
    const direction = useSelector(selectDirection);
    const token = useSelector(selectToken);
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(actions.getUser());
    }, []);

    useEffect(() => {
        if (!isLoading && !token) {
            dispatch(actions.logout());
            navigate("/auth/login", { replace: true });
            return;
        }
    }, [token]);

    return (
        <div className={styles.mainContainer}>
            <ScrollToTop></ScrollToTop>
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <CustomDrawer />               
                <Box
                    sx={direction === "ltr" ? {
                        marginLeft: "235px"
                    }:{
                        marginRight: "235px"
                    }}
                >
                    <Navbar />
                    <div className={styles.root}>
                        <Outlet />
                    </div>
                </Box>
            </Box>
        </div>
    )
}
