import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { FormError } from "app/containers/types";
import { ContainerState } from "../types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  email: {
    value: "",
  },
  loading: false,
  error: null,
};

const formSlice = createSlice({
  name: "forgotPasswordState",
  initialState,
  reducers: {
    setFormValues: (state, action: PayloadAction<{ key: string; value: string }>) => {
      const { key, value } = action.payload;
      set(state, `${key}.value`, value);
      set(state, `${key}.error`, "");
    },
    setFormErrors(
      state,
      action: PayloadAction<FormError>
    ) {
      state.email.error = action.payload.error;
    },
    submit(state, action: PayloadAction<{ callback?: ()=>void }>) {
      state.loading = true;
      state.error = null;
    },
    submitSuccess(state) {
      state.email = {
        value: "",
      };
      state.loading = false;
      state.error = null;
    },
    submitError(state) {
      state.loading = false;
    }
  },
});

export const { actions, reducer: forgotPasswordReducer, name: forgotPasswordSlice } = formSlice;
