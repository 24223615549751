import React from 'react'
import { ArrowBack } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'
import { selectDirection } from 'styles/theme/slice'
import { useTranslation } from 'react-i18next'

const BreadCrumb = ({ 
  title, 
  onBackClick, 
  headerTextStyle,
  containerStyle
}:{
  title: string,
  onBackClick: () => void,
  headerTextStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
}) => {
  const styles = useStyles();
  const direction = useSelector(selectDirection)
  return (
    <Box 
      className={styles.mainHeader}
      sx={containerStyle}
    >
      <IconButton 
        sx={{
          background: "#F8F8FA",
          borderRadius: "22.6667px",
          width: "40px",
          height: "40px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          cursor: "pointer"
        }}
      >
        <ArrowBack  
          sx={{ 
            color: '#3C3C43',
            margin: "7px", 
            fontSize: '20px',
            transform: direction === 'rtl' ? 'rotate(180deg)' : 'none'
          }} 
          onClick={onBackClick} 
        />
      </IconButton>

      <Typography 
        className={styles.textHeader}
        sx={{
          ...headerTextStyle,
          fontFamily: direction === "ltr" ? "Inter" : "Rubik !important",
        }}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default BreadCrumb