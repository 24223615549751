import { FormElement } from "app/containers/types";

export type PlaceForm = {
  q: FormElement;
};

export type PlaceState = {
  form: PlaceForm;
  isLoading: boolean;
  predictions: Place[];
};

export const PlaceState: PlaceState = {
  form: {
    q: {
      value: "",
      error: "",
    },
  },
  isLoading: false,
  predictions: [],
};

interface MatchedSubstring {
    length: number;
    offset: number;
  }
  
  interface StructuredFormatting {
    main_text: string;
    main_text_matched_substrings: MatchedSubstring[];
    secondary_text: string;
  }
  
  interface Term {
    offset: number;
    value: string;
  }
  
  export interface Place {
    description: string;
    matched_substrings: MatchedSubstring[];
    place_id: string;
    reference: string;
    structured_formatting: StructuredFormatting;
    terms: Term[];
    types: string[];
  }

export type ContainerState = PlaceState;
