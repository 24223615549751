import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, Query } from "app/containers/types";
import { ContainerState, GamesSelectState } from "../types";
import { Game } from "app/containers/Games/types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = GamesSelectState;

const formSlice = createSlice({
  name: "gamesSelectState",
  initialState,
  reducers: {
    getGames(state, action: PayloadAction<Query>) {
      state.isLoading = true;
    },
    setGames(state, action: PayloadAction<{games: Game[], pagination: Pagination}>) {
      state.games = action.payload.games;
      state.pagination = action.payload.pagination;
      if(state.pagination.page > 1) {
        action.payload.games.map(g => {
          const i = state.games.findIndex( f=> f._id == g._id)
          if(i === -1) {
            state.games.push(g)
          }
        })
      }else{
        state.games =  action.payload.games
      }
      state.isLoading = false;
    },
    getGamesError(state) {
        state.isLoading = false;
    }
  },
});

export const { actions, reducer: gamesSelectReducer, name: gamesSelectSlice } = formSlice;
