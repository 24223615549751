import { FormError } from "app/containers/types";
import { ResetPasswordState } from "./types";
import { translate } from "utils/constants";

export function validate(form: ResetPasswordState): Array<FormError> {
  const errors: Array<FormError> = [];
  if (!form.password.value) {
    errors.push({
      name: "password",
      error: translate("AUTH.ERRORS.CREATE_PASSWORD.PASSWORD_IS_REQUIRED"),
    });
  }
  if(form.password.value?.length < 8) {
    errors.push({
      name: "password",
      error: translate("AUTH.ERRORS.CREATE_PASSWORD.PASSWORD_SHOULD_BE_AT_LEAST_8_CHARACTERS"),
    });
  }
  if (!form.confirmPassword.value) {
    errors.push({
      name: "confirmPassword",
      error: translate("AUTH.ERRORS.CREATE_PASSWORD.PASSWORD_IS_REQUIRED"),
    });
  }
  if(form.password.value !== form.confirmPassword.value) {
    errors.push({
      name: "confirmPassword",
      error: translate("AUTH.ERRORS.CREATE_PASSWORD.PASSWORDS_DO_NOT_MATCH"),
    });
  }
  return errors;
}


