import { Box } from "@mui/material";
import OutlineButton from "app/components/Buttons/OutlineButton";
import InputField from "app/components/InputField";
import React from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomizeTicket } from "app/containers/Tickets/redux/selector";
import { actions } from "app/containers/Tickets/redux/slice";

import Uploader from "../Uploader";
import { ASSET_TYPE } from "utils/constants";
import { MediaTypes } from "app/containers/types";
import { useTranslation } from "react-i18next";
export default function MidSection() {
  const { t } = useTranslation();
  const ticket = useSelector(selectCustomizeTicket);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        gap: "12px",
        width: "100%",
      }}
    >
      <InputField
        label={t("TICKETS.CUSTOMIZED_TICKETS.CUSTOMIZED_GAME_NAME")}
        sx={{
          width: "100%",
        }}
        borderRadius="8px"
        borderColor="#D7D7E0"
        labelStyle={{
          fontFamily: "Rubik",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0em",
          color: "#8890A6",
        }}
        onChange={(e) =>
          dispatch(
            actions.setCustomizeFormValues({
              key: "gameName",
              value: e.target.value,
            })
          )
        }
        value={ticket.gameName.value}
      />
      <Uploader
        onUpload={(img) => {
          dispatch(
            actions.setCustomizeAssetsValues({
              key: "asset",
              value: {
                _id: img?._id,
                type: ASSET_TYPE.IMAGE,
                mimeType: img?.mimeType,
                fileName: img?.fileName,
                filePath: img?.filePath,
                url: img?.url,
              },
            })
          );
        }}
        caption={t("TICKETS.FORM.VIDEO_IMAGE_CAPTION")}
        isDelete={ticket?.asset?.url?true:false}
        src={ticket?.asset?.url}
        onDelete={() => {
          dispatch(
            actions.setCustomizeAssetsValues({
              key: "asset",
              value: {
                _id: "",
                type: ASSET_TYPE.IMAGE,
                mimeType: "",
                fileName: "",
                filePath: "",
                url: "",
              },
            })
          );
        }}
        type={MediaTypes.IMAGE_VIDEO}
        width="320px"
        height="200px"
      />
    </Box>
  );
}
