import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  selectCurrentResult,
  selectCurrentResultId,
  selectList,
  selectStationsInfo,
} from "app/containers/Results/redux/selector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TextField } from "app/components/TextField";

const StationModal = ({ onClose }: { onClose(): void }) => {
  const currentResult = useSelector(selectCurrentResult);
  const { t } = useTranslation();
  const stationData: any = useSelector(selectStationsInfo);
  const open = !!currentResult?._id;
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="stations-list-title"
      aria-describedby="stations-list-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 6,
        }}
      >
          <IconButton sx={{position: 'absolute', right: '10px', top: '10px'}} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          
          }}
        >
          <Typography id="stations-list-title" sx={{color: 'rgb(32, 125, 185)'}} variant="h6" component="h2">
            {t("RESULTS.LABELS.STATIONS_LIST")}
          </Typography>
        
        </Box>
        <Box sx={{ mb: '20px' , display: "flex" , flexDirection: 'column'}}>
          <Typography sx={{}}>
            {t("RESULTS.LABELS.GROUP_NAME_WITH_LABEL")}
          </Typography>
          <TextField
            sx={{ margin: 0, backgroundColor: "rgb(243, 246, 248)" }}
            value={currentResult?.groupName}
            disabled
            fullWidth
          />
        </Box>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "calc(100vh - 300px)", overflowY: "scroll" }}
        >
          <Table sx={{ minWidth: 300 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t("TABLES.STATION_NAME")}</TableCell>
                <TableCell>{t("TABLES.STATUS")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stationData.map((station) => (
                <TableRow key={station.id}>
                  <TableCell>{station.id}</TableCell>
                  <TableCell>{station.name}</TableCell>
                  <TableCell>{t(`RESULTS.STATUS.${station.status}`)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default StationModal;
