import { FormElement, Metadata, OrderDirections, Pagination } from "app/containers/types";
export interface OrganizationListResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: Organization[];
}

export type OrganizationForm = {
  id: FormElement;
  contactName: FormElement;
  taxNumber: FormElement;
  email: FormElement;
  address: FormElement;
  mobileNumber: FormElement;
  note: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  name: FormElement;
};

export type OrganizationsState = {
  form: OrganizationForm;
  organizations: Array<Organization>;
  pagination: Pagination;
  loading: boolean;
  isUnsaved:boolean
};

export const OrganizationsState: OrganizationsState = {
  organizations: [],
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 20,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  loading: false,
  form: {
    id: { value: "" },
    contactName: { value: "" },
    taxNumber: { value: "" },
    email: { value: "" },
    address: { value: "" },
    mobileNumber: { value: "" },
    note: { value: "" },
    name: { value: "" },
    createdAt: { value: "" },
    updatedAt: { value: "" },
  },
  isUnsaved:false
};

export interface Organization {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  contactName: string;
  taxNumber: string;
  email: string;
  address: string;
  mobileNumber: string;
  note: string;
  isActive?:boolean;
}

export type ContainerState = OrganizationsState;
