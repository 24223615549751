import React from 'react';
import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Details from './components/Details';
import { MODES } from 'utils/constants';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { ticketsReducer, ticketsSlice } from './redux/slice';
import { ticketsSaga } from './redux/saga';

const Tickets = () => {
    useInjectReducer({ key: ticketsSlice, reducer: ticketsReducer });
    useInjectSaga({ key: ticketsSlice, saga: ticketsSaga });
    
    return (
        <Routes>
            <Route path="/" element={<List />} />
            <Route path=":id/view" element={<Details mode={MODES.VIEW} />} />
            <Route path="new" element={<Details mode={MODES.NEW}  />} />
            <Route path=":id/edit" element={<Details mode={MODES.EDIT}/>} /> 
        </Routes>
    );
};

export default Tickets;
