import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { ProfileState } from '../types';

const selectDomain = (state: RootState) => state.profileState || ProfileState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.isLoading,
);
export const selectProfile = createSelector([selectDomain], state => state.form);