import { GoogleMap, Marker, OverlayView, useLoadScript } from "@react-google-maps/api";
import React, { useRef, useEffect, useState } from "react";

function Map({ lat, lng, handleMapClick }) {
    const [position , setPosition] = useState({ lat, lng })
  const mapRef = useRef<any>(null);

  useEffect(() => {
    if (mapRef.current) {
      const center = new window.google.maps.LatLng(lat, lng);
      mapRef.current.panTo(center);
      setPosition({lat, lng})
    }
  }, [lat, lng]);

  return (
    <div style={{ height: "250px", width: "515px" }}>
      <GoogleMap
        onLoad={(map) => {
          mapRef.current = map;
          const center = new window.google.maps.LatLng(lat, lng);
          map.setCenter(center);
        }}
        mapContainerStyle={{ height: "250px", width: "515px" }}
        zoom={12}
        onClick={handleMapClick}
      >
        <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
         <img
          src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
          alt="Map Pin"
          style={{ width: '30px', height: '30px', transform: 'translate(-50%, -100%)' }}
        />
      </OverlayView>
      </GoogleMap>
    </div>
  );
}

export default React.memo(Map);
