import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import PopupDialog from 'app/components/PopupDialog';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

export default function Calendar({
    value,
    onChange,
    open,
    onClose,
}:{
    value: Date;
    onChange: (v: Date) => void;
    open: boolean;
    onClose: () => void;
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <PopupDialog
                open={open}
                handleClose={onClose}
            >
                <Box
                    sx={{
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        alignItems: "center",
                    }}
                >
                    <DateCalendar 
                        defaultValue={dayjs(value || new Date())}
                        onChange={(newValue) => {
                            onChange(newValue)
                            onClose()
                        }}
                    />
                </Box>
            </PopupDialog>
        </LocalizationProvider>
    )
}
