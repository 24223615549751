import React from "react";
import { TableHeaderColumnType } from "app/components/Table/types";
import qr from "assets/ic_qr.svg";
import edit from "assets/ic_edit.svg";
import fileIcon from "assets/ic_file.svg";
import ticket from "assets/tickets.svg";
import trash from "assets/ic_trash.svg";
import duplicate from "assets/duplicate.svg";
import { ListItem } from "app/containers/types";
import { getFormattedDateTime } from "utils/helpers";

export const TableHeaderDefinition = {
  GAME_ID: {
    id: "_id",
    label: "GAME_ID",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: false,
  },
  CREATED_DATE: {
    id: "createdAt",
    label: "CREATED_DATE",
    format: (value) => getFormattedDateTime(value),
    maxWidth: 160,
    minWidth: 160,
    sortable: true,
  },
  EDITED_DATE: {
    id: "updatedAt",
    label: "EDITED_DATE",
    format: (value) => getFormattedDateTime(value),
    maxWidth: 160,
    minWidth: 160,
    sortable: true,
  },
  GAME_NAME: {
    id: "name",
    label: "GAME_NAME",
    format: (value) => value,
    maxWidth: 400,
    minWidth: 400,
    sortable: true,
  },
  EDITOR_NAME: {
    id: "creator.fullName",
    label: "EDITOR_NAME",
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable: true,
  },
  NUMBER_OF_STATIONS: {
    id: "noOfStations",
    label: "NUMBER_OF_STATIONS",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
};
export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);

export const MenuOptions: ListItem[] = [
  {
    id: "1",
    name: "DOWNLOAD_QR",
    onClick: () => console.log("Edit"),
    icon: <img src={qr} alt="qr" />,
  },
  {
    id: "2",
    name: "DOWNLOAD_ANSWERS",
    onClick: () => console.log("Edit"),
    icon: <img src={fileIcon} alt="edit" />,
  },
  {
    id: "3",
    name: "DUPLICATE",
    onClick: () => console.log("Delete"),
    icon: <img src={duplicate} alt="duplicate" />,
  },
  {
    id: "4",
    name: "CREATE_TICKET",
    onClick: () => console.log("Download File"),
    icon: <img src={ticket} alt="ticket" />,
  },
  {
    id: "5",
    name: "EDIT",
    onClick: () => console.log("Download Folder"),
    icon: <img src={edit} alt="folder" />,
  },
  {
    id: "6",
    name: "DELETE",
    onClick: () => console.log("Download Folder"),
    icon: <img src={trash} alt="controller" />,
  },
];
