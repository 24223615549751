import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL, translate } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import { Organization, OrganizationListResponse } from "../types";
import { removeBlankProperties } from "utils/helpers";
import toastService from "utils/toast";
import { selectPagination } from "./selector";
import { Query } from "app/containers/types";
import { actions as authAction } from "app/redux/slice";


export function* getOrganizationRequest({ payload }: { payload: string }) {
  yield delay(500);
  try {
    const id = payload;
    const { data, err, status }: Res<{ data: Organization }> = yield call(
      request,
      `${API_URL}/v1/admin/organization/get/${id}`
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err || !data) {
      throw err;
    }
    yield put(actions.getOrganizationSuccess(data?.data));
  } catch (error: any) {
    yield put(actions.getOrganizationError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* getOrganizationsRequest({ payload }: { payload: Query }) {
  yield delay(500);
  try {
    const query = new URLSearchParams(
      removeBlankProperties(payload)
    ).toString();
    const { data, err, status }: Res<OrganizationListResponse> = yield call(
      request,
      `${API_URL}/v1/admin/organization/list?${query}`
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err || !data) {
      throw err;
    }
    yield put(
      actions.getOrganizationsSuccess({
        organizations: data?.data,
        pagination: data?._metadata.pagination,
      })
    );
  } catch (error: any) {
    yield put(actions.getOrganizationError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* createOrganizationRequest({
  payload,
}: {
  payload: { organization: Organization; callback?: () => void };
}) {
  try {
    yield delay(500);
    const { status, err } = yield call(
      request,
      `${API_URL}/v1/admin/organization/create`,
      {
        method: "POST",
        body: JSON.stringify(payload.organization),
      }
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err) {
      throw err;
    }
    yield put(actions.createOrganizationSuccess());
    toastService.success(translate("ORGANIZATIONS.SUCCESSFULLY_CREATED"));
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.createOrganizationError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* updateOrganizationRequest({
  payload,
}: {
  payload: { organization: Organization; callback?: () => void };
}) {
  yield delay(500);
  try {
    const { data, err, status }: Res<{ data: Organization }> = yield call(
      request,
      `${API_URL}/v1/admin/organization/update/${payload.organization?._id}`,
      {
        method: "PUT",
        body: JSON.stringify(payload.organization),
      }
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err || !data) {
      throw err;
    }
    yield put(actions.updateOrganizationSuccess(data?.data));
    toastService.success(translate("ORGANIZATIONS.SUCCESSFULLY_UPDATED"));
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.updateOrganizationError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* deleteOrganizationRequest({ payload }: { payload: string }) {
  yield delay(500);
  try {
    const { err, status } = yield call(
      request,
      `${API_URL}/v1/admin/organization/delete/${payload}`,
      {
        method: "DELETE",
      }
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err) {
      throw err;
    }
    yield put(actions.deleteOrganizationSuccess());
    const pagination = yield select(selectPagination);
    yield put(authAction.getOrganizations({}));
    toastService.success(translate("ORGANIZATIONS.SUCCESSFULLY_DELETED"));
    yield put(
      actions.getOrganizations({
        perPage: pagination.perPage,
        page: pagination.page,
        search: pagination.search,
        isActive: pagination.filters?.isActive,
        orderBy: pagination.orderBy,
        orderDirection: pagination.orderDirection,
      })
    );
  } catch (error: any) {
    yield put(actions.deleteOrganizationError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* updateStatusOrganizationRequest({
  payload,
}: {
  payload: { id: string; status: boolean };
}) {
  yield delay(500);
  try {
    const { err, status } = yield call(
      request,
      `${API_URL}/v1/admin/organization/update/${payload.id}/status?status=${payload.status?"ACTIVE":"INACTIVE"}`,
      {
        method: "PATCH",
      }
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err) {
      throw err;
    }
   
    const pagination = yield select(selectPagination);
    toastService.success(translate("ORGANIZATIONS.SUCCESSFULLY_UPDATED"));
    yield put(
      actions.updateStatusOrganizationSuccess(payload)
    );
  } catch (error: any) {
    yield put(actions.deleteOrganizationError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}
export function* resetFrom({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  yield delay(500);
  try {
    payload?.callback?.();
  } catch (error: any) {}
}
export function* organizationSaga() {
  yield takeLatest(actions.getOrganization, getOrganizationRequest);
  yield takeLatest(actions.getOrganizations, getOrganizationsRequest);
  yield takeLatest(actions.createOrganization, createOrganizationRequest);
  yield takeLatest(actions.updateOrganization, updateOrganizationRequest);
  yield takeLatest(actions.deleteOrganization, deleteOrganizationRequest);
  yield takeLatest(
    actions.updateStatusOrganization,
    updateStatusOrganizationRequest
  );
  yield takeLatest(actions.resetForm, resetFrom);
}
