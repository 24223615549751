/**
 * Gets the repositories of the user from Github
 */

import { call, delay, put, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import { showServerError } from "styles/theme/utils";

export function* resetPasswordApi({ payload }: { payload: { callback: () => void; creds: { password: string; hash: string; email: string } } }) {
  yield delay(500);
  try {
    const { creds, callback } = payload;
    const requestURL = `${API_URL}/v1/auth/passwords/change-password`;
    const data = {
      newPassword: creds.password,
      token: creds.hash,
      email: creds.email,
    };
    const {err}: Res<{}> = yield call(request, requestURL, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
    if(err){
      throw err;
    }
    yield put(actions.submitSuccess());
    yield call(callback);
  } catch (err: any) {
    yield put(actions.submitError());
    showServerError(err)
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export function* resetPasswordSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.submit, resetPasswordApi);
}
