/**
 * Homepage selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.authState || initialState;

export const selectToken = createSelector(
  [selectDomain],
  authState => authState.accessToken,
);

export const selectRole = createSelector(
  [selectDomain],
  authState => authState.user?.type,
);

export const selectUser = createSelector(
  [selectDomain],
  authState => authState.user,
);

export const selectIsLoading = createSelector(
  [selectDomain],
  authState => authState.isLoading,
);

export const selectMessage = createSelector(
  [selectDomain],
  authState => authState.message,
);

export const selectOrganizations = createSelector(
  [selectDomain],
  authState => authState.organizations,
);

export const selectOrganizationPagination = createSelector(
  [selectDomain],
  authState => authState.pagination,
);

export const selectOrganization = createSelector(
  [selectDomain],
  authState => authState.organization,
);

export const selectIsLoadingOrganizations = createSelector(
  [selectDomain],
  authState => authState.isLoadingOrganizations,
);

export const selectIsEnableOrganizations = createSelector(
  [selectDomain],
  authState => authState.isEnableOrganization,
);
