import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { selectDirection } from 'styles/theme/slice';
import { useSelector } from 'react-redux';

const MenuProps = {
  PaperProps: {
    style: {
      width: 60,
    },
  },
};

interface GroupSelectProps {
  value: number;
  setValue: (number)=> void;
}

export default function GroupSelect({
  value,
  setValue,
}: GroupSelectProps) {
  const direction = useSelector(selectDirection);
  return (
    <div>
      <FormControl sx={{ width: 60 }}>
        <Select
          value={value}
          disableUnderline
          variant="standard"
          onChange={(event: SelectChangeEvent<number>) => {
            setValue(parseInt(event.target.value as string));
          }}
          MenuProps={MenuProps}
          sx={{
            "& .MuiSelect-select": {
              padding: 0,
              px: 1,
              borderWidth: 0,
              fontWeight: '400',
              fontSize: '14px',
              color: '#263238',
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            },                
            '& .MuiOutlinedInput-root': {
              borderWidth: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': { border: 0 },
            input: {
              fontWeight: '400',
              fontSize: '14px',
              color: '#263238',
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            },
          }}
        >
          {[1,2,3,4,5,6,7,8,9,10].map((name) => (
            <MenuItem
              key={name}
              value={name}
              sx={{
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "16px",
                textAlign: "left",
                color: "#5E6781",
                py: "4px",
              }}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}