import { Box, Typography } from "@mui/material";
import { selectUser } from "app/redux/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDirection } from "styles/theme/slice";
import { getFormattedDateTime } from "utils/helpers";

export default function Summary() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const direction = useSelector(selectDirection);
  return (
    <Box
      sx={{
        border: "1px solid #F2F2F2 !important",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("PROFILE.SUMMARY.NUMBER_OF_GAMES")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {user?.noOfGames || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
          backgroundColor: "#F3F6F8",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("PROFILE.SUMMARY.NUMBER_OF_QUESTIONS")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {user?.noOfQuestions || 0}
        </Typography>
      </Box>
      {user?.organization?.name && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "10px 16px",
          }}
        >
          <Typography
            sx={{
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#207DB9",
              fontWeight: 600,
            }}
          >
            {t("PROFILE.SUMMARY.ORGANIZATION_NAME")}
          </Typography>
          <Typography
            sx={{
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#5E6781",
              fontWeight: 400,
            }}
          >
            {user?.organization?.name}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
          backgroundColor: "#F3F6F8",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("PROFILE.SUMMARY.EMAIL")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {user?.email}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("PROFILE.SUMMARY.LAST_LOGIN_DATE")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {getFormattedDateTime(user?.lastLoginDate || "")}
        </Typography>
      </Box>
    </Box>
  );
}
