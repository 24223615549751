import { Metadata, OrderDirections, Pagination } from "app/containers/types";

export interface City {
    name: string;
    _id: string;
}

export type CityState = {
  cities: City[];
  pagination: Pagination;
  isLoading: boolean;
};

export const CityState: CityState = {
    cities: [],
    isLoading: false,
    pagination: {
      filters: {
        isActive: "true",
      },
      search: "",
      page: 1,
      perPage: 20,
      orderBy: "createdAt",
      orderDirection: OrderDirections.DESC,
      total: 0,
      totalPage: 0,
    }
};

export interface CityResponse {
    statusCode: number;
    message: string;
    _metadata: Metadata;
    data: City[];
}

export type ContainerState = CityState;