import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { Option } from 'app/types';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { selectGames, selectIsLoading, selectPagination } from '../redux/selector';
import { selectOrganization } from 'app/redux/selectors';
import { selectDirection } from 'styles/theme/slice';
import debounce from 'lodash/debounce';

interface SelectInputProps {
  readonly value?: Option;
  readonly onChange?: (newValue?: Option | null) => void;
  readonly isLoading?: boolean;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly backgroundColor?: string;
  readonly borderWidth?: number;
}

export default function SelectInput({
  value,
  onChange,
  isLoading,
  error,
  disabled,
  backgroundColor,
  borderWidth,
}: SelectInputProps) {
  const [open, setOpen] = useState(false);  
  const [search, setSearch] = useState('');
  const loadingMore = useSelector(selectIsLoading);
  const pagination = useSelector(selectPagination);
  const organization = useSelector(selectOrganization);
  const games = useSelector(selectGames);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);

  const fetchGames = useCallback(
    debounce((searchQuery: string) => {
      dispatch(
        actions.getGames({
          page: 1,
          perPage: pagination.perPage,
          search: searchQuery,
          isActive: pagination.filters?.isActive,
          organization: organization?.value,
        })
      );
    }, 300),
    [dispatch, pagination.perPage, pagination.filters?.isActive, organization?.value]
  );

  useEffect(() => {
    if (search.length > 0) {
      fetchGames(search);
    } else {
      dispatch(
        actions.getGames({
          page: 1,
          perPage: pagination.perPage,
          search: '',
          isActive: pagination.filters?.isActive,
          organization: organization?.value,
        })
      );
    }
  }, [search, dispatch, organization?.value, fetchGames]);

  const handleScroll = (event: React.SyntheticEvent) => {
    if(loadingMore) {return}
    const listboxNode = event.currentTarget;
    const bottom = Math.ceil(listboxNode.scrollTop + listboxNode.clientHeight) >= listboxNode.scrollHeight 
 
  if (bottom) {
      const hasMore = pagination.page < pagination.totalPage;
      if (!hasMore) return;
      dispatch(
        actions.getGames({
          page: pagination.page + 1,
          perPage: pagination.perPage,
          search: search,
          isActive: pagination.filters?.isActive,
          organization: organization?.value,
        })
      );
    }
  };

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      getOptionLabel={(option) => option?.label ?? ''}
      getOptionKey={(option) => option?.value}
      onChange={(e, v) => onChange?.(v)}
      options={games?.map((c) => ({
        label: c.name,
        value: c._id as string,
      })) || []}
      disabled={disabled}
      loadingText={
        <Typography
          sx={{
            fontFamily: direction === 'ltr' ? 'Inter' : 'Rubik',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '14px',
            textAlign: 'left',
          }}
        >
          {t('COMMON.TEXT.LOADING')}
        </Typography>
      }
      noOptionsText={
        <Typography
          sx={{
            fontFamily: direction === 'ltr' ? 'Inter' : 'Rubik',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '14px',
            textAlign: 'left',
          }}
        >
          {t('COMMON.TEXT.NO_OPTIONS')}
        </Typography>
      }
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography
            sx={{
              fontFamily: direction === 'ltr' ? 'Inter' : 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '16px',
              textAlign: 'left',
              color: '#5E6781',
              py: '4px',
            }}
          >
            {option?.label}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('COMMON.PLACEHOLDERS.SELECT')}
          disabled={disabled}
          sx={{
            width: '100%',
            backgroundColor: backgroundColor ?? '#FFFFFF',
            borderRadius: '4px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
              '&:hover fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
              '&.Mui-focused fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
            },
            '& .MuiFormHelperText-root': {
              fontFamily: direction === 'ltr' ? 'Inter' : 'Rubik',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0em',
              margin: 0,
              color: '#d32f2f',
            },
            input: {
              '&::placeholder': {
                color: '#727F89',
                opacity: '100%',
                fontWeight: '400',
                fontSize: '14px',
              },
              fontWeight: '400',
              fontSize: '14px',
              color: '#263238',
              backgroundColor: backgroundColor ?? '#FFFFFF',
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(isLoading || loadingMore) ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          helperText={error}
          onChange={(e) => setSearch(e.target.value)}
        />
      )}
      ListboxProps={{ onScroll: handleScroll }}
    />
  );
}
