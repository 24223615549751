import { Mail } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react'
import { useStyles } from './styles';

export const RequestSubmitted = () => {
  const styles = useStyles();
  return (
    <Box className={styles.form}>
      <Box className={styles.successHeading}>Reset Password</Box>
      <Mail className={styles.iconMail} />
      <Box className={styles.mailSent}>
        A Mail has been send to you to Reset your password
      </Box>
      <div className={styles.descText}>
        Click the reset link sent to your registed email and Reset your Password
      </div>
    </Box>
  );
};
