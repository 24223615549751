import { EditorForm } from 'app/containers/Editors/types';
import { FormError } from 'app/containers/types';
import { validateAndFormatIsraeliMobileNumber } from 'styles/theme/utils';

export const validateEditor = (form: EditorForm): FormError[] => {
  let errors: FormError[] = [];
  
  const regexOnlyAlphabet = /[\u0041-\u005A\u0061-\u007A\u0591-\u05F4]/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(05)\d{8}$/

  if (!form.email.value) {
    errors.push({ name: 'email', error: 'EDITORS.ERRORS.EMAIL_IS_REQUIRED' });
  }
  if (form.email.value && !emailRegex.test(form.email.value)) {
    errors.push({ name: 'email', error: 'EDITORS.ERRORS.INVALID_EMAIL' });
  }
  if (!form.fullName.value) {
    errors.push({ name: 'fullName', error: 'EDITORS.ERRORS.FULL_NAME_IS_REQUIRED' });
  }
  if (form.fullName.value && !regexOnlyAlphabet.test(form.fullName.value)) {
    errors.push({ name: 'fullName', error: 'EDITORS.ERRORS.INVALID_FULL_NAME' });
  }
  if (form.fullName.value && form.fullName.value.length>40) {
    errors.push({ name: 'fullName', error: 'EDITORS.ERRORS.INVALID_FULL_NAME' });
  }
  if (!form.mobileNumber.value ) {
    errors.push({ name: 'mobileNumber', error: 'EDITORS.ERRORS.MOBILE_NUMBER_IS_REQUIRED' });
  }
  if (form.mobileNumber.value && !validateAndFormatIsraeliMobileNumber(form.mobileNumber.value).isValid) {
    errors.push({ name: 'mobileNumber', error: 'EDITORS.ERRORS.INVALID_MOBILE_NUMBER' });
  }
  // if (!form.departmentName.value) {
  //   errors.push({ name: 'departmentName', error: 'EDITORS.ERRORS.DEPARTMENT_IS_REQUIRED' });
  // }

  return errors;
};
