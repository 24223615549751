import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "./components/List";
import Details from "./components/Details";
import { MODES } from "utils/constants";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { stationsReducer, stationsSlice } from "./redux/slice";
import { stationsSaga } from "./redux/saga";
interface Props {}

const Stations = (props: Props) => {
  useInjectReducer({ key: stationsSlice, reducer: stationsReducer });
  useInjectSaga({ key: stationsSlice, saga: stationsSaga });

  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path=":id/view" element={<Details mode={MODES.VIEW} />} />
      <Route path="new" element={<Details mode={MODES.NEW} />} />
      <Route path=":id/edit" element={<Details mode={MODES.EDIT} />} />
      <Route path=":id/duplicate" element={<Details mode={MODES.DUPLICATE} />} />
    </Routes>
  );
};

export default Stations;
