import React, { useEffect } from "react";
import { Box } from "@mui/material";
import CustomTable from "app/components/Table";
import { TableHeaderColumnType } from "app/components/Table/types";
import EditableColumn from "../FreeText/components/EditableColumn";
import { styled } from "@mui/material/styles";
import { MODES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { CorelationAnswer } from "app/containers/Questions/types";
import { actions } from "app/containers/Questions/redux/slice";
import { selectAnswer } from "app/containers/Questions/redux/selector";
import CustomTableRow from "app/components/TableRow";
import { useTranslation } from "react-i18next";
import { selectDirection } from "styles/theme/slice";
interface StyledCellProps {
  minWidth?: string | number;
  maxWidth?: string | number;
  fontFamily?: string;
}

const StyledTableCell = styled("div")(
  ({ minWidth, maxWidth, fontFamily }: StyledCellProps) => ({
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "14px 16px",
    "&:last-of-type": {
      borderRight: "none",
    },
    backgroundColor: "transparent",
    textAlign: "unset",
    whiteSpace: "nowrap",
    height: 50,
    minWidth: minWidth || "auto",
    maxWidth: maxWidth || "none",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3C3C43",
  })
);

const Headers: TableHeaderColumnType[] = [
  {
    id: "#",
    label: "HASH",
    align: "left",
    minWidth: 80,
    sortable: true,
  },
  {
    id: "rightAnswer",
    label: "LEFT",
    align: "left",
    minWidth: 500,
    sortable: true,
  },
  {
    id: "difficulty",
    label: "RIGHT",
    align: "left",
    minWidth: 500,
    sortable: true,
  },
];

const Data: CorelationAnswer[] = [
  {
    _id: "",
    left: "",
    right: "",
  },
  {
    _id: "",
    left: "",
    right: "",
  },
  {
    _id: "",
    left: "",
    right: "",
  },
  {
    _id: "",
    left: "",
    right: "",
  },
  {
    _id: "",
    left: "",
    right: "",
  },
  {
    _id: "",
    left: "",
    right: "",
  },
  {
    _id: "",
    left: "",
    right: "",
  },
];

const Correlation = ({
  disabled,
  mode,
}: {
  disabled?: boolean;
  mode: MODES;
}) => {
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);
  const answers = useSelector(selectAnswer);
  useEffect(() => {
    if (mode == MODES.NEW) {
      dispatch(actions.setCoRelationAnswer(Data));
    } else {
      if (!answers.some((x) => x.corelation)) {
        dispatch(actions.setCoRelationAnswer(Data));
      }
    }
    return () => {};
  }, [mode]);



  return (
    <CustomTable headers={Headers} containerStyle={{ marginBottom: "50px" }}>
      {answers.map((item, index) => (
        <CustomTableRow key={index}>
          <StyledTableCell
            minWidth={80}
            fontFamily={direction === "ltr" ? "Inter" : "Rubik"}
            maxWidth={80}
          >
            <Box
              sx={{
                paddingY: "8px",
              }}
            >
              {index + 1}
            </Box>
          </StyledTableCell>
          <StyledTableCell
            minWidth={500}
            maxWidth={500}
            fontFamily={direction === "ltr" ? "Inter" : "Rubik"}
          >
            <EditableColumn
              value={item.corelation?.left || ""}
              isOdd={index % 2 === 0}
              innerContainerStyle={{
                maxWidth: "100%",
              }}
              disabled={disabled}
              inputStyle={{
                background: "transparent",
              }}
              onSave={(val) => {
                dispatch(
                  actions.setUpdateAnswer({
                    index: index,
                    type: "corelation",
                    key: "left",
                    value: val,
                  })
                );
              }}
            />
          </StyledTableCell>
          <StyledTableCell
            fontFamily={direction === "ltr" ? "Inter" : "Rubik"}
            minWidth={500}
            maxWidth={500}
          >
            <EditableColumn
              value={item.corelation?.right || ""}
              isOdd={index % 2 === 0}
              innerContainerStyle={{
                maxWidth: "100%",
              }}
              inputStyle={{
                background: "transparent",
              }}
              onSave={(val) => {
                dispatch(
                  actions.setUpdateAnswer({
                    index: index,
                    type: "corelation",
                    key: "right",
                    value: val,
                  })
                );
              }}
            />
          </StyledTableCell>
        </CustomTableRow>
      ))}
    </CustomTable>
  );
};

export default Correlation;
