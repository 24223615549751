import { Box } from "@mui/material";
import React from "react";
import BasicForm from "../BasicForm";
import CustomizationForm from "../CustomizationForm";
import Results from "../Results";
import { MODES } from "utils/constants";

type Props = { mode: MODES };

const Form = ({ mode }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <BasicForm mode={mode} />
      <CustomizationForm mode={mode} />
      <Results mode={mode} />
    </Box>
  );
};

export default Form;
