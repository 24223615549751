import { Box } from '@mui/material'
import React from 'react'
import PrimaryButton from '../Buttons/PrimaryButton'
import OutlineButton from '../Buttons/OutlineButton'
import { MODES } from 'utils/constants'
import { useTranslation } from 'react-i18next'

export default function ActionButtons({
    handleCancelBtn,
    handleConfirmBtn,
    mode=MODES.VIEW,
    isLoading,disable
}:{
    handleCancelBtn?: () => void;
    handleConfirmBtn?: () => void;
    mode?: MODES;
    isLoading?: boolean;
    disable?:boolean;
}) {
    const { t } = useTranslation();
    
    const generateConfirmBtnLabel = (mode: MODES) => {
        switch (mode) {
            case MODES.NEW:
                return "COMMON.BUTTONS.ADD"
            case MODES.EDIT:
                return "COMMON.BUTTONS.SAVE"
            case MODES.VIEW:
                return "COMMON.BUTTONS.EDIT"
            case MODES.DUPLICATE:
                return "COMMON.BUTTONS.SAVE"
            default:
            return "COMMON.BUTTONS.SAVE"
        }
    }

    return (
        <Box 
            sx={{ 
                display: "flex", 
                flexDirection: "row-reverse", 
                gap: "10px",
            }}
        >
            <PrimaryButton 
                label={t(generateConfirmBtnLabel(mode))}
                onClick={handleConfirmBtn} 
                isLoading={isLoading}
                disabled={disable}
            />
            <OutlineButton 
                label={t("COMMON.BUTTONS.CANCEL")} 
                onClick={handleCancelBtn} 
                sx={{
                    backgroundColor: "#ffffff",
                    "&:hover": {
                        backgroundColor: "#efefef"
                    }
                }}
                disabled={isLoading||disable}
            />
        </Box>
    )
}
