import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import React from "react";
import Counter from "../Counter";
import { useTranslation } from "react-i18next";
import { selectTicket } from "app/containers/Tickets/redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { actions } from 'app/containers/Tickets/redux/slice';
import { selectDirection } from "styles/theme/slice";

export default function GroupsInput({
    containerStyle,
    disabled=false,
}:{
    readonly containerStyle?: React.CSSProperties;
    readonly disabled?: boolean;
}) {
    const { t } = useTranslation();
    const direction = useSelector(selectDirection);
    
    const { noOfDevices } = useSelector(selectTicket)
    const checked = noOfDevices.value > 0
    const dispatch = useDispatch();

    const setCount = (count) => {
        if(disabled) return;
        dispatch(actions.setFormValues({ key: "noOfDevices", value: count }))
    }
    return (
        <Tooltip title={!checked ? t('TICKETS.FORM.NUMBER_OF_DEVICES_ON_ZERO') : ''}>


        <Box
            sx={{
                marginBottom: "10px"
            }}
        >
            <Typography
                sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    visibility: "hidden",
                }}
            >
                {t("COMMON.LABELS.CUSTOMER_NAME")}
            </Typography>
            <Box
                sx={{
                    borderRadius: "4px",
                    px: "16px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "52.81px",
                    backgroundColor: "#FFFFFF",
                    border: noOfDevices?.error ? "1px solid #FF0000" : "1px solid #86BDE3",
                    gap: "10px",
                    ...containerStyle
                }}
            >
                <FormControlLabel
                    sx={{
                        margin: 0,
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontWeight: 400,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        gap: "10px"
                    }}
                    label={
                        <Typography
                            sx={{
                                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                                opacity: (disabled || !checked) ? 0.5 : 1,
                            }}
                        >
                            {t("TICKETS.FORM.NUMBER_OF_DEVICES")}
                        </Typography>
                    }
                    control={
                        <Checkbox
                            size="small"
                            disabled={disabled}
                            sx={{
                                color: "#207DB9",
                                "&.Mui-checked": {
                                    color: "#207DB9",
                                },
                                p: 0,
                            }}
                            onChange={(e) => {
                                if(e.target.checked) {
                                    setCount(1)
                                }else{
                                    setCount(0)
                                }
                            }}
                            checked={noOfDevices?.value > 0}
                        />
                    }
                />
                <Counter 
                    disabled={disabled}
                    maxCount={50}
                    value={!checked ? '' : parseInt(noOfDevices?.value)}
                    onChange={(n)=>{
                        dispatch(
                            actions.setFormValues({
                                key: "noOfDevices",
                                value: n,
                            })
                        );
                    }}
                />
            </Box>
            {
                noOfDevices?.error && (
                    <Typography
                        sx={{
                            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            color: "#FF0000",
                            mt: "5px",
                        }}
                    >
                        {t(noOfDevices?.error)}
                    </Typography>
                )
            }
        </Box>
        </Tooltip>
    );
}
