import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const Options = [
  {
    name: "EDIT",
    icon: (
      <EditIcon
        sx={{
          color: "#207DB9",
          fontSize: "20px",
        }}
      />
    ),
  },
  {
    name: "DELETE",
    icon: (
      <DeleteIcon
        sx={{
          color: "#207DB9",
          fontSize: "20px",
        }}
      />
    ),
  },
];

export default function MenuOptions({
  onDelete,
  onEdit,
}: {
  onDelete: () => void;
  onEdit: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        sx={{
          width: "24px",
          height: "24px",
          backgroundColor: "#26A8E0",
        }}
        onClick={handleClick}
      >
        <MoreVertOutlinedIcon
          sx={{
            color: "#FFFFFF",
            fontSize: "20px",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "120px",
          },
        }}
        MenuListProps={{
          dense: true,
        }}
      >
        {Options?.map((option, index) => (
          <MenuItem
            onClick={() => {

              handleClose();
              if (option?.name.toLocaleLowerCase() === "delete") {
                onDelete();
                return;
              }
              onEdit();
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>
              <Typography
                sx={{
                  fontFamily: "Rubik",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "14px",
                  letterSpacing: "0em",
                  color: "#8890A6",
                }}
              >
                {t(`COMMON.BUTTONS.${option.name}`)}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
