import React from "react";
import { Grid } from "@mui/material";
import SearchInput from "app/components/SearchInput";
import { LEFT_SPACING } from "utils/constants";

const Header = () => {
  return (
    <Grid item container md={12} paddingLeft={LEFT_SPACING}>
      <Grid item xs={4}>
        <SearchInput />
      </Grid>
    </Grid>
  );
};

export default Header;
