import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppSettings from "../AppSettings";
import OrganizationSelect from "../OrganizationSelect";

function Navbar() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Toolbar
        sx={{
          gap: "24px",
        }}
      >
        <OrganizationSelect />
        <AppSettings />
      </Toolbar>
    </Box>
  );
}
export default React.memo(Navbar)