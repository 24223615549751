import React from "react";

const TermsOfUse = () => {
  return (
    <div className="page">
      <div id="terms-and-conditions" style={{margin: '10rem'}}>
        <h1>Terms and Conditions</h1>

        <h2>1. App Usage</h2>
        
        <p>
          1.1 The App is intended for personal, non-commercial use only. You may
          not use the App for any illegal or unauthorized purpose. You agree to
          comply with all applicable laws and regulations while using the App.
        </p>
        <p>
          1.2 The Company reserves the right to modify, suspend, or terminate
          the App, or any part thereof, at any time without prior notice.
        </p>

        <h2>2. User Accounts</h2>
        <p>
          2.1 In order to use certain features of the App, you may be required
          to create a user account. You must provide accurate and complete
          information when creating your account. You are responsible for
          maintaining the confidentiality of your account login credentials and
          for all activities that occur under your account.
        </p>
        <p>
          2.2 You must be at least 18 years old to create an account and use
          the App. If you are under the applicable age, you may only use the App
          under the supervision of a parent or legal guardian.
        </p>

        <h2>3. Intellectual Property</h2>
        <p>
          3.1 The App and all of its contents, including but not limited to
          text, graphics, images, logos, and software, are the intellectual
          property of the Company and are protected by applicable copyright and
          other intellectual property laws.
        </p>
        <p>
          3.2 You may not modify, reproduce, distribute, create derivative works
          of, publicly display, or in any way exploit any of the content or
          materials from the App without the prior written consent of the
          Company.
        </p>

        <h2>4. Privacy</h2>
        <p>
          4.1 Your privacy is important to us. The App collects and processes
          personal information in accordance with our{" "}
          <a href="[link to Privacy Policy]">Privacy Policy</a>. By using the
          App, you consent to the collection and use of your personal
          information as described in the Privacy Policy.
        </p>

        <h2>5. Third-Party Links and Services</h2>
        <p>
          5.1 The App may contain links to third-party websites, services, or
          resources that are not owned or controlled by the Company. We do not
          endorse or assume any responsibility for any third-party content,
          products, or services. You acknowledge and agree that we shall not be
          liable for any damages or losses incurred by you arising from the use
          of such third-party websites, services, or resources.
        </p>

        <h2>6. Disclaimer of Warranties</h2>
        <p>
          6.1 The App is provided on an "as-is" and "as available" basis. The
          Company makes no warranties or representations, express or implied,
          regarding the App's availability, functionality, or suitability for
          any particular purpose.
        </p>
        <p>
          6.2 The Company does not warrant that the App will be error-free,
          secure, or free from viruses or other harmful components. You
          acknowledge and agree that your use of the App is at your own risk.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          7.1 To the maximum extent permitted by applicable law, the Company and
          its officers, directors, employees, agents, and affiliates shall not
          be liable for any indirect, incidental, special, consequential, or
          punitive damages, including but not limited to loss of profits, data,
          or goodwill, arising from your use or inability to use the App.
        </p>

        <h2>8. Governing Law and Jurisdiction</h2>
        <p>
          8.1 These Terms shall be governed by and construed in accordance with
          the laws of [Jurisdiction]. Any disputes arising under or in
          connection with these Terms shall be subject to the exclusive
          jurisdiction of the courts of [Jurisdiction].
        </p>

        <h2>9. Changes to the Terms</h2>
        <p>
          9.1 The Company reserves the right to modify or update these Terms at
          any time without prior notice. It is your responsibility to review
          these Terms periodically for any changes. Your continued use of the
          App after the posting of any modifications to the Terms constitutes
          your acceptance of such changes.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
