import { Box } from "@mui/material";
import DropdownOptions from "app/components/DropdownOptions";
import { ListItem } from "app/containers/types";

import React from "react";

interface Props {
  readonly options?: ListItem[];
  readonly onClick?: () => void;
  readonly onCheckChange?: (val) => void;
  readonly checkedLabel?: string;
  readonly checked?: boolean;
  readonly hovered?: boolean;
}

export default function MenuWrapper({
  options = [],
  onClick,
  onCheckChange,
  checkedLabel,
  checked=false,
}: Props) {
  return (
    <Box position={'relative'}>
      <DropdownOptions list={options} checked={checked} onCheckChange={onCheckChange} checkedLabel={checkedLabel}/>
    </Box>
  );
}
