import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { StationsState } from '../types';

const selectDomain = (state: RootState) => state.stationsState || StationsState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.isLoading,
);
export const selectStations = createSelector([selectDomain], state => state.stations);
export const selectHasMore = createSelector([selectDomain], state => state.hasMore);
export const selectStation = createSelector(
  [selectDomain],
  state => state.form,
);

export const selectStationCoordinates = createSelector(
  [selectDomain],
  state => state.form.coordinates.value || [0, 0],
);

export const selectStationAddress = createSelector(
  [selectDomain],
  state => state.form.address.value || '',
);

export const selectQuestions = createSelector([selectDomain], state => state.questions);

export const selectSelectedQuestions = createSelector(
  [selectDomain],
  state => state.selectedQuestions
)

export const selectPagination = createSelector(
  [selectDomain],
  state => state.stationsPagination
)

export const selectQuestionsPagination = createSelector(
  [selectDomain],
  state => state.questionsPagination
)

export const selectIsLoadingQuestions = createSelector(
  [selectDomain],
  state => state.isLoadingQuestions
)
export const selectIsUnsaved = createSelector([selectDomain], state => state.isUnsaved);