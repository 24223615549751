/**
 * Gets the repositories of the user from Github
 */

import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import { actions as authAction } from "../../../../../redux/slice";
import { selectEmail, selectPassword } from "./selectors";
import toastService from "utils/toast";
import { FormElement } from "app/containers/types";
import { LoginResponse } from "../types";

/**
 * Github repos request/response handler
 */


export function* loginRequest(action) {
  try {
    yield delay(500);
    const email:FormElement = yield select(selectEmail);
    const password:FormElement = yield select(selectPassword);
    const requestURL = `${API_URL}/v1/auth/user/login`;
    const options = {
      method: "POST",
      body: JSON.stringify({email: email.value, password: password.value}),
    };
    const { data, err }: Res<{data:LoginResponse}> = yield call(request, requestURL, options);
    if(err || !data){
      throw err;
    }
    yield put(actions.loginSuccess());
    yield put(authAction.setCredentials(data?.data));
    yield put(authAction.getUser());
    yield localStorage.setItem("accessToken", data?.data?.accessToken);
    yield localStorage.setItem("refreshToken", data?.data?.refreshToken);
    yield call(action.payload?.callback);
  } catch (err: any) {
    yield put(actions.loginError());
    let errorMessage = err?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {
    }
    toastService.error(errorMessage?.message || errorMessage || "Something went wrong");
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export function* loginSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.login, loginRequest);
}
