import React from "react";
import UpperSection from "../UpperSection";
import MiddleSection from "../MiddleSection";
import BottomSection from "../BottomSection";
import Stations from "../Stations";
import { MODES } from "utils/constants";
import { Box } from "@mui/material";

const Form = ({
  mode
}:{
  mode: MODES
}) => {
  return (
    <Box sx={{padding: '12px'}}>
      <UpperSection disabled={mode === MODES.VIEW} />
      <MiddleSection disabled={mode === MODES.VIEW} />
      <BottomSection disabled={mode === MODES.VIEW} />
      <Stations disabled={mode === MODES.VIEW} />
    </Box>
  );
};

export default Form;
