import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState, CreateAssetResponse } from "../types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  url: null,
  isLoading: false,
};

const MediaUploadSlice = createSlice({
  name: "mediaUploadState",
  initialState,
  reducers: {
    uploadMedia(state, action: PayloadAction<{
      media: { 
        file: File;
        type: string;
        mimeType: string;
        name: string;
      },
      callback?: (url: CreateAssetResponse) => void;
    }>) {
      state.isLoading = true;
    },
    uploadMediaSuccess(state, action: PayloadAction<string>) {
      state.url = action.payload;
      state.isLoading = false;
    },
    uploadMediaError(state) {
      state.isLoading = false;
    }
  },
});

export const { actions, reducer: mediaUploadReducer, name: mediaUploadSlice } = MediaUploadSlice;
