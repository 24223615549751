import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import StationQuestions from "./components/StationQuestions";
import AllQuestions from "./components/AllQuestions";
import { MODES } from "utils/constants";

type Props = {
  mode?: MODES;
};
const Questions = ({
  mode
}: Props) => {

  const { t } = useTranslation();

  return (
    <Grid2 container spacing={3}>
      <Grid2 xs={12}>
        <Box>
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "100%",
              color: "#207DB9",
            }}
          >
            {t("QUESTIONS.TITLE")}
          </Typography>
        </Box>
      </Grid2>
      
      <Grid2
        xs={6}
      >
        <StationQuestions disabled={mode === MODES.VIEW} />
      </Grid2>
      <Grid2
        xs={6}
      >
        <AllQuestions disabled={mode === MODES.VIEW} />
      </Grid2>
    </Grid2>
  );
};

export default Questions;
