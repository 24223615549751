import React from "react";
import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import GamesStations from "./components/GamesStations";
import { useTranslation } from "react-i18next";
import AllStations from "./components/AllStations";

const Stations = ({
  disabled
}:{
  disabled?: boolean
}) => {
  const { t } = useTranslation();
  return (
    <Grid2 container spacing={3}>
      <Grid2 xs={12}>
        <Box>
          <Typography
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "100%",
              color: "#207DB9",
            }}
          >
            {t("STATIONS.TITLE")}
          </Typography>
        </Box>
      </Grid2>
      
      <Grid2
        xs={6}
      >
        <GamesStations disabled={disabled} />
      </Grid2>
      <Grid2
        xs={6}
      >
        <AllStations disabled={disabled} />
      </Grid2>
    </Grid2>
  );
};

export default Stations;
