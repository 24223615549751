import React, {  } from "react";
import { StyledRow } from "../Table";

interface CustomTableRowProps {
  readonly children: React.ReactNode;
  onDoubleClick?(): void;
  hoverOptionComponent?: React.ReactNode;
}

const CustomTableRow: React.FC<CustomTableRowProps> = ({
  children,
  hoverOptionComponent,
  onDoubleClick,
}) => {


  return (
    <>
      <StyledRow
       className="table-row"
       onDoubleClick={onDoubleClick}
      > 
        {hoverOptionComponent}
        {children}
       
      </StyledRow>
    </>
  );
};

export default React.memo(CustomTableRow);
