import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { OrganizationsState } from '../types';

const selectDomain = (state: RootState) => state.organizationsState || OrganizationsState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectOrganizations = createSelector([selectDomain], state => state.organizations);

export const selectPagination = createSelector([selectDomain], state => state.pagination);

export const selectFormValues = createSelector(
  [selectDomain],
  state => state.form,
);
export const selectIsUnsaved = createSelector([selectDomain], state => state.isUnsaved);