import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function TableHeader() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "30%"
        }}
      >
        <Typography
          style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "100%",
            color: "#5E6781",
            marginBottom: "16px",
            paddingTop: "16px",
            paddingLeft: "16px",
          }}
        >
          {t("STATIONS.FORM.LABELS.QUESTION_ID")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "40%"
        }}>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "100%",
            color: "#5E6781",
            marginBottom: "16px",
            paddingTop: "16px",
          }}
        >
          {t("STATIONS.FORM.QUESTION_BODY")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "30%"
        }}>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "100%",
            color: "#5E6781",
            marginBottom: "16px",
            paddingTop: "16px",
          }}
        >
          {t("STATIONS.FORM.LABELS.CREATOR_NAME")}
        </Typography>
      </Box>
    </Box>
  );
}
