
import { Typography } from '@mui/material';

import React from 'react';

import { QUESTION_TYPES } from 'utils/constants';
import { Question } from './types';


type Props = {
  question: Question
}

// The Chip component for displaying individual answers
const Chip: React.FC<{ text: string; img?: string; isCorrect?: boolean }> = ({ img, text, isCorrect }) => {
  const chipStyle: React.CSSProperties = {
    backgroundColor: isCorrect ? 'green' : 'gray',
    color: 'white',
    padding: '1px 2px',
    margin: '4px 4px 4px 0px',
    borderRadius: '4px',
    display: 'inline-block',
    maxWidth: 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };
  if (!text && !img) { return null}

  return <span style={chipStyle}>{text} { img && <img src={img} style={{ height: '10px', width: '10px'}} />}</span>;
};

// Main component to display the question and answers
const QuestionBodyWithAnswer = ({
  question,
}: Props) => {
  const {type, answers, body} = question

  const renderChips = () => {
    switch (type) {
      case QUESTION_TYPES.SINGLE_SELECT:
      case QUESTION_TYPES.MULTI_SELECT:
        return answers.map((answer) => {
          if(!answer.isCorrect) {return null}
         return  <Chip key={answer._id} text={answer.title} img={answer?.asset?.thumbUrl} isCorrect={answer.isCorrect} />
    });
      case QUESTION_TYPES.FREE_TEXT:
        return answers.map((answer, index) => {
          if(!answer.letters) {return null}
         const word = answer.letters.map(m => {
          return  m.option1
        }).join('')
         return  <Chip key={answer._id} text={`Word ${index + 1}: ${word}`}  isCorrect={true} />
        })
       case QUESTION_TYPES.CORRELATION:
          return answers.map((answer, index) => {
            if(!answer.letters) {return null}
           return  <Chip key={answer._id} text={`${answer.left}->${answer.right}`}  isCorrect={true} />
          })
      case QUESTION_TYPES.MIXED_LETTER:
        return answers.map((answer, index) => {
          if(!answer.letters) {return null}
          const word = answer.word
          return  <Chip key={answer._id} text={`Word ${index + 1}: ${word}`}  isCorrect={true} />
        })
      default:
        return null;
    }
  };


  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Typography component={'span'} noWrap>{getQuestionBodyText(body)}</Typography>
      <span style={{marginTop: '-5px'}}>{renderChips()}</span>
    </div>
  );
};

const getQuestionBodyText = (value: string) => {
  const val = value
  .replace(/<u>|<\/u>/g, '')     // Remove <u> and </u> tags
  .replace(/[#*`~>\[\]]+/g, '')  // Remove Markdown characters
  .replace(/!\[.*\]\(.*\)/g, '')   // Remove images
  .replace(/\[(.*?)\]\(.*?\)/g, '$1') // Keep link text, remove URLs
  .replace(/\n+/g, '\n');          // Normalize new lines

  return val.length > 55 ? val.substring(0, 55) + "..." : val;
};

export default QuestionBodyWithAnswer;
