import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TermsOfUsePage from './termOfUse';
interface Props { }

const Pages = (props: Props) => {


  return (

        <Routes>
          <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        </Routes>
  );
};

export default Pages;
