import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import PersonIcon from "@mui/icons-material/Person";
import MediaUploader from "app/components/MediaUploader";
import { useTranslation } from "react-i18next";
import { CreateAssetResponse } from "app/components/DragNDropDialog/types";

export default function Uploader({
  onUpload,
  src,
}: {
  onUpload: (url?: CreateAssetResponse) => void;
  src?: string;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "6px",
      }}
    >
      <MediaUploader
        showBtn={false}
        onSuccess={onUpload}
        src={src}
        title={t("COMMON.LABELS.UPLOAD_IMAGE")}
      >
        <IconButton
          sx={{
            border: "1px solid #86BDE3",
            width: "70px",
            height: "70px",
          }}
        >
          {src ? (
            <img
              style={{
                borderRadius: "50px",
                width: "70px",
                height: "70px",
              }}
              src={src}
            ></img>
          ) : (
            <PersonIcon
              sx={{
                color: "#5E6781",
                fontSize: "42px",
              }}
            />
          )}
        </IconButton>
      </MediaUploader>
    </Box>
  );
}
