import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import { useTranslation } from "react-i18next";
import CustomTable, { StyledCell } from "app/components/Table";
import { MenuOptions, TableHeaderDefinition, TableHeaders } from "./headers";
import FloatingButton from "app/components/Buttons/FloatingButton";
import { Typography } from "@mui/material";
import MenuWrapper from "app/components/MenuWrapper";
import ConfirmDialog from "app/components/ConfirmDialog";
import InputField from "app/components/InputField";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTickets,
  selectIsLoading,
  selectPagination,
} from "../../redux/selector";
import { API_URL, modifyMenuOptions } from "utils/constants";
import { actions } from "../../redux/slice";
import { selectOrganization } from "app/redux/selectors";
import {
  formatTableDate,
  formatTableDateTime,
  formatUUID,
} from "styles/theme/utils";
import CustomTableRow from "app/components/TableRow";
import { OrderDirections } from "app/containers/types";
import { selectDirection } from "styles/theme/slice";

const List = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const [confirmDelete, setConfirmDelete] = React.useState({
    status: false,
    id: "",
  });
  const tickets = useSelector(selectTickets);
  const isLoading = useSelector(selectIsLoading);
  const pagination = useSelector(selectPagination);
  const organization = useSelector(selectOrganization);
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const q = searchParams?.[0]?.get("q");
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction") as OrderDirections;

  useEffect(() => {
    dispatch(
      actions.getTickets({
        page: 1,
        search: q ? q : undefined,
        organization: organization?.value,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      })
    );
  }, [q, dispatch, organization?.value, orderBy, orderDirection]);

  const addNewUser = () => {
    navigate("/app/tickets/new");
  };

  const onClickAction = (id: string, ticketId: string, gameId?: string) => {
    switch (id) {
      case "1":
        window.open(
          `${API_URL}/v1/service/ticket-info/downloads/${ticketId}?type=PDF&source=STATION`,
          "_blank"
        );
        break;
      case "2":
        window.open(
          `${API_URL}/v1/service/ticket-info/downloads/${ticketId}?type=PDF&source=ANSWER`,
          "_blank"
        );
        break;
      case "3":
        navigate(`/results/${ticketId}`);
        break;
      case "4":
        navigate(`/app/games/${gameId}/view`);
        break;
      case "5":
        navigate(`/app/tickets/${ticketId}/edit`);
        break;
      case "6":
        setConfirmDelete({
          status: true,
          id: ticketId,
        });
        break;
      default:
        break;
    }
  };

  const deleteTicket = () => {
    dispatch(actions.deleteTicket(confirmDelete.id));
    setConfirmDelete({
      status: false,
      id: "",
    });
  };
  const hasMoreItems = pagination.page < pagination.total;
  const selectedTicketGameName = tickets?.find(
    (t) => t?._id === confirmDelete?.id
  )?.game?.name;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(
        actions.getTickets({
          page: pagination.page + 1,
          search: q ? q : undefined,
          organization: organization?.value,
          orderBy: orderBy as string,
          orderDirection: orderDirection?.toLowerCase() as OrderDirections,
        })
      );
    }
  };

  return (
    <>
      <Header />
      <CustomTable
        headers={TableHeaders}
        loading={isLoading}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        dataLength={tickets.length}
      >
        {tickets?.map((ticket) => (
          <CustomTableRow
            onDoubleClick={() => navigate(`/app/tickets/${ticket?._id}/view`)}
            key={ticket?._id}
            hoverOptionComponent={
              <MenuWrapper
                options={modifyMenuOptions(
                  MenuOptions,
                  ticket?._id ?? "",
                  onClickAction,
                  ticket?.game?._id ?? ""
                )}
              />
            }
          >
            <StyledCell
              minWidth={TableHeaderDefinition.TICKET_ID.minWidth}
              maxWidth={TableHeaderDefinition.TICKET_ID.maxWidth}
            >
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/app/tickets/${ticket?._id}/view`)}
              >
                {formatUUID(ticket?._id)}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CREATED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.CREATED_DATE.maxWidth}
            >
              {formatTableDateTime(ticket.createdAt)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.EDITED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.EDITED_DATE.maxWidth}
            >
              {formatTableDateTime(ticket.updatedAt)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.VALID_FROM.minWidth}
              maxWidth={TableHeaderDefinition.VALID_FROM.maxWidth}
            >
              {formatTableDate(ticket.startDate)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.VALID_TO.minWidth}
              maxWidth={TableHeaderDefinition.VALID_TO.maxWidth}
            >
              {formatTableDate(ticket.endDate)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CREDITS.minWidth}
              maxWidth={TableHeaderDefinition.CREDITS.maxWidth}
            >
              {ticket.noOfDevices > 0
                ? ticket.noOfDevices - Number(ticket?.noOfDevicesPlayed || 0)
                : t("COMMON.LABELS.UNLIMITED")}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.NUMBER_OF_DEVICES.minWidth}
              maxWidth={TableHeaderDefinition.NUMBER_OF_DEVICES.maxWidth}
            >
              {ticket.noOfDevices > 0
                ? `${Number(ticket?.noOfDevicesPlayed || 0)}/${
                    ticket.noOfDevices
                  }`
                : `${Number(ticket?.noOfDevicesPlayed || 0)}`}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CUSTOMIZED.minWidth}
              maxWidth={TableHeaderDefinition.CUSTOMIZED.maxWidth}
            >
              <Typography
                sx={{
                  color: "#94C137",
                  fontSize: "14px",
                }}
              >
                {ticket.customizations.isCustomizationAvailable
                  ? t("COMMON.TEXT.YES")
                  : t("COMMON.TEXT.NO")}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CUSTOMER_NAME.minWidth}
              maxWidth={TableHeaderDefinition.CUSTOMER_NAME.maxWidth}
            >
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(`/app/customers/${ticket?.customer?._id}/view`)
                }
              >
                {ticket.customer?.name}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.GAME_NAME.minWidth}
              maxWidth={TableHeaderDefinition.GAME_NAME.maxWidth}
            >
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/app/games/${ticket?.game?._id}/view`)}
              >
                {ticket?.game?.name}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.TICKET_CREATOR.minWidth}
              maxWidth={TableHeaderDefinition.TICKET_CREATOR.maxWidth}
            >
              <Typography
                noWrap
                sx={
                  ticket?.creator?._id
                    ? {
                        color: "#2F80ED",
                        fontSize: "14px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }
                    : {
                        fontSize: "14px",
                      }
                }
                onClick={() => {
                  if (!ticket?.creator?._id) {
                    return;
                  }
                  navigate(`/app/editors/${ticket?.creator?._id}/view`);
                }}
              >
                {ticket?.creator?.fullName ?? "N/A"}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.PASSWORD.minWidth}
              maxWidth={TableHeaderDefinition.PASSWORD.maxWidth}
            >
              {ticket.password}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.NUMBER_OF_STATIONS.minWidth}
              maxWidth={TableHeaderDefinition.NUMBER_OF_STATIONS.maxWidth}
            >
              {ticket?.game?.noOfStations}
            </StyledCell>
          </CustomTableRow>
        ))}
      </CustomTable>
      <FloatingButton onClick={addNewUser} label={t("TICKETS.TICKET")} />
      <ConfirmDialog
        open={confirmDelete.status}
        handleClose={() =>
          setConfirmDelete({
            status: false,
            id: "",
          })
        }
        onConfirm={deleteTicket}
        title={t("COMMON.DELETE.TITLE", { name: t("TICKETS.TICKET") })}
        message={t("COMMON.DELETE.MESSAGE", { name: t("TICKETS.TICKET") })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.DELETE")}
      >
        <InputField
          label="Game Name"
          value={selectedTicketGameName}
          fullWidth
          labelStyle={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
          }}
          borderColor="#FFFFFF"
          backgroundColor="#F3F6F8"
          mainStyle={{
            width: "100%",
          }}
        />
      </ConfirmDialog>
    </>
  );
};

export default List;
