import React from "react";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import SearchInput from "app/components/SearchInput";
import { LEFT_SPACING } from "utils/constants";

const Header = () => {
  return (
    <Grid2
      container
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
      }}
      paddingLeft={LEFT_SPACING}
    >
      <Grid2 xs={4}>
        <Box>
          <SearchInput />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Header;
