import {
  Box,
  Checkbox,
  Radio,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import React from "react";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomizeTicketQuestion } from "app/containers/Tickets/redux/selector";
import trash from "assets/ic_delete.svg";
import { actions } from "app/containers/Tickets/redux/slice";
import EditableColumn from "app/containers/Questions/components/Details/components/QuestionTypes/components/FreeText/components/EditableColumn";
import { selectDirection } from "styles/theme/slice";
export default function Answers() {
  const question = useSelector(selectCustomizeTicketQuestion);
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        marginBottom: "24px",
      }}
    >
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {question.answers.map((answer, index) => (
          <Box
            sx={{
              margin: 0,
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontWeight: 400,
              background: "#F3F6F8",
              padding: "8px 24px 8px 8px",
              borderRadius: "8px",
              width: "100%",
              display:"flex"
            }}
          >
            <FormControlLabel
              key={index}
              label=""
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "#E24399",
                    "&.Mui-checked": {
                      color: "#E24399",
                    },
                  }}
                  checked={answer.isCorrect}
                  onChange={() => {
                    dispatch(
                      actions.setCustomizeQuestionAnswer({
                        index: index,
                        key: "isCorrect",
                        value: !answer.isCorrect,
                      })
                    );
                  }}
                />
              }
            />
            <EditableColumn
              disabled={false}
              value={answer.title}
              isOdd={false}
              innerContainerStyle={{
                width: "100%",
              }}
              inputStyle={{
                background: "transparent",
              }}
              onSave={(val) => {
                dispatch(
                  actions.setCustomizeQuestionAnswer({
                    index: index,
                    key: "title",
                    value: val,
                  })
                );
              }}
            />
            <IconButton
              onClick={() => {
                dispatch(
                  actions.deleteCustomizeFormQuestionAnswer({
                    index: index,
                  })
                );
              }}
              sx={{
               
             
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                width: "32px",
                height: "32px",
                borderRadius: "4px",
                
              }}
            >
              <img src={trash} alt="upload" />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
