import { Box, IconButton } from '@mui/material'
import React from 'react'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export default function ActionButtons({
    isOdd=false,
    onCancel,
    onSave
}:{
    isOdd?: boolean
    onCancel?: () => void,
    onSave?: () => void
}) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
            }}
        >
            <IconButton
                sx={{
                    width: "35px",
                    height: "24px",
                    padding: "12px",
                    borderRadius: "4px",
                    gap: "6px",
                    backgroundColor: isOdd ? "#FFFFFF" : "#F3F6F8",
                    "&:hover": {
                        backgroundColor: "transparent"
                    }
                }}
                onClick={onSave}
            >
                <CheckOutlinedIcon 
                    sx={{
                        color: "#207DB9",
                        fontSize: "14px"
                    }}
                />
            </IconButton>
            <IconButton
                onClick={onCancel}
                sx={{
                    width: "35px",
                    height: "24px",
                    padding: "12px",
                    borderRadius: "4px",
                    gap: "6px",
                    backgroundColor: isOdd ? "#FFFFFF" : "#F3F6F8",
                    "&:hover": {
                        backgroundColor: "transparent"
                    }
                }}
            >
                <ClearOutlinedIcon 
                    sx={{
                        color: "#3C3C43",
                        fontSize: "14px"
                    }}
                />
            </IconButton>
        </Box>
    )
}
