import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import InputField from "app/components/InputField";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { selectEmail, selectLoading } from "../../redux/selectors";
import { actions } from "../../redux/slice";
import { validate } from "../../validator";
import { useTranslation } from "react-i18next";


export const ForgotPasswordForm = ({
    setIsSubmitted,
}:{
    setIsSubmitted: (value: boolean) => void;
}) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const email = useSelector(selectEmail);
    const isLoading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onSubmit = (e) => {
      e?.preventDefault();
      const error = validate(email);
      if (error) {
        dispatch(actions.setFormErrors(error));
        return;
      }
      dispatch(actions.submit({
        callback: () => setIsSubmitted(true),
      }))
    }
    return (
      <form className={styles.form}>
        <Box width="100%" mt={1}>
          <Typography className={styles.loginText}>
            {t("AUTH.FORGOT_PASSWORD.TITLE")}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "27px",
              letterSpacing: "0em",
              textAlign: "center",
              marginBottom: "32px",
              color: "#8890A6",
            }}
          >
            {t("AUTH.FORGOT_PASSWORD.CAPTION")}
          </Typography>
        </Box>
        <InputField
          label={t("AUTH.FORM.EMAIL_ADDRESS")}
          onChange={(e) => dispatch(actions.setFormValues({
            key: "email",
            value: e.target.value,
          }))}
          fullWidth
          mainStyle={{ marginBottom: "16px", width: "100%" }}
          labelStyle={{ fontWeight: 600 }}
          value={email?.value}
          error={email?.error}
          isDisable={isLoading}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSubmit(e);
            }
          }}
        />
        <Box
          className={styles.loginButtonWrapper}
          sx={{
            my: 3,
          }}
        >
          <PrimaryButton
            label={t("AUTH.BUTTONS.RESET_PASSWORD")}
            sx={{
              width: "100%",
              height: "48px",
              textTransform: "none",
            }}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onSubmit}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            flexDirection: "row",
            marginBottom: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#207DB9",
              cursor: "pointer",
            }}
            onClick={() => navigate("/auth/login")}
          >
            {t("AUTH.FORM.BACK_TO_LOGIN")}
          </Typography>
        </Box>
      </form>
    );
  };