import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface MediaModalProps {
  open: boolean;
  onClose: () => void;
  url: string;
  type: "image" | "video" | "text";
  name?: string;
}

const MediaModal: React.FC<MediaModalProps> = ({ open, onClose, url, type, name }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "24px",
          boxShadow: 24,
          outline: "none",
          width: '70vw',
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        {type === "video" && (
          <video
            src={url}
            controls
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '100%',
              height: '95%',
            }}
          />
        )}
        {type === "image" && (
          <img
            src={url}
            alt={name}
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'block',
              height: '95%'
            }}
          />
        )}
        {type === "text" && (
          <Typography variant="body1" sx={{ whiteSpace: "pre-wrap", maxHeight: '100%', overflowY: 'auto' }}>
            {name}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default MediaModal;
