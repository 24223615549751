import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import excelImage from "assets/excel.svg";
import CustomTable, { StyledCell } from "app/components/Table";

import OutlineButton from "app/components/Buttons/OutlineButton";
import { TableHeaderDefinition, TableHeaders } from "./headers";
import { useTranslation } from "react-i18next";
import FloatingButton from "app/components/Buttons/FloatingButton";
import { useDispatch, useSelector } from "react-redux";
import { selectDirection } from "styles/theme/slice";
import {
  selectCustomers,
  selectIsLoading,
  selectPagination,
} from "../../redux/selector";
import { actions } from "../../redux/slice";
import { selectOrganization } from "app/redux/selectors";
import { API_URL } from "utils/constants";
import { formatTableDateTime, formatUUID } from "styles/theme/utils";
import CustomTableRow from "app/components/TableRow";
import { Typography } from "@mui/material";
import { OrderDirections } from "app/containers/types";
import { DateTime } from "luxon";
const List = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);
  const customers = useSelector(selectCustomers);
  const pagination = useSelector(selectPagination);
  const isLoading = useSelector(selectIsLoading);
  const organization = useSelector(selectOrganization);
  const searchParams = useSearchParams();
  const q = searchParams?.[0]?.get("q");
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction");

  useEffect(() => {
    dispatch(
      actions.getCustomers({
        page: 1,
        search: q ? q : undefined,
        organization: organization?.value,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      })
    );
  }, [q, dispatch, organization?.value, orderBy, orderDirection]);

  const addNewUser = () => {
    navigate("/app/customers/new");
  };
  const hasMoreItems = pagination.page < pagination.total;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(
        actions.getCustomers({
          page: pagination.page + 1,
          search: q ? q : undefined,
          organization: organization?.value,
          orderBy: orderBy as string,
          orderDirection: orderDirection?.toLowerCase() as OrderDirections,
        })
      );
    }
  };

  const downloadExcel = () => {
    const authToken = localStorage.getItem("accessToken") || "";
    let url = new URL(`${API_URL}/v1/admin/customer/download`);
    url.searchParams.append("authToken", authToken);
    if (q) {
      url.searchParams.append("search", q);
    }
    if (organization?.value) {
      url.searchParams.append("organization", organization?.value);
    }
    if (orderBy) {
      url.searchParams.append("orderBy", orderBy);
    }
    if (orderDirection) {
      url.searchParams.append("orderDirection", orderDirection);
    }
    const dt = DateTime.now();
    url.searchParams.append("timeOffset", String(dt.offset));
    window.open(url, "_blank");
  };

  return (
    <>
      <Header />
      <CustomTable
        headers={TableHeaders}
        loading={isLoading}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        dataLength={customers.length}
      >
        {customers.map((customer, index) => (
          <CustomTableRow
            onDoubleClick={() =>
              navigate(`/app/customers/${customer?._id}/view`)
            }
            key={customer?._id}
          >
            <StyledCell
              minWidth={TableHeaderDefinition.CUSTOMER_ID.minWidth}
              maxWidth={TableHeaderDefinition.CUSTOMER_ID.maxWidth}
            >
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/app/customers/${customer?._id}/view`);
                }}
              >
                {formatUUID(customer._id)}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.REGISTRATION_DATE.minWidth}
              maxWidth={TableHeaderDefinition.REGISTRATION_DATE.maxWidth}
            >
              {formatTableDateTime(customer.createdAt)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.LAST_PURCHASE_DATE.minWidth}
              maxWidth={TableHeaderDefinition.LAST_PURCHASE_DATE.maxWidth}
            >
              {formatTableDateTime(customer.lastPurchaseDate)}
            </StyledCell>{" "}
            {/*  Last purchase date */}
            <StyledCell
              minWidth={TableHeaderDefinition.CUSTOMER_NAME.minWidth}
              maxWidth={TableHeaderDefinition.CUSTOMER_NAME.maxWidth}
            >
              {customer.name}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.MOBILE_NUMBER.minWidth}
              maxWidth={TableHeaderDefinition.MOBILE_NUMBER.maxWidth}
            >
              {customer.mobileNumber}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.EMAIL.minWidth}
              maxWidth={TableHeaderDefinition.EMAIL.maxWidth}
            >
              {customer.email}
            </StyledCell>
            <StyledCell
              minWidth={
                TableHeaderDefinition.NUMBER_OF_PURCHASED_GAMES.minWidth
              }
              maxWidth={
                TableHeaderDefinition.NUMBER_OF_PURCHASED_GAMES.maxWidth
              }
            >
              {customer.numberOfPurchasedGames}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.NUMBER_OF_PLAYED_GAMES.minWidth}
              maxWidth={TableHeaderDefinition.NUMBER_OF_PLAYED_GAMES.maxWidth}
            >
              {customer.noOfPlayedGames}
            </StyledCell>
          </CustomTableRow>
        ))}
      </CustomTable>
      <FloatingButton
        onClick={addNewUser}
        label={t("CUSTOMERS.CUSTOMER")}
        containerStyle={{
          width: "150px",
        }}
      />
      <OutlineButton
        onClick={downloadExcel}
        label={t("COMMON.BUTTONS.EXPORT_TO_EXCEL")}
        startIcon={<img src={excelImage} alt="excel" />}
        sx={{
          textTransform: "uppercase",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "100%",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          padding: "13px 16px",
          width: "200px",
          height: "45px",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          margin: 0,
          top: "auto",
          right: direction === "ltr" ? 210 : "auto",
          left: direction === "ltr" ? "auto" : 210,
          bottom: 20,
          position: "fixed",
        }}
      />
    </>
  );
};

export default List;
