import { Question } from "app/containers/Questions/types";

export type GameQuestionsState = {
  questions: Question[];
  isLoading: boolean;
  selectedQuestion: string;
};

export const InitialGamesQuestionsState: GameQuestionsState = {
  questions: [],
  isLoading: false,
  selectedQuestion: '',
};

export type ContainerState = GameQuestionsState;
