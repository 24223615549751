import React from 'react';
import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { resultsReducer, resultsSlice } from './redux/slice';
import { resultsSaga } from './redux/saga';
import { ticketsReducer, ticketsSlice } from '../Tickets/redux/slice';
import { ticketsSaga } from '../Tickets/redux/saga';

const Results = () => {
    useInjectReducer({ key: resultsSlice, reducer: resultsReducer });
    useInjectSaga({ key: resultsSlice, saga: resultsSaga });
    useInjectReducer({ key: ticketsSlice, reducer: ticketsReducer });
    useInjectSaga({ key: ticketsSlice, saga: ticketsSaga });

    return (
        <Routes>
            <Route path="/" element={<List />} />
        </Routes> 
    );
};

export default Results;
