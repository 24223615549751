import { PictureAsPdfSharp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import OutlineButton from "app/components/Buttons/OutlineButton";
import InputField from "app/components/InputField";
import React from "react";
import StartGame from "./components/StartGame";
import EndGame from "./components/EndGame";
import MediaUploader from "app/components/MediaUploader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectGame } from "app/containers/Games/redux/selector";
import { actions } from "app/containers/Games/redux/slice";
import { getFileNameFromUrl } from "utils/constants";
import { MediaTypes } from "app/containers/types";
import { selectDirection } from "styles/theme/slice";

export default function MiddleSection({
  disabled,
}: {
  readonly disabled?: boolean;
}) {
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const game = useSelector(selectGame);
  const dispatch = useDispatch();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(actions.setFormValues({ key: name, value }));
  };

  return (
    <Box sx={{ mt: "32px" }}>
      <Typography
        sx={{
          fontFamily: direction === "ltr" ? "Inter" : "Rubik",
          fontSize: "32px",
          fontWeight: 700,
          linHeight: "32px",
          letterSpacing: "0em",
          color: "#207DB9",
          marginBottom: "24px",
        }}
      >
        {t("GAMES.FORM.CONFIGURE_TEXT")}
      </Typography>
      <Grid2
        sx={{
          backgroundColor: "#F3F6F8",
          borderRadius: "8px",
          padding: "24px",
        }}
        container
        spacing={3}
      >
        <Grid2 md={12}>
          <Box
            sx={{
              width: "100%",
              height: "160px",
            }}
          >
            <InputField
              tooltip={t("GAMES.FORM.LABELS.CONFIGURE_TEXT_TOOLTIP")}
              label={t("GAMES.FORM.LABELS.GROUP_INSTRUCTIONS")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              multiline
              fullWidth
              rows={4}
              value={game?.groupInstructionText?.value}
              isDisable={disabled}
              name="groupInstructionText"
              onChange={onChange}
              lightPlaceholder
              error={
                game?.groupInstructionText?.error
                  ? t(game?.groupInstructionText?.error)
                  : ""
              }
            />
          </Box>
        </Grid2>
        <Grid2 md={6}>
          <MediaUploader
            disabled={disabled}
            showBtn={false}
            error={
              game?.groupInstructionPDF?.error
                ? t(game?.groupInstructionPDF?.error)
                : ""
            }
            src={game?.groupInstructionPDF.value?.url}
            onSuccess={(res) => {

              dispatch(
                actions.setFormValues({
                  key: "groupInstructionPDF",
                  value: res,
                })
              );
              dispatch(
                actions.setFormValues({
                  key: "groupInstructionPDFId",
                  value: res?._id,
                })
              );
            }}
          
            type={MediaTypes.PDF}
          >
            <OutlineButton
              sx={{
                width: "100%",
                textTransform: "uppercase",
                backgroundColor: "#FFFFFF",
                justifyContent: 'flex-start',
              }}
              label={
                game?.groupInstructionPDF?.value?.url
                  ? getFileNameFromUrl(game?.groupInstructionPDF?.value?.url, 60)
                  : t("GAMES.FORM.BUTTONS.UPLOAD_PDF_FILE")
              }
              startIcon={<PictureAsPdfSharp />}
              disabled={disabled}
            />
          </MediaUploader>
        </Grid2>
        <Grid2 md={6} />
        <Grid2 md={6}>
          <StartGame disabled={disabled} />
        </Grid2>
        <Grid2 md={6}>
          <EndGame disabled={disabled} />
        </Grid2>
      </Grid2>
    </Box>
  );
}
