import { FormError } from "app/containers/types";
import { CustomerForm } from "../../types";
import { validateAndFormatIsraeliMobileNumber } from "styles/theme/utils";

export const validateCustomer = (form: CustomerForm): FormError[] => {
  let errors: FormError[] = [];
  // const regexOnlyAlphabet = /[\u0041-\u005A\u0061-\u007A\u0591-\u05F4]/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(05)\d{8}$/;

  if (!form.email.value) {
    errors.push({ name: "email", error: "CUSTOMERS.ERRORS.EMAIL_IS_REQUIRED" });
  }
  if (form.email.value && !emailRegex.test(form.email.value)) {
    errors.push({ name: "email", error: "CUSTOMERS.ERRORS.INVALID_EMAIL" });
  }
  // if (!form.name.value) {
  //   errors.push({ name: 'name', error: 'CUSTOMERS.ERRORS.NAME_IS_REQUIRED' });
  // }
  // if (form.name.value && !regexOnlyAlphabet.test(form.name.value)) {
  //   errors.push({ name: 'name', error: 'CUSTOMERS.ERRORS.INVALID_NAME' });
  // }
  // if (form.name.value && form.name.value.length>40) {
  //   errors.push({ name: 'name', error: 'CUSTOMERS.ERRORS.NAME_MAX_LENGTH' });
  // }
  if (!form.companyName.value) {
    errors.push({
      name: "companyName",
      error: "CUSTOMERS.ERRORS.COMPANY_NAME_IS_REQUIRED",
    });
  }
  if (form.companyName.value && form.companyName.value.length > 40) {
    errors.push({
      name: "companyName",
      error: "CUSTOMERS.ERRORS.COMPANY_MAX_LENGTH",
    });
  }
  // if (!form.taxNumber.value) {
  //   errors.push({ name: 'taxNumber', error: 'CUSTOMERS.ERRORS.TAX_NUMBER_IS_REQUIRED' });
  // }
  // if (!form.address.value) {
  //   errors.push({ name: 'address', error: 'CUSTOMERS.ERRORS.ADDRESS_IS_REQUIRED' });
  // }
  if (!form.mobileNumber.value) {
    errors.push({
      name: "mobileNumber",
      error: "CUSTOMERS.ERRORS.MOBILE_NUMBER_IS_REQUIRED",
    });
  }
  if (
    form.mobileNumber.value &&
    !validateAndFormatIsraeliMobileNumber(form.mobileNumber.value).isValid
  ) {
    errors.push({
      name: "mobileNumber",
      error: "CUSTOMERS.ERRORS.INVALID_MOBILE_NUMBER",
    });
  }

  return errors;
};
