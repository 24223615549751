import { FormElement } from "app/containers/types";
import { ASSET_TYPE, TAsset } from "utils/constants";

export type ProfileState = {
    form: ProfileForm;
    isLoading: boolean;
};

export interface ProfileForm {
  _id?: FormElement;
  fullName: FormElement;
  mobileNumber: FormElement;
  noOfQuestions: number;
  noOfGames: number;
  email: FormElement;
  departmentName: FormElement;
  photo?: TAsset;
}

export const ProfileState: ProfileState = {
    isLoading: false,
    form: {
        _id: { value: "" },
        fullName: { value: "" },
        mobileNumber: { value: "" },
        noOfQuestions: 0,
        noOfGames: 0,
        email: { value: "" },
        departmentName: { value: "" },
        photo: {
            _id: "",
            fileName: "",
            filePath: "",
            mimeType: "",
            type: ASSET_TYPE.IMAGE,
          },
    },
}

export interface Profile {
    fullName: string;
    mobileNumber: string;
    noOfQuestions?: number;
    noOfGames?: number;
    email?: string;
    departmentName: string;
    photo?: TAsset;
}

export type ContainerState = ProfileState;
