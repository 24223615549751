import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { InitialGamesQuestionsState } from '../types';


const selectDomain = (state: RootState) => state.gameQuestionsState || InitialGamesQuestionsState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.isLoading,
);

export const selectQuestions = createSelector(
  [selectDomain],
  state => state.questions,
);

export const selectSelectedQuestion = createSelector(
  [selectDomain],
  state => state.selectedQuestion,
);