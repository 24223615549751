import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const LineButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100], // Light background color
  borderRadius: '16px', 
  color: '#207DB9', 
  textTransform: 'none', 
  padding: '3px 5px', 
  '&:hover': {
    backgroundColor: theme.palette.grey[200], // Slightly darker on hover
  },
  '& .MuiButton-label': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .MuiButton-label:after': {
    content: '""',
    display: 'block',
    marginTop: '4px',
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.primary.main, // Underline color
  }
}));


