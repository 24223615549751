import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditableColumn from "../EditableColumn";
import { FreeTextItemAnswer } from "app/containers/Questions/types";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Questions/redux/slice";
import { useTranslation } from "react-i18next";
import { selectDirection } from "styles/theme/slice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "14px",
    fontWeight: 400,
    border: 0,
    height: "45px",
    padding: "0 16px",
    width: "150px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontWeight: 400,
    padding: "0 16px",
    height: "45px",
    width: "150px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F3F6F8",
  },
  "& td, & th": {
    border: 0,
  },
}));

const FreeTextCard = ({
  disabled,
  data,
  dataIndex,
}: {
  disabled?: boolean;
  dataIndex: number;
  data: FreeTextItemAnswer[];
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const direction = useSelector(selectDirection);

  return (
    <Box
      sx={{
        border: "1px solid #207DB9",
        borderRadius: "4px",
        marginTop: "10px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          background: "#F3F6F8",
          borderRadius: "4px 4px 0px 0px",
          height: "36px",
          padding: "10px",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "18px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#207DB9",
          }}
        >
          {`${t("QUESTIONS.FORM.LABELS.WORD")} ${dataIndex + 1}`}
        </Typography>
      </Box>

      <Table sx={{ minWidth: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            {[1, 2, 3, 4, 5, 6, 7].map((row) => (
              <StyledTableCell align={direction == 'ltr' ? "left" : 'right'}>
                <Typography
                  sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    color: "#5E6781",
                    padding: "8px",
                  }}
                >
                  {row}
                </Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell 
              sx={{
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              }}
              component="th" 
              scope="row">
              {t("QUESTIONS.FORM.LABELS.SHOW_LETTER")}
            </StyledTableCell>
            {data?.map((row, index) => (
              <StyledTableCell 
                sx={{
                  fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                }}
                align={direction == 'ltr' ? "left" : 'right'}
                key={index} 
                component="th" 
                scope="row">
                <Checkbox
                  checked={row.isVisible}
                  size="small"
                  disabled={disabled}
                  onChange={()=>{
                    dispatch(actions.setUpdateMixedLetterAnswer({index:dataIndex, innerIndex:index,key:"isVisible",value:!row.isVisible})); 
                  }}
                  sx={{
                    margin: 0,
                    padding: "4px",
                    color: "#207DB9",
                    "&.Mui-checked": {
                      color: "#207DB9",
                    },
                  }}
                />
              </StyledTableCell>
            ))}
           
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              sx={{
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              }} 
              component="th" 
              scope="row">
            {t("QUESTIONS.FORM.LABELS.OPTION_ONE")}
            </StyledTableCell>
            {data?.map((row, index) => (
              <StyledTableCell 
                sx={{
                  fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                }}
                key={index} 
                component="th" 
                scope="row">
                <EditableColumn
                  value={row.option1}
                  isOdd={index % 2 === 0}
                  disabled={disabled}
                  maxLength={1}
                  onSave={(val) => {
                    dispatch(actions.setUpdateMixedLetterAnswer({index:dataIndex, innerIndex:index,key:"option1",value:val})); 
                  }}
                />
              </StyledTableCell>
            ))}
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell 
              sx={{
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              }}
              component="th" 
              scope="row">
            {t("QUESTIONS.FORM.LABELS.OPTION_TWO")}
            </StyledTableCell>
            {data?.map((row, index) => (
              <StyledTableCell 
                sx={{
                  fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                }}
                key={index} 
                component="th" 
                scope="row">
                <EditableColumn
                  value={row.option2}
                  isOdd={index % 2 === 0}
                  disabled={disabled}
                  maxLength={1}
                  onSave={(val) => {dispatch(actions.setUpdateMixedLetterAnswer({index:dataIndex, innerIndex:index,key:"option2",value:val})); }}
                />
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default FreeTextCard;
