import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import set from "lodash/set";
import { FormError } from "app/containers/types";
import { Profile, ProfileState } from "../types";

const initialState = ProfileState;

export const formSlice = createSlice({
  name: "profileState",
  initialState,
  reducers: {
    setFormValues(state, action: PayloadAction<{ key: string; value: any }>) {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
    },
    setFormPhoto(state, action: PayloadAction<{ key: string; value: any }>) {
      set(state.form, `${action.payload.key}`, action.payload.value);
    },
    setFormErrors(
      state,
      action: PayloadAction<FormError[]>
    ) {
      action.payload.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    updateProfile(state, action: PayloadAction<{profile: Profile; callback?: ()=>void}>) {
        state.isLoading = true;
    },
    setDefaultProfileValues(state, action: PayloadAction<Profile>){
      state.form.email.value = action.payload.email;
      state.form.fullName.value = action.payload.fullName;
      state.form.mobileNumber.value = action.payload.mobileNumber;
      state.form.departmentName.value = action.payload.departmentName;
      state.form.photo = action.payload.photo;
    },
    updateProfileSuccess(state, action: PayloadAction<Profile>) {
        state.form.email.value = action.payload.email;
        state.form.fullName.value = action.payload.fullName;
        state.form.mobileNumber.value = action.payload.mobileNumber;
        state.form.departmentName.value = action.payload.departmentName;
        state.isLoading = false;
    },
    updateProfileError(state) {
        state.isLoading = false;
    },
    resetForm(state) {
        state.form = initialState.form;
    }
  },
});

export const { reducer: profileReducer, actions, name: profileSlice } = formSlice;
