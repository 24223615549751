import { Box } from '@mui/material'
import React from 'react'
import logo from 'assets/logo.png'
import ContactItem, { Type } from './components/ContactItem'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

const ContactInfo:{
    id: string,
    type: Type,
    value: string,
}[] = [
    {
        id: "1",
        type: "phone",
        value: "+91 9876543210",
    },
    {
        id: "2",
        type: "address",
        value: "Morem ipsum dolor",
    },
    {
        id: "3",
        type: "email",
        value: "twigo@gmail.com"
    },
    {
        id: "4",
        type: "website",
        value: "www.mifrasim.org"
    }
]

export default function Footer() {
    return (
        <Grid2
            container
            spacing={3}
            sx={{
                padding: "16px",
                background: "#26A8E0",
                alignItems: "center",
            }}
        >
            <Grid2 md={4}>
                <img alt="logo" src={logo} width="145px" height="50px" />
            </Grid2>
            {
                ContactInfo?.map((item, i) => {
                    return (
                        <Grid2 md={2}>
                            <ContactItem 
                                type={item?.type}
                                text={item?.value}
                            />
                        </Grid2>
                    )
                }) 
            }
        </Grid2>
    )
}
