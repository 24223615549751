import { Add } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectDirection } from 'styles/theme/slice'

export default function Header({
    onAdd
}:{
    onAdd: ()=>void
}) {
    const direction = useSelector(selectDirection);
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <Typography
                sx={{
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "19px",
                    letterSpacing: "0em",
                    color: "#3C3C43"
                }}
            >
                {t("TICKETS.CUSTOMIZED_TICKETS.CUSTOMIZED_QUESTIONS")}
            </Typography>
            <Typography
                sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    color: "#E24399",
                    cursor: "pointer",
                }}
                onClick={onAdd}
            >
                <Add 
                    sx={{
                        fontSize: "20px",
                    }}
                />
                {t("TICKETS.CUSTOMIZED_TICKETS.QUESTION")}
            </Typography>
        </Box>
    )
}
