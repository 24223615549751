import React from 'react'
import PrimaryButton from '../PrimaryButton'
import { Add } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

export default function FloatingButton({
    label,
    onClick,
    containerStyle,
    startIcon
}:{
    label?:string;
    onClick?:()=>void;
    containerStyle?: SxProps<Theme>,
    startIcon?: React.ReactNode
}) {
    const direction = useSelector(selectDirection)
    return (
        <PrimaryButton 
            label={label || "Add"}
            startIcon={startIcon || <Add />}
            sx={{
                textTransform: "uppercase",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "100%",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                padding: "13px 16px",
                width: "150px",
                height: "45px",
                backgroundColor: "#E24399",
                borderRadius: "8px",
                margin: 0,
                top: 'auto',
                right: direction === "ltr" ? 30 : "auto",
                bottom: 20,
                left: direction === "ltr" ? "auto" : 30,
                position: 'fixed',
                ...containerStyle
            }}
            onClick={onClick}
        />
    )
}
