import { Box, Typography } from '@mui/material'
import { selectCustomer } from 'app/containers/Customers/redux/selector'
import { selectTicket } from 'app/containers/Tickets/redux/selector'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectDirection } from 'styles/theme/slice'

export default function Summary() {
  const { t } = useTranslation()
  const direction = useSelector(selectDirection);
  const ticket = useSelector(selectTicket)
  return (
    <Box
      sx={{
        border: "1px solid #F2F2F2 !important",
        borderRadius: "8px",
        marginBottom: "16px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("TICKETS.FORM.NUMBER_OF_DEVICES_PLAYED")}
        </Typography>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {ticket.noOfDevicesPlayed.value}
        </Typography>
      </Box>

    </Box>
  )
}
