import { FormError } from "app/containers/types";
import { OrganizationForm } from "../../types";
import { validateAndFormatIsraeliMobileNumber } from "styles/theme/utils";

export const validateOrganization = (values: OrganizationForm) => {
    const errors: Array<FormError> = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(05)\d{8}$/
    if (!values.name.value) {
        errors.push({ name: "name", error: "ORGANIZATIONS.ERRORS.NAME_IS_REQUIRED" });
    }
    // if(!values.contactName.value) {
    //     errors.push({ name: "contactName", error: "ORGANIZATIONS.ERRORS.CONTACT_NAME_IS_REQUIRED" });
    // }
    if (!values.email.value) {
        errors.push({ name: "email", error: "ORGANIZATIONS.ERRORS.EMAIL_IS_REQUIRED" });
    } else if (!emailRegex.test(values.email.value)) {
        errors.push({ name: "email", error: "ORGANIZATIONS.ERRORS.INVALID_EMAIL" });
    }
    // if (!values.mobileNumber.value) {
    //     errors.push({ name: "mobileNumber", error: "ORGANIZATIONS.ERRORS.MOBILE_NUMBER_IS_REQUIRED" });
    // }
    if (values.mobileNumber.value && !validateAndFormatIsraeliMobileNumber(values.mobileNumber.value).isValid) {
        errors.push({ name: "mobileNumber", error: "ORGANIZATIONS.ERRORS.INVALID_MOBILE_NUMBER" });
    }
    // if (!values.address.value) {
    //     errors.push({ name: "address", error: "ORGANIZATIONS.ERRORS.ADDRESS_IS_REQUIRED" });
    // }
    // if (!values.taxNumber.value) {
    //     errors.push({ name: "taxNumber", error: "ORGANIZATIONS.ERRORS.TAX_NUMBER_IS_REQUIRED" });
    // }
    // if (!values.note.value) {
    //     errors.push({ name: "note", error: "ORGANIZATIONS.ERRORS.NOTE_IS_REQUIRED" });
    // }
    return errors;
};