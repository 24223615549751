import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import CustomTable, { StyledCell } from "app/components/Table";
import { MenuOptions, TableHeaderDefinition, TableHeaders } from "./headers";
import { Tooltip, Typography } from "@mui/material";
import MenuWrapper from "app/components/MenuWrapper";
import ConfirmDialog from "app/components/ConfirmDialog";
import FloatingButton from "app/components/Buttons/FloatingButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStations,
  selectIsLoading,
  selectPagination,
} from "../../redux/selector";
import { modifyMenuOptions } from "utils/constants";
import { actions } from "../../redux/slice";
import { selectOrganization } from "app/redux/selectors";
import { StationTypes } from "../../types";
import { formatTableDateTime, formatUUID } from "styles/theme/utils";
import CustomTableRow from "app/components/TableRow";
import { OrderDirections } from "app/containers/types";

const List = () => {
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = React.useState({
    status: false,
    id: "",
  });
  const { t } = useTranslation();
  const stations = useSelector(selectStations);
  const isLoading = useSelector(selectIsLoading);
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const q = searchParams?.[0]?.get("q");
  const user = searchParams?.[0]?.get("user");
  const city = searchParams?.[0]?.get("city");
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction") as OrderDirections;
  const organization = useSelector(selectOrganization);

  useEffect(() => {
    dispatch(
      actions.getStations({
        page: 1,
        search: q ? q : undefined,
        organization: organization?.value,
        user: user || undefined,
        city: city || undefined,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      })
    );
  }, [q, dispatch, organization?.value, user, city, orderBy, orderDirection]);

  const addNewStation = () => {
    navigate("/app/stations/new");
  };

  const deleteStation = () => {
    dispatch(actions.deleteStation(confirmDelete.id));
    setConfirmDelete({
      status: false,
      id: "",
    });
  };

  const onClickAction = (id: string, stationId: string) => {
    switch (id) {
      case "1":
        navigate(`/app/stations/${stationId}/duplicate`);
        break;
      case "2":
        navigate(`/app/stations/${stationId}/edit`);
        break;
      case "3":
        setConfirmDelete({
          status: true,
          id: stationId,
        });
        break;
      default:
        break;
    }
  };
  const hasMoreItems = pagination.page < pagination.total;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getStations({
        page: pagination.page + 1,
        search: q ? q : undefined,
        organization: organization?.value,
        user: user || undefined,
        city: city || undefined,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      }));
    }
  };
  return (
    <>
      <Header />
      <CustomTable
        headers={TableHeaders}
        loading={isLoading}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        dataLength={stations.length}
      >
        {stations.map((s, index) => (
          <CustomTableRow
            onDoubleClick={() => navigate(`/app/stations/${s._id}/view`)}
            key={index}
            hoverOptionComponent={<MenuWrapper
              onClick={() => navigate(`/app/stations/${s?._id}/view`)}
              options={modifyMenuOptions(
                MenuOptions,
                s?._id ?? "",
                onClickAction
              )}
       
            />}
          >
            <StyledCell
              minWidth={TableHeaderDefinition.STATION_ID.minWidth}
              maxWidth={TableHeaderDefinition.STATION_ID.maxWidth}>
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/app/stations/${s?._id}/view`)}
              >
                {formatUUID(s._id)}
              </Typography>
            </StyledCell>
           
            <StyledCell
              minWidth={TableHeaderDefinition.STATION_NAME.minWidth}
              maxWidth={TableHeaderDefinition.STATION_NAME.maxWidth}
            >{s.name}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.STATION_CITY.minWidth}
              maxWidth={TableHeaderDefinition.STATION_CITY.maxWidth}
            >{s.city?.name}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.STATION_TYPE.minWidth}
              maxWidth={TableHeaderDefinition.STATION_TYPE.maxWidth}
            >
              {s.location?.type == StationTypes.CODE && (
                <Tooltip title={s.location?.code}>
                  <Typography sx={{ fontSize: "14px",}}> {s.location?.type}</Typography>
                </Tooltip>
              )}

              {s.location?.type == StationTypes.QR && (
                 <Tooltip title={s.location?.code}>
                  <Typography sx={{ fontSize: "14px",}}> {s.location?.type}</Typography>
                </Tooltip>
              )}
               {s.location?.type == StationTypes.POINT && (
                 <Tooltip title={`Lat: ${s.location?.coordinates?.[0] ?? ''}, Long: ${s.location?.coordinates?.[1] ?? ''},Radius: ${s.location.radius}m`} >
                  <Typography sx={{ fontSize: "14px",}}> {s.location?.type}</Typography>
                </Tooltip>
              )}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.NUMBER_OF_GAMES.minWidth}
              maxWidth={TableHeaderDefinition.NUMBER_OF_GAMES.maxWidth}
            >{s?.noOfQuestions ?? 0}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CREATOR_NAME.minWidth}
              maxWidth={TableHeaderDefinition.CREATOR_NAME.maxWidth}
            >
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/app/editors/${s?.creator?._id}/view`)}
              >
                {s?.creator?.fullName}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CREATED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.CREATED_DATE.maxWidth}
            >
              {formatTableDateTime(s.createdAt)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.EDITED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.EDITED_DATE.maxWidth}
            >
              {formatTableDateTime(s.updatedAt)}
            </StyledCell>
          </CustomTableRow>
        ))}
      </CustomTable>

      <FloatingButton label={t("STATIONS.STATION")} onClick={addNewStation} />

      <ConfirmDialog
        open={confirmDelete?.status}
        handleClose={() =>
          setConfirmDelete({
            status: false,
            id: "",
          })
        }
        onConfirm={deleteStation}
        title={t("COMMON.DELETE.TITLE", { name: t("STATIONS.STATION") })}
        message={t("COMMON.DELETE.MESSAGE", { name: t("STATIONS.STATION") })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.DELETE")}
      />
    </>
  );
};

export default List;
